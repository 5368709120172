import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import './TerraCallback.scss';
import {
  useAuthContext,
  useUpdateOnboardingStatus,
  useUpdateUserDevices,
} from '../../../data-access';
import { Path } from '../../../routing';

const TerraCallback: FC = () => {
  const location = useLocation();
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const terraUserId = searchParams.get('user_id') ?? '';
  const resource = searchParams.get('resource') ?? '';
  const redirectTo = searchParams.get('redirectTo') ?? '';

  const { updateUserDevices } = useUpdateUserDevices();
  const { updateOnboardingStatus } = useUpdateOnboardingStatus();

  useEffect(() => {
    if (!user.userId) {
      return;
    }

    const update = async () => {
      await updateUserDevices({
        userId: user.userId,
        devices: [
          {
            deviceName: resource,
            terraUserId,
            priority: 1,
            from: 'TERRA',
          },
        ],
      });
      await updateOnboardingStatus({
        userId: user.userId,
        newStatus: { connectWearables: 'answered' },
      });
      navigate(redirectTo || Path.DASHBOARD);
    };

    update();
  }, [user?.userId]);

  return (
    <div className="terra-callback-page">
      <CircularProgress />
    </div>
  );
};

export default TerraCallback;

import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import clsx from 'clsx';
import { TaskItem, TodoIntegrationType } from '@demind-inc/core';
import { useRecoilState } from 'recoil';
import { useDrop } from 'react-dnd';

import './KanbanList.scss';
import { KanbanTaskCard } from '../KanbanTaskCard';
import {
  useAuthContext,
  useCircadianContext,
  useTodoIntegrations,
  useTodoTasksContext,
  visibleTaskDetailAtom,
} from '../../../data-access';
import { TaskDetailModal } from '../../TaskDetailModal';
import { EmptyKanbanCard } from '../EmptyKanbanCard';
import { useDropdownState } from '../../../hooks';
import { ConnectTodosDropdown } from '../../TodoIntegration';
import { CircularProgress } from '@mui/material';
import { trackEventGA4 } from '../../../utils';
import { AddOutlined } from '@mui/icons-material';

interface KanbanListProps {
  date: Dayjs;
  tasks: TaskItem[];
  isFetchingTasks?: boolean;
  generatingTodoAuth?: TodoIntegrationType;
  onConnectTodo?: (todoType: TodoIntegrationType) => void;
  onMoveTask: (task: TaskItem) => void;
  className?: string;
}

const KanbanList: React.FC<KanbanListProps> = ({
  date,
  tasks,
  isFetchingTasks = false,
  generatingTodoAuth,
  onConnectTodo = () => void 0,
  onMoveTask,
  className,
}) => {
  const { findProject, todoProjects } = useTodoTasksContext();
  const [visibleTask, setVisibleTask] = useRecoilState(visibleTaskDetailAtom);
  const { findPhaseForTaskTime } = useCircadianContext();
  const { anchorEl, handleOpenDropdown, handleCloseDropdown } = useDropdownState();
  const { user } = useAuthContext();
  const { todoIntegrations } = useTodoIntegrations({ userId: user?.userId });
  const hasNoProjects = !todoProjects.length;

  const [, drop] = useDrop(() => ({
    accept: 'TASK',
    drop: onMoveTask,
  }));

  return (
    <div ref={drop} className={clsx('kanban-list', className)}>
      <div className="kanban-list__header">
        <div className="kanban-list__header__day">{date.format('dddd')}</div>
        <div className="kanban-list__header__date">{date.format('MMM DD')}</div>
      </div>
      <div className="kanban-list__item-group">
        {isFetchingTasks && <CircularProgress className="kanban-list__loading" size={30} />}
        {!isFetchingTasks && hasNoProjects && <EmptyKanbanCard onClick={handleOpenDropdown} />}
        {!isFetchingTasks &&
          !hasNoProjects &&
          tasks.map((task) => (
            <KanbanTaskCard
              key={task.taskId}
              task={task}
              phase={
                task.startDateTime?.datetime && task.dueDateTime?.datetime
                  ? findPhaseForTaskTime(task.startDateTime.datetime, task.dueDateTime.datetime)
                  : undefined
              }
              projectName={findProject(task.boardId!)?.name}
              onClick={() => {
                setVisibleTask(task);
                trackEventGA4('Modal_open', 'open_task_detail_modal', { taskId: task.taskId });
              }}
            />
          ))}
        <button className="kanban-list__add-task">
          <AddOutlined className="kanban-list__add-task__icon" />
          <p className="kanban-list__add-task__text">Add a task</p>
        </button>
      </div>
      <TaskDetailModal
        task={visibleTask!}
        visible={!!visibleTask}
        onClose={() => setVisibleTask(null)}
      />
      <ConnectTodosDropdown
        generatingTodoAuth={generatingTodoAuth}
        anchorEl={anchorEl}
        onClose={handleCloseDropdown}
        onClickItem={onConnectTodo}
        connectedTodos={todoIntegrations}
      />
    </div>
  );
};

export default KanbanList;

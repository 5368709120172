export interface TotalSleepHourAndMinute {
  hours: number;
  minutes: number;
}

/**
 * Translates the given number of seconds into hours and minutes.
 * @param targetSeconds The number of seconds to be translated.
 * @returns An object containing the translated hours and minutes.
 */
export const translateSecondsToHourAndMinutes = (
  targetSeconds: number
): TotalSleepHourAndMinute => {
  const hours = Math.floor(targetSeconds / (60 * 60));
  const minutes = Math.round(targetSeconds / 60 - hours * 60);
  return { hours, minutes };
};

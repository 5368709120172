import { useMutation, useQueryClient } from '@tanstack/react-query';

import { calendarApi } from '../api';
import { debounce } from 'lodash';
import { useState } from 'react';
import { AxiosError } from 'axios';

interface UseSyncCalendarEventsParams {
  calendarIds: string[];
  userId: string;
  startTime: string;
  endTime: string;
}

export function useSyncCalendarEvents() {
  const queryClient = useQueryClient();
  const [syncErrorRootEmail, setSyncErrorRootEmail] = useState<string>();

  const syncCalMutation = useMutation({
    mutationFn: ({ calendarIds, userId, startTime, endTime }: UseSyncCalendarEventsParams) => {
      return calendarApi.syncCalendarEvents(userId, startTime, endTime, calendarIds);
    },
    onSuccess: debounce(() => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.events'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.eventsForPhase'] });
    }, 2000),
    onError: (error) => {
      const errorData = (error as AxiosError)?.response?.data;
      const errorRootEmail = (errorData as any)?.rootEmail as string;
      if (errorRootEmail) {
        setSyncErrorRootEmail(errorRootEmail);
      }
    },
  });

  return {
    syncCalEvents: syncCalMutation.mutateAsync,
    syncErrorRootEmail,
    ...syncCalMutation,
  };
}

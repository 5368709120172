import React from 'react';

import './PeakEnergy.scss';
import EnergyIcon from '../../../assets/circadian/energy.svg';
import { HelpOutline } from '@mui/icons-material';
import { CircadianTooltipContent } from '../CircadianTooltipContent';
import { CustomTooltip } from '../../common';

interface PeakEnergyProps {
  score: string;
}

const PeakEnergy: React.FC<PeakEnergyProps> = ({ score }) => {
  return (
    <div className="peak-energy">
      <img src={EnergyIcon} alt="Energy Icon" className="peak-energy__icon" />
      <div className="peak-energy__score">{score}</div>
      <CustomTooltip title={<CircadianTooltipContent />} className="peak-energy__tooltip">
        <HelpOutline fontSize="small" className="peak-energy__tooltip__icon" />
      </CustomTooltip>
    </div>
  );
};

export default PeakEnergy;

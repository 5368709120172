import { useMediaQuery } from '@mui/material';
import theme from '../theme';

export const useBreakpoints = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = !isMobile && !isDesktop;

  return { isMobile, isDesktop, isTablet };
};

import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Calendar, CalendarType } from '@demind-inc/core';
import { groupBy, upperFirst } from 'lodash';
import { Error } from '@mui/icons-material';
import { Avatar, CircularProgress } from '@mui/material';

import './CalendarsList.scss';
import { ManageCalendarsBtn } from './ManageCalendarsBtn';
import { CalendarListItem } from './CalendarListItem';
import { CustomTooltip } from '../../common';
import { SyncErrorTooltipContent } from './SyncErrorTooltipContent';

interface CalendarsListProps {
  calendarsMeta: Calendar[];
  visibleCalendarIds: string[];
  isFetchingCalendars: boolean;
  syncErrorRootEmail?: string;
  onSelectCalendar?: (calendar: Calendar, checked: boolean) => void;
  onConnectCalendar: (calendarType: CalendarType) => void;
  className?: string;
}

const CalendarsList: React.FC<CalendarsListProps> = ({
  calendarsMeta,
  visibleCalendarIds,
  isFetchingCalendars,
  syncErrorRootEmail,
  onSelectCalendar = () => void 0,
  onConnectCalendar,
  className,
}) => {
  const calendarsGroupedByRoot = groupBy(calendarsMeta, 'rootEmail');

  const disabledCalendarType: CalendarType[] = useMemo(() => {
    const hasOutlookCalendar = calendarsMeta.some(({ calendarType }) => calendarType === 'outlook');
    return hasOutlookCalendar ? ['outlook'] : []; //#469 Support multipe outlook calendar
  }, [calendarsMeta.length]);

  return (
    <div className={clsx('calendars-list', className)}>
      <div className="calendars-list__content">
        {isFetchingCalendars && <CircularProgress size={20} className="calendars-list__loading" />}
        {!isFetchingCalendars &&
          Object.entries(calendarsGroupedByRoot).map(([rootEmail, calendars]) => {
            return (
              <div className="calendars-list__item-group" key={rootEmail}>
                <div className="calendars-list__item-group__label">
                  <Avatar className="calendars-list__item-group__label__icon">
                    {upperFirst(rootEmail.charAt(0))}
                  </Avatar>
                  <span className="calendars-list__item-group__label__email">{rootEmail}</span>
                  {syncErrorRootEmail === rootEmail && (
                    <CustomTooltip
                      title={
                        <SyncErrorTooltipContent
                          disabled={!calendars[0]?.calendarType}
                          onReconnect={() => onConnectCalendar(calendars[0]?.calendarType)}
                        />
                      }
                    >
                      <Error className="calendars-list__item-group__label__error" />
                    </CustomTooltip>
                  )}
                </div>

                {calendars.map((calendar) => (
                  <CalendarListItem
                    key={calendar.calendarId}
                    calendar={calendar}
                    selected={visibleCalendarIds.includes(calendar.calendarId)}
                    onSelect={(checked: boolean) => onSelectCalendar(calendar, checked)}
                    className="calendars-list__item-group__item"
                  />
                ))}
              </div>
            );
          })}
      </div>

      <ManageCalendarsBtn
        disabledCalendarType={disabledCalendarType}
        onConnect={onConnectCalendar}
      />
    </div>
  );
};

export default CalendarsList;

import { FC } from 'react';
import { ALL_TODO_INTEGRATIONS_TYPE, TodoIntegrationType } from '@demind-inc/core';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import './TodoIntgSettings.scss';
import { TODO_INTEGRATION_ICONS } from '../../../TodoIntegration';
import { AVAILABLE_TODO_INTEGRATIONS_TYPE } from '../../../../data-access';
import { useStartTodoAuth } from '../../../../hooks';

const TodoIntgSettings: FC = () => {
  const { connectedTodos, generatingTodoAuth, handleOpenTodoAuth } = useStartTodoAuth({});

  const handleConnect = (type: TodoIntegrationType) => {
    if (!generatingTodoAuth) {
      handleOpenTodoAuth(type);
    }
  };

  const getButtonContent = (todoType: TodoIntegrationType, connected: boolean, notSupported: boolean) => {
    if (generatingTodoAuth === todoType) {
      return <CircularProgress size={15} className="todo-intg-settings__loading" />;
    }

    const icon = connected ? <CheckIcon fontSize="inherit" /> : <AddIcon fontSize="inherit" />;
    let text = 'Connect';
    if (connected) text = 'Connected';
    if (!connected && notSupported) text = 'Coming soon';

    return (
      <>
        {icon}
        {text}
        <img
          src={TODO_INTEGRATION_ICONS[todoType]}
          alt={todoType}
          className="todo-intg-settings__icon"
        />
      </>
    );
  };

  const getButtonClassName = (connected: boolean, notSupported: boolean) => 
    clsx('todo-intg-settings__button', {
      'todo-intg-settings__button--connected': connected,
      'todo-intg-settings__button--not-supported': notSupported,
      'todo-intg-settings__button--connectable': !connected && !notSupported,
    });

  return (
    <div className="todo-intg-settings">
      <div className="todo-intg-settings__title">Todos</div>
      <div className="todo-intg-settings__grid">
        {ALL_TODO_INTEGRATIONS_TYPE.map((todoType) => {
          const notSupported = !AVAILABLE_TODO_INTEGRATIONS_TYPE.includes(todoType);
          const connected = connectedTodos.some(({ source }) => source === todoType);

          return (
            <button
              key={todoType}
              disabled={notSupported || connected}
              onClick={() => handleConnect(todoType)}
              className={getButtonClassName(connected, notSupported)}
            >
              <div className="todo-intg-settings__button-content">
                {getButtonContent(todoType, connected, notSupported)}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default TodoIntgSettings;
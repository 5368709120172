import { TodoIntegrationType } from '@demind-inc/core';
import { useState } from 'react';

import { useAuthContext, useGenerateTodoAuthURL, useTodoIntegrations } from '../data-access';
import { trackEventGA4 } from '../utils';

interface UseStartTodoAuthParams {
  redirectTo?: string;
}

export const useStartTodoAuth = ({ redirectTo = '/home' }: UseStartTodoAuthParams) => {
  const { user } = useAuthContext();
  const { generateTodoAuthUrl } = useGenerateTodoAuthURL();
  const { todoIntegrations: connectedTodos, isLoading: isFetchingTodoIntegrations } =
    useTodoIntegrations({ userId: user.userId });
  const [generatingTodoAuth, setGeneratingTodoAuth] = useState<TodoIntegrationType | undefined>();

  const handleOpenTodoAuth = async (todoType: TodoIntegrationType) => {
    const callback = {
      trello: window.location.origin + '/callback/trello?redirectTo=' + redirectTo,
      todoist: window.location.origin + '/callback/todoist?redirectTo=' + redirectTo,
      ticktick: window.location.origin + '/callback/ticktick?redirectTo=' + redirectTo,
    }[todoType];
    setGeneratingTodoAuth(todoType);

    const authUrl = await generateTodoAuthUrl({ todoType, callback });
    setGeneratingTodoAuth(undefined);
    trackEventGA4('Button_click', 'click_connect_todo_auth', { todoType });
    window.location.href = authUrl;
  };

  return { connectedTodos, generatingTodoAuth, isFetchingTodoIntegrations, handleOpenTodoAuth };
};

import React from 'react';
import clsx from 'clsx';
import { Calendar } from '@demind-inc/core';
import { Tooltip } from '@mui/material';
import { ReactComponent as EyeIcon } from '../../../../assets/icons/eye-icon.svg';
import './CalendarListItem.scss';
import { getCssVariable } from '../../../../utils';

interface CalendarListItemProps {
  calendar: Calendar;
  selected: boolean;
  onSelect: (checked: boolean) => void;
  className?: string;
}

const primaryColor = getCssVariable('--color-primary');

const CalendarListItem: React.FC<CalendarListItemProps> = ({
  calendar,
  selected,
  onSelect,
  className,
}) => {
  return (
    <button onClick={() => onSelect(!selected)} className={clsx('calendar-list-item', className)}>
      <div className="calendar-list-item__left-group">
        <div className='calendar-list-item__box-container'>
          <div
            className="calendar-list-item__box"
            style={{ backgroundColor: calendar.color || primaryColor }}
          />
        </div>
        <Tooltip title={calendar.name}>
          <p className="calendar-list-item__name">{calendar.name}</p>
        </Tooltip>
      </div>
      <div
        className={clsx('calendar-list-item__icon', {
          'calendar-list-item__icon--active': selected,
        })}
      >
        <EyeIcon />
      </div>
    </button>
  );
};

export default CalendarListItem;

import React, { KeyboardEventHandler, useEffect, useMemo, useState } from 'react';
import {
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import dayjs, { Dayjs } from 'dayjs';
import { useRecoilValue } from 'recoil';

import './CreateEventMenu.scss';
import {
  selectedDateAtom,
  useCalendarContext,
  useCreateEventMenuContext,
} from '../../../../data-access';
import { EventTimeSelector } from '../EventTimeSelector';
import { EventDescription } from '../EventDescription';
import { EventCategories } from '../EventCategories';
import { EventCategorySet } from '@demind-inc/core';
import { CalendarAccountDropdown } from '../CalendarAccountDropdown';

interface CreateEventMenuProps {
  visible: boolean;
  onClose: () => void;
}

const CreateEventMenu: React.FC<CreateEventMenuProps> = ({ visible, onClose }) => {
  const selectedDate = useRecoilValue(selectedDateAtom);
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState<Dayjs>(selectedDate);
  const [endDate, setEndDate] = useState<Dayjs>(selectedDate.add(30, 'minutes'));
  const [description, setDescription] = useState('');
  const [selectedCalendarId, setSelectedCalendarId] = useState<string>('');
  const [selectedCategories, setSelectedCategories] = useState<EventCategorySet[]>([]);
  const { creatingEvent, handleCreateEvent, clearCreatingEvent } = useCreateEventMenuContext();
  const { mainCalendar, calendarAccountsToCreateEvents } = useCalendarContext();

  // Set default values
  useEffect(() => {
    if (!creatingEvent) {
      setTitle('');
      setStartDate(selectedDate);
      setEndDate(selectedDate.add(30, 'minutes'));
      setDescription('');
      setSelectedCategories([]);
      if (!mainCalendar || selectedCalendarId) {
        return;
      }
      setSelectedCalendarId(mainCalendar.calendarId || '');
      return;
    }

    setStartDate(creatingEvent.start?.date ? dayjs(creatingEvent.start.date) : selectedDate);
    setEndDate(
      creatingEvent.end?.date ? dayjs(creatingEvent.end.date) : selectedDate.add(30, 'minutes')
    );
  }, [creatingEvent, mainCalendar]);

  const canSave = useMemo(
    () => !!title && startDate.isBefore(endDate),
    [title, startDate, endDate]
  );
  const handleSaveEvent = () => {
    handleCreateEvent({
      summary: title,
      start: { date: startDate.toISOString(), timeZone: dayjs.tz.guess() },
      end: { date: endDate.toISOString(), timeZone: dayjs.tz.guess() },
      description,
      categories: selectedCategories,
      calendarId: selectedCalendarId,
    });
    clearCreatingEvent();
    onClose();
  };

  const handleKeyDown = (key: string) => {
    if (!canSave) {
      return;
    }
    if (key === 'Enter') {
      handleSaveEvent();
    }
  };

  return (
    <Dialog
      open={visible}
      className="create-event-menu"
      hideBackdrop
      onClose={onClose}
      onKeyDown={({ key }) => handleKeyDown(key)}
    >
      <DialogTitle className="create-event-menu__header">
        <CloseOutlined onClick={onClose} className="create-event-menu__header__close" />
      </DialogTitle>
      <DialogContent className="create-event-menu__content">
        <TextField
          value={title}
          placeholder="Add title"
          onChange={(e) => setTitle(e.target.value)}
          variant="standard"
          className="create-event-menu__title"
        />
        <EventTimeSelector
          startDateTime={startDate}
          endDateTime={endDate}
          onStartTimeChange={(val) => setStartDate(val)}
          onEndTimeChange={(val) => setEndDate(val)}
          className="create-event-menu__content__item"
        />
        <EventCategories
          className="create-event-menu__content__item"
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
        />
        <EventDescription
          description={description}
          onChangeDescription={setDescription}
          className="create-event-menu__content__item"
        />
        <CalendarAccountDropdown
          calendars={calendarAccountsToCreateEvents}
          onSelectCalendarId={(id) => setSelectedCalendarId(id)}
          selectedCalendarId={selectedCalendarId}
        />
      </DialogContent>
      <DialogActions className="create-event-menu__actions">
        <Button variant="contained" disabled={!canSave} onClick={handleSaveEvent}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEventMenu;

import { CalendarType } from '@demind-inc/core';

import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar-icon.svg';
import { ReactComponent as ChromeExtensionIcon } from '../../assets/icons/chrome-extension-icon.svg';
import { ReactComponent as MobileIcon } from '../../assets/icons/mobile-icon.svg';
import { ReactComponent as NotificationsIcon } from '../../assets/icons/notifications-icon.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile-icon.svg';
import { ReactComponent as SubscriptionIcon } from '../../assets/icons/subscription-icon.svg';
import { ReactComponent as TodoIcon } from '../../assets/icons/todo-icon.svg';
import { ReactComponent as WearableIcon } from '../../assets/icons/wearable-icon.svg';
import { ReactComponent as HealthIcon } from '../../assets/settings/health-icon.svg';
import { ReactComponent as GarminIcon } from '../../assets/settings/garmin-icon.svg';
import { ReactComponent as WithingsIcon } from '../../assets/settings/withings-icon.svg';
import { ReactComponent as FitbitIcon } from '../../assets/settings/fitbit-icon.svg';
import { ReactComponent as OuraIcon } from '../../assets/settings/oura-icon.svg';
import { ReactComponent as GoogleIcon } from '../../assets/icons/google-icon.svg';
import { ReactComponent as OutlookIcon } from '../../assets/icons/outlook-icon.svg';

import { ReactComponent as GoogleFitIcon } from '../../assets/settings/google-fit.svg';
import { AccountSettings } from './SettingsModal/AccountSettings';
import { CalendarsSettings } from './SettingsModal/CalendarSettings';
import { WearablesSettings } from './SettingsModal/WearablesSettings';
import { TodoIntgSettings } from './SettingsModal/TodoIntgSettings';
import { SubscriptionSettings } from './SettingsModal/SubscriptionSettings';
import { NotificationSettings } from './SettingsModal/NotificationSettings';
import { MobileApp } from './SettingsModal/MobileApp';
import { InstallExtension } from './SettingsModal/InstallExtension';
import { AboutSettings } from './SettingsModal/AboutSettings';
import { FC, ReactElement, SVGProps } from 'react';

export interface SettingsSectionItemComponent {
  Component: React.ComponentType;
  key: string;
}

export interface SettingsSectionItem {
  title: string;
  components: SettingsSectionItemComponent[];
}

export interface WearbaleItem {
  id: string; // Used to compare with connectedDevices
  name: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
}

export interface CalendarOptionsType {
  calendar: string;
  id: CalendarType;
  icon: React.ComponentType;
}

export const ICONS = {
  mobile: require('../../assets/icons/mobile_icon.png'),
  chrome: require('../../assets/icons/chrome_icon.png'),
  close: require('../../assets/icons/close_icon.png'),
  todoist: require('../../assets/todoIntegrations/todoist_icon.png'),
  trello: require('../../assets/todoIntegrations/trello_icon.png'),
  ticktick: require('../../assets/todoIntegrations/ticktick_icon.jpg'),
};

export const SVG_ICONS = {
  calendar: CalendarIcon,
  chromeExtension: ChromeExtensionIcon,
  mobile: MobileIcon,
  notifications: NotificationsIcon,
  profile: ProfileIcon,
  subscription: SubscriptionIcon,
  todo: TodoIcon,
  wearable: WearableIcon,
  google: GoogleIcon,
  outlook: OutlookIcon,
};

export const WEARABLES: WearbaleItem[] = [
  { id: 'apple', name: 'Health', Icon: HealthIcon },
  { id: 'garmin', name: 'Garmin', Icon: GarminIcon },
  { id: 'whoop', name: 'Whoop', Icon: WithingsIcon },
  { id: 'fitbit', name: 'Fitbit', Icon: FitbitIcon },
  { id: 'oura', name: 'Oura', Icon: OuraIcon },
  // { name: 'Google Fit', Icon: GoogleFitIcon },
];

export const MobileSdkOnlyWearbales = ['apple'];

export const settingsSections: SettingsSectionItem[] = [
  {
    title: 'Account',
    components: [
      { Component: AccountSettings, key: 'profile' },
      { Component: CalendarsSettings, key: 'calendars' },
      { Component: WearablesSettings, key: 'wearables' },
      { Component: TodoIntgSettings, key: 'todos' },
      { Component: SubscriptionSettings, key: 'subscription' },
    ],
  },
  {
    title: 'General',
    components: [{ Component: NotificationSettings, key: 'notifications' }],
  },
  {
    title: 'Product Hub',
    components: [
      { Component: MobileApp, key: 'mobileApp' },
      { Component: InstallExtension, key: 'chromeExtension' },
    ],
  },
  {
    title: `Lifestack ${process.env.REACT_APP_VERSION}`,
    components: [{ Component: AboutSettings, key: 'about' }],
  },
];

export const calendars: CalendarOptionsType[] = [
  { calendar: 'Google', id: 'google', icon: SVG_ICONS.google },
  { calendar: 'Outlook', id: 'outlook', icon: SVG_ICONS.outlook },
];

import React, { useCallback, useMemo, memo } from 'react';
import * as d3 from 'd3';
import { groupBy } from 'lodash';
import { HeatmapDataType } from '@demind-inc/core';

import './Heatmap.scss';
import { getCssVariable } from '../../../../utils';

interface HeatmapBarChartProps {
  width: string;
  height: string;
  data: HeatmapDataType[];
  opacity?: number;
  style?: React.CSSProperties;
  strokeMode?: 'thin' | 'thinner' | 'default';
  onClickPoint?: (point: HeatmapDataType) => void;
}

const colorPalette = [
  getCssVariable('--color-red-base'),
  getCssVariable('--color-yellow-base'),
  getCssVariable('--color-green-base'),
];
const sleepColor = getCssVariable('--color-blue-base');

const HeatmapBarChart: React.FC<HeatmapBarChartProps> = memo(
  ({
    data,
    width,
    height,
    style,
    opacity = 1.0,
    strokeMode = 'default',
    onClickPoint = () => void 0,
  }) => {
    const allYGroups = useMemo(() => data.map((d) => d.y), [data]);
    const allXGroups = useMemo(() => data.map((d) => d.x), [data]);

    const yScale = useMemo(() => {
      return d3
        .scaleBand()
        .range([0, parseInt(height)])
        .domain(allYGroups)
        .padding(0);
    }, [allYGroups, width]);

    const xScale = useMemo(() => {
      return d3
        .scaleBand()
        .range([0, parseInt(width)])
        .domain(allXGroups)
        .padding(0);
    }, [allYGroups, width]);

    const colorScale = d3
      .scaleSequential()
      .interpolator(d3.interpolateRgbBasis(colorPalette))
      .domain([0, 1]);

    const getFillColor = useCallback((value: number) => {
      if (value === -1) {
        return getCssVariable('--color-gray-light');
      }
      if (value === 0) {
        return sleepColor;
      }
      return colorScale(value);
    }, []);

    const defineColoredLinearGradeient = useCallback(
      (data: HeatmapDataType[]) => {
        const allColors = Object.keys(groupBy(data, (d) => getFillColor(d.value)));

        return allColors.map((color) => (
          <defs key={color}>
            <linearGradient id={`heatmapBarGradient${color}`} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={'white'} stopOpacity="0.1" />
              <stop offset="0.3" stopColor={color} stopOpacity="0.7" />
              <stop offset="0.5" stopColor={color} stopOpacity="1" />
              <stop offset="0.8" stopColor={color} stopOpacity="0.7" />
              <stop offset="0.9" stopColor={'white'} stopOpacity="0.3" />
              <stop offset="1" stopColor={'white'} stopOpacity="0.1" />
            </linearGradient>
          </defs>
        ));
      },
      [data]
    );

    return (
      <div
        style={{
          ...style,
          boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.3)',
          elevation: 3,
          ...((strokeMode === 'thinner' || strokeMode === 'thin') && {
            boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.2)',
            elevation: 1,
          }),
        }}
      >
        <svg width={width} height={height}>
          {/* TODO: apply linear gradient */}
          {/* {strokeMode === 'default' && defineColoredLinearGradeient(data)} */}
          {data.map((d, i) => {
            return (
              <rect
                key={i}
                y={yScale(d.y)}
                onClick={() => onClickPoint(d)}
                width={xScale.bandwidth()}
                height={7} // Any value is fine as long as there are not space between heatmap
                fill={
                  getFillColor(d.value)
                  // strokeMode ===
                  //   'default'
                  //   ? `url(#heatmapBarGradient${getFillColor(d.value)})`
                  //   : getFillColor(d.value)
                }
                opacity={opacity}
              />
            );
          })}
        </svg>
      </div>
    );
  }
);

export default HeatmapBarChart;

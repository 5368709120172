import { useMutation } from '@tanstack/react-query';

import { accountApi } from '../api';

interface UseRequestStripeSubscriptionParams {
  userId: string;
  priceId: string;
  email: string;
  customerToken: string;
}

export function useRequestStripeSubscription() {
  const requestSubscription = useMutation({
    mutationFn: ({ userId, priceId, email, customerToken }: UseRequestStripeSubscriptionParams) => {
      return accountApi.requestStripeSubscription(userId, priceId, email, customerToken);
    },
  });

  return {
    requestStripeSubscription: requestSubscription.mutateAsync,
    ...requestSubscription,
  };
}

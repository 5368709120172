import { FC } from 'react';
import clsx from 'clsx';

import './SubscriptionTimeline.scss';

interface SubscriptionTimelineProps {
  startTrialDate: string;
  startChargeDate: string;
  price?: string;
}

const SubscriptionTimeline: FC<SubscriptionTimelineProps> = ({
  startChargeDate,
  startTrialDate,
  price = '',
}) => {
  return (
    <div className="subscription-timeline">
      <div className="subscription-timeline__left-group">
        <div className={clsx('subscription-timeline__vertical-line', 'vertical-line')}>
          <div className="vertical-line__circle" />
          <div className="vertical-line__line" /> <div className="vertical-line__circle" />
        </div>
        <div className="subscription-timeline__title-group">
          <div className="subscription-timeline__title-group__action">
            {startTrialDate} (Cancel Anytime)
          </div>
          <div className="subscription-timeline__title-group__action">{startChargeDate}</div>
        </div>
      </div>
      <div className="subscription-timeline__right-group">
        <div className="subscription-timeline__price">
          14 days free <span className="subscription-timeline__price--red"> $0</span>
        </div>
        <div className="subscription-timeline__price">{price}</div>
      </div>
    </div>
  );
};

export default SubscriptionTimeline;

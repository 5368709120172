import { useEffect, useMemo, useState } from 'react';

import { projectFilterAtom, useTodoTasksContext } from '../data-access';
import { ProjectFilterItem } from './types';
import { useRecoilState } from 'recoil';

export const allTypeProjectFilter: ProjectFilterItem = {
  todoTaskId: 'all',
  name: 'All',
};

export const useProjectFilter = () => {
  const { todoProjects } = useTodoTasksContext();

  const filterProjectOptions = useMemo(() => {
    const optionsFromDB = todoProjects.map(
      (project) =>
        ({
          todoTaskId: project.todoTaskId,
          name: project.name,
        }) as ProjectFilterItem
    );
    return [allTypeProjectFilter, ...optionsFromDB];
  }, [todoProjects]);

  const [selectedFilter, setSelectedFilter] = useRecoilState(projectFilterAtom);

  useEffect(() => {
    const persistedFilterId = localStorage.getItem('selectedProjectFilter');
    if (persistedFilterId) {
      const persistedFilter = filterProjectOptions.find(
        (project) => project.todoTaskId === persistedFilterId
      );
      if (persistedFilter) {
        setSelectedFilter(persistedFilter);
      }
    }
  }, [filterProjectOptions]);

  const handleSelectFilterProject = (projectId: string) => {
    const project =
      filterProjectOptions.find((project) => project.todoTaskId === projectId) ??
      allTypeProjectFilter;

    setSelectedFilter(project);
    localStorage.setItem('selectedProjectFilter', projectId);
  };

  return { selectedFilter, filterProjectOptions, handleSelectFilterProject };
};

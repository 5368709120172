import { round } from 'lodash';
import Stripe from 'stripe';

/*
  @example: currency: USD , price: 3.00 -> $3.00
*/
export const formatCurrencyPrice = (currency: string, price: number) => {
  return new Intl.NumberFormat('en-EN', { style: 'currency', currency }).format(price);
};

export const translatePlanPriceDetail = (plan: Stripe.Price, onlyPrice = false): string => {
  const recurring = plan.recurring.interval;
  const price = plan.unit_amount / 100; // Dollar
  const currency = plan.currency;

  if (onlyPrice) {
    return formatCurrencyPrice(currency, price);
  }

  if (recurring === 'year') {
    const monthlyPrice = round(price / 12, 2);
    return `${formatCurrencyPrice(currency, price)} / Year (${formatCurrencyPrice(
      currency,
      monthlyPrice
    )} / Month)`;
  }

  return `${formatCurrencyPrice(currency, price)} / Month`;
};

import { FC } from 'react';
import { clsx } from 'clsx';
import { Menu, MenuItem } from '@mui/material';
import { FilterAltOutlined, CheckOutlined } from '@mui/icons-material';

import './ProjectFilter.scss';
import { ProjectFilterItem, useDropdownState } from '../../../hooks';

interface ProjectFilterProps {
  filterOptions: ProjectFilterItem[];
  selectedFilter: ProjectFilterItem;
  onSelectFilter: (projectId: string) => void;
  className?: string;
}

const ProjectFilter: FC<ProjectFilterProps> = ({
  filterOptions,
  selectedFilter,
  onSelectFilter,
  className,
}) => {
  const { anchorEl, handleOpenDropdown, handleCloseDropdown } = useDropdownState();

  return (
    <>
      <div className={clsx('project-filter', className)} onClick={handleOpenDropdown}>
        <FilterAltOutlined fontSize="small" />
        <div className="project-filter__selected-title"># {selectedFilter.name}</div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
        className="project-filter__dropdown"
      >
        {filterOptions.map((project) => (
          <MenuItem
            key={project.todoTaskId}
            onClick={() => onSelectFilter(project.todoTaskId)}
            className="project-filter__dropdown__item"
          >
            <div className="project-filter__dropdown__item__icon">
              {selectedFilter.todoTaskId === project.todoTaskId && (
                <CheckOutlined fontSize="small" />
              )}
            </div>
            # {project.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ProjectFilter;

import React from 'react';
import clsx from 'clsx';

import './KanbanView.scss';
import { KanbanList, ProjectFilter, ManageProjectsBtn, SyncingChip } from '../../../components';
import { useCalendarContext, useTodoTasksContext, useAuthContext } from '../../../data-access';
import { useStartTodoAuth, useProjectFilter, useMoveTask } from '../../../hooks';

interface KanbanViewProps {
  className?: string;
}

const KanbanView: React.FC<KanbanViewProps> = ({ className }) => {
  const {
    todoTasksByDate,
    visibleTodoListDates,
    todoProjects,
    isFetchingTasks,
    isFetchingProjects,
    isSyncingTodos,
  } = useTodoTasksContext();
  const { handleOpenTodoAuth, generatingTodoAuth } = useStartTodoAuth({});
  const { selectedFilter, filterProjectOptions, handleSelectFilterProject } = useProjectFilter();
  const { isSyncingEvents } = useCalendarContext();
  const { handleMoveTask, isMovingTask } = useMoveTask();

  const hasNoProjects = !todoProjects.length && !isFetchingProjects;

  return (
    <div className={clsx('kanban-view', className)}>
      <div className="kanban-view__header">
        <div className="kanban-view__header__left-group">
          <div className="kanban-view__header__title">Today</div>
          {(isSyncingEvents || isSyncingTodos || isMovingTask) && <SyncingChip />}
        </div>
        {hasNoProjects ? (
          <ManageProjectsBtn onConnect={handleOpenTodoAuth} />
        ) : (
          <ProjectFilter
            filterOptions={filterProjectOptions}
            selectedFilter={selectedFilter}
            onSelectFilter={handleSelectFilterProject}
          />
        )}
      </div>
      <div className="kanban-view__content">
        {visibleTodoListDates.map((listDate) => (
          <KanbanList
            key={listDate.toISOString()}
            date={listDate}
            tasks={
              todoTasksByDate.find(({ date }) => date === listDate.format('YYYY-MM-DD'))?.tasks ||
              []
            }
            isFetchingTasks={isFetchingTasks}
            generatingTodoAuth={generatingTodoAuth}
            className="kanban-view__content__list"
            onConnectTodo={handleOpenTodoAuth}
            onMoveTask={(task) => handleMoveTask({ task, targetDate: listDate })}
          />
        ))}
      </div>
    </div>
  );
};

export default KanbanView;

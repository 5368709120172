import { CalendarEvent } from '@demind-inc/core';
import { RBCEvent } from './types';

export const transformEventToRBCEvent = (event: CalendarEvent): RBCEvent => {
  return {
    ...event,
    title: event.summary,
    start: new Date(event.start.date),
    end: new Date(event.end.date),
    allDay: event.isAllDay,
  };
};

export const transformCreatingEventToRBCEvent = (event: Partial<CalendarEvent>): RBCEvent => {
  return {
    ...event,
    ...(event.start ? { start: new Date(event.start.date) } : {}),
    ...(event.end ? { end: new Date(event.end.date) } : {}),
  } as RBCEvent;
};

import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';

import './OutlookCallback.scss';
import {
  msAuth,
  useAuthContext,
  useCalendarContext,
  useSyncOutlookCalendarsList,
} from '../../../data-access';
import { Path } from '../../../routing';

const OutlookCallback: FC = () => {
  const location = useLocation();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { syncOutlookCalendarsList } = useSyncOutlookCalendarsList();
  const { reSyncCalEvents } = useCalendarContext();

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code') ?? '';

  useEffect(() => {
    if (!user.userId || !code) {
      return;
    }

    const update = async () => {
      const cred = await msAuth.signinCallback(window.location.href);

      if (cred) {
        const newCalendarIds = await syncOutlookCalendarsList({
          userId: user.userId,
          accessToken: cred.access_token,
          refreshToken: cred.refresh_token,
          expiryDate: dayjs.unix(cred.expires_at).toISOString(),
        });

        reSyncCalEvents(newCalendarIds);
      }

      navigate(Path.DASHBOARD);
    };

    update();
  }, [user?.userId, code]);

  return (
    <div className="outlook-callback-page">
      <CircularProgress />
    </div>
  );
};

export default OutlookCallback;

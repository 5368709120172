import { useMutation, useQueryClient } from '@tanstack/react-query';
import { debounce } from 'lodash';

import { tasksApi } from '../api';
import { FirestoreTaskItem } from '../types';

interface UseSyncTodoTasksParams {
  userId: string;
  targetTodoIntegrationIds: string[];
  timezone: string;
}

export function useSyncTodoTasks() {
  const queryClient = useQueryClient();

  const syncTodoTasksMutation = useMutation({
    mutationFn: ({ userId, targetTodoIntegrationIds, timezone }: UseSyncTodoTasksParams) => {
      return tasksApi
        .syncTodoTasks(userId, timezone, targetTodoIntegrationIds)
        .then(({ data }) => (data as FirestoreTaskItem[]) ?? []);
    },
    onSuccess: debounce(() => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.tasks'] });
    }, 2000),
  });

  return {
    syncTodoTasks: syncTodoTasksMutation.mutateAsync,
    ...syncTodoTasksMutation,
  };
}

import axios from 'axios';
import { CredentialRequest, TokenInfo } from 'google-auth-library';

interface FetchGoogleUserParams {
  accessToken: string;
}

interface ExchangeCodeToTokenParams {
  serverAuthCode: string;
}

export const fetchGoogleUser = async ({ accessToken }: FetchGoogleUserParams) => {
  const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const user = userInfoResponse.data as TokenInfo;
  return user;
};

export const exchangeCodeToToken = async ({ serverAuthCode }: ExchangeCodeToTokenParams) => {
  const response = await axios.post('https://oauth2.googleapis.com/token', {
    code: serverAuthCode,
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
    redirect_uri: 'postmessage',
    grant_type: 'authorization_code',
  });

  return response.data as CredentialRequest;
};

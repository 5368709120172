import { FC } from 'react';
import { CalendarEventAttendee, CalendarEventAttendeeResponseStatus } from '@demind-inc/core';
import { Check, Close, QuestionMark } from '@mui/icons-material';
import { Avatar, Badge } from '@mui/material';
import { upperFirst } from 'lodash';

import './EventAttendees.scss';

interface EventAttendeesProps {
  attendees: CalendarEventAttendee[];
}

const getStatusIcon = (status: CalendarEventAttendeeResponseStatus) => {
  switch (status) {
    case 'accepted':
      return <Check className="event-attendees__list__item__avatar--status" />;
    case 'declined':
      return <Close className="event-attendees__list__item__avatar--status" />;
    case 'tentative':
      return <QuestionMark className="event-attendees__list__item__avatar--status" />;
    default:
      return null;
  }
};

const EventAttendees: FC<EventAttendeesProps> = ({ attendees }) => {
  return (
    <div className="event-attendees">
      <div className="event-attendees__title">{attendees.length} guests</div>
      <div className="event-attendees__list">
        {attendees
          .sort((a, b) => (a.organizer ? -1 : 1))
          .map((attendee) => (
            <div className="event-attendees__list__item" key={attendee.email}>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={getStatusIcon(attendee.responseStatus)}
              >
                <Avatar className="event-attendees__list__item__avatar">
                  {upperFirst(attendee.email.charAt(0))}
                </Avatar>
              </Badge>
              <div className="event-attendees__list__item__title-group">
                <div className="event-attendees__list__item__title-group__title">
                  {attendee.email}
                </div>
                {attendee.organizer && (
                  <div className="event-attendees__list__item__title-group__sub-title">
                    Organizer
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default EventAttendees;

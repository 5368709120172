import { ReactNode, createContext, useContext, useMemo } from 'react';
import { Subscription } from '@demind-inc/core';

import { useAuthContext } from './AuthProvider';

import { useSubscriptions } from '../queries';

interface ISubscriptionContext {
  currentSubscription?: Subscription;
  hasAccessToApp: boolean;
  isValidating: boolean;
  refetchSubscriptions: () => Promise<void>;
}

export const SubscriptionContext = createContext({} as ISubscriptionContext);
export const useSubscriptionContext = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuthContext();
  const {
    subscriptions,
    isLoading: isFetchingSubscriptions,
    refetch,
  } = useSubscriptions({
    userId: user.userId,
  });

  const refetchSubscriptions = async () => {
    await refetch();
  };

  const hasAccessToApp = useMemo(
    () => (subscriptions?.hasAccess === undefined ? true : subscriptions.hasAccess),
    [subscriptions]
  );

  return (
    <SubscriptionContext.Provider
      value={{
        currentSubscription: subscriptions?.activeSubscription,
        hasAccessToApp,
        isValidating: isFetchingSubscriptions,
        refetchSubscriptions,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

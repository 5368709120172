import { FC } from 'react';
import { TodoIntegrationType } from '@demind-inc/core';

import './ManageProjectsBtn.scss';
import { ConnectTodosDropdown } from '../../TodoIntegration';
import { useDropdownState } from '../../../hooks';
import { useAuthContext, useTodoIntegrations } from '../../../data-access';

interface ManageProjectsBtnProps {
  generatingTodoAuth?: TodoIntegrationType;
  onConnect: (todoType: TodoIntegrationType) => void;
}

const ManageProjectsBtn: FC<ManageProjectsBtnProps> = ({ generatingTodoAuth, onConnect }) => {
  const { anchorEl, handleCloseDropdown, handleOpenDropdown } = useDropdownState();
  const { user } = useAuthContext();
  const { todoIntegrations } = useTodoIntegrations({ userId: user?.userId });
  return (
    <>
      <div className="manage-projects-btn" onClick={handleOpenDropdown}>
        Connect Todos
      </div>
      <ConnectTodosDropdown
        generatingTodoAuth={generatingTodoAuth}
        anchorEl={anchorEl}
        onClose={handleCloseDropdown}
        onClickItem={onConnect}
        connectedTodos={todoIntegrations}
      />
    </>
  );
};

export default ManageProjectsBtn;

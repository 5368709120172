import React, { FC } from 'react';

import './EventDescription.scss';
import clsx from 'clsx';
import { Notes } from '@mui/icons-material';
import { TextField } from '@mui/material';

interface EventDescriptionProps {
  description: string;
  onChangeDescription: (description: string) => void;
  className?: string;
}

const EventDescription: FC<EventDescriptionProps> = ({
  description,
  onChangeDescription,
  className,
}) => {
  return (
    <div className={clsx('event-description', className)}>
      <Notes className="event-description__icon" fontSize="small" />
      <TextField
        value={description}
        placeholder="Add description"
        onChange={(e) => onChangeDescription(e.target.value)}
        variant="standard"
        className="event-description__input"
      />
    </div>
  );
};

export default EventDescription;

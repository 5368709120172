import './MobileApp.scss';
import AppPreviewOne from '../../../../assets/settings/app-preview-1.png';
import AppPreviewTwo from '../../../../assets/settings/app-preview-2.png';
import AppStore from '../../../../assets/settings/appstore.png';
import PlayStore from '../../../../assets/settings/playstore.png';

const MobileApp = () => {
  return (
    <div className="mobile-app-container">
      <p className="mobile-app-title">Mobile App</p>
      <p className="mobile-app-subtitle">Expand your experience</p>
      <div className="mobile-app">
        <div className="app-previews">
          <img src={AppPreviewOne} alt="App Preview 1" draggable="false" />
          <img src={AppPreviewTwo} alt="App Preview 2" draggable="false" />
        </div>
        <div className="store-links">
          <a
            href="https://apps.apple.com/us/app/lifestack-calendar-and-health/id6473464429"
            target="_blank"
          >
            <img src={AppStore} alt="App Store" draggable="false" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.demind.lifestack_calendar&hl=en_US&gl=US"
            target="_blank"
          >
            <img src={PlayStore} alt="Play Store" draggable="false" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileApp;

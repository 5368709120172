import ReactGA from 'react-ga4';
import { isProduction } from '../environment';

type GA4EventCategory = 'Button_click' | 'Drag' | 'Modal_open';

export const initGA4 = () => {
  if (!process.env.REACT_APP_GOOGLE_ANALYTICS_ID || !isProduction) {
    return;
  }

  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
};

export const setUserProperties = (userId: string) => {
  ReactGA.set({ userId });
};

export const trackPageViewGA4 = (path: string) => {
  ReactGA.send({ hitType: 'pageview', page: path });
};

export const trackEventGA4 = (category: GA4EventCategory, action: string, params?: object) => {
  ReactGA.event({
    category,
    action,
    ...params,
  });
};

import React, { ReactNode } from 'react';

import './OnboardingContainer.scss';
import LifestackLogoName from '../../../assets/logo/logo_with_name.png';

interface OnboardingContainerProps {
  leftChildren: ReactNode;
  rightChildren: ReactNode;
}

const OnboardingContainer: React.FC<OnboardingContainerProps> = ({
  leftChildren,
  rightChildren,
}) => {
  return (
    <div className="onboarding-container">
      <div className="onboarding-container__left-section">
        <div className="onboarding-container__left-section__logo--container">
          <img
            src={LifestackLogoName}
            alt="lifestck icon"
            className="onboarding-container__left-section__logo"
          />
        </div>
        <div className="onboarding-container__left-section__asset--container">{leftChildren}</div>
      </div>

      <div className="onboarding-container__right-section">{rightChildren}</div>
    </div>
  );
};

export default OnboardingContainer;

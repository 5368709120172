import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TodoIntegrationType } from '@demind-inc/core';

import './ConnectTodoPage.scss';
import { ConnectTodosList, OnboardingContainer } from '../../../components';
import { Path } from '../../../routing/path';
import { useStartTodoAuth } from '../../../hooks';
import { useAuthContext, useUpdateOnboardingStatus } from '../../../data-access';
import ConnectTodoAsset from '../../../assets/onboarding/connectTodo.gif';

const ConnectTodoPage: React.FC = () => {
  const navigate = useNavigate();
  const { connectedTodos, generatingTodoAuth, handleOpenTodoAuth } = useStartTodoAuth({
    redirectTo: Path.ONBOARDING.CONNECT_TODO,
  });
  const { user } = useAuthContext();
  const { updateOnboardingStatus } = useUpdateOnboardingStatus();

  const handleContinue = async () => {
    await updateOnboardingStatus({ userId: user.userId, newStatus: { connectTodo: 'answered' } });
    navigate(Path.ONBOARDING.INITIAL_SYNC);
  };

  const handleConnectTodo = (todoType: TodoIntegrationType) => {
    if (generatingTodoAuth) {
      return;
    }

    handleOpenTodoAuth(todoType);
  };

  return (
    <div className="connect-todo-page">
      <OnboardingContainer
        leftChildren={
          <img
            src={ConnectTodoAsset}
            alt="lifestack connect todo"
            className="connect-todo-page__asset"
          />
        }
        rightChildren={
          <div className="connect-todo-page__right-section">
            <div className="connect-todo-page__content">
              <div className="connect-todo-page__content__title">Connect your todos</div>
              <ConnectTodosList connectedTodos={connectedTodos} onConnectTodo={handleConnectTodo} />
            </div>
            <div className="connect-todo-page__btn-group">
              <Button
                variant="contained"
                className={'connect-todo-page__btn'}
                onClick={handleContinue}
              >
                Continue
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ConnectTodoPage;

import { Navigate, useLocation } from 'react-router-dom';
import { Status, useAuthContext, useSubscriptionContext } from '../data-access';
import { Path } from './path';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const { status } = useAuthContext();
  const { hasAccessToApp } = useSubscriptionContext();

  // Redirect to the requested path after successful login.
  if (status === Status.UN_AUTHORIZED) {
    return <Navigate to="/" state={{ redirectTo: location.pathname }} replace />;
  }

  if (!hasAccessToApp) {
    return <Navigate to={Path.PAYWALL} />;
  }

  return <>{children}</>;
};

export default RequireAuth;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CalendarEvent, CalendarEventMetrics } from '@demind-inc/core';
import { cloneDeep } from 'lodash';

import { calendarApi } from '../api';
import { supplyCreateEventOption } from '../helpers';
import { CalendarEventCreateOption } from '../types';

interface UseCreateCalendarEventV2Params {
  calendarId: string;
  userId: string;
  newEventOption: CalendarEventCreateOption;
  taskId?: string;
  metrics?: CalendarEventMetrics;
}

export function useCreateCalendarEvent() {
  const queryClient = useQueryClient();

  const createCalendarEventMutation = useMutation({
    mutationFn: ({
      calendarId,
      userId,
      newEventOption,
      taskId,
      metrics,
    }: UseCreateCalendarEventV2Params) => {
      return calendarApi.createCalendarEvent(calendarId, userId, {
        taskId,
        metrics,
        newEventOption,
      });
    },
    onMutate: async ({ calendarId, newEventOption }: UseCreateCalendarEventV2Params) => {
      await queryClient.cancelQueries({ queryKey: ['lifestack.calendar.events'] });
      queryClient.setQueriesData<CalendarEvent[]>(
        { queryKey: ['lifestack.calendar.events'] },
        (prevEvents) => {
          const suppliedNewEvent = supplyCreateEventOption(calendarId, newEventOption);
          return cloneDeep([...(prevEvents ?? []), suppliedNewEvent]);
        }
      );
    },
    onSuccess: (_, { metrics }) => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.events'] });

      if (metrics?.energy) {
        queryClient.invalidateQueries({ queryKey: ['lifestack.circadian'] });
      }
    },
  });

  return {
    createCalendarEvent: createCalendarEventMutation.mutate,
    ...createCalendarEventMutation,
  };
}

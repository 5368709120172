import React, { useMemo } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import {
  CloseOutlined,
  AccessTime,
  Notes,
  CalendarToday,
  TaskAlt,
  DeleteOutline,
  LocationOn,
  Videocam,
  People,
  EditOutlined,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import clsx from 'clsx';

import './EventDetailsMenu.scss';
import { useCalendarContext, useEventDetailsMenuContext } from '../../../../data-access';
import { getCssVariable } from '../../../../utils';
import { EventAttendees } from '../EventAttendees';

interface EventDetailsMenuProps {
  visible: boolean;
  onClose: () => void;
}

const EventDetailsMenu: React.FC<EventDetailsMenuProps> = ({ visible, onClose }) => {
  const { selectedEvent, handleStartEditingEvent, handleDeleteEvent } =
    useEventDetailsMenuContext();
  const { mainCalendar, findCalendarItem, checkCalendarHassAccessToModifyEvent } =
    useCalendarContext();

  if (!selectedEvent) {
    return null;
  }

  const { targetCalendar, hasAccesToModify } = useMemo(() => {
    const targetCalendar = selectedEvent.calendarId
      ? findCalendarItem(selectedEvent.calendarId)
      : mainCalendar;
    const hasAccesToModify = checkCalendarHassAccessToModifyEvent(targetCalendar!.calendarId);

    return { targetCalendar, hasAccesToModify };
  }, [selectedEvent]);

  return (
    <Dialog open={visible} className="event-details-menu" hideBackdrop onClose={onClose}>
      <DialogTitle className="event-details-menu__header">
        <div className="event-details-menu__header__icon-group">
          {hasAccesToModify && (
            <>
              <EditOutlined
                className="event-details-menu__header__icon"
                onClick={handleStartEditingEvent}
              />
              <DeleteOutline
                className="event-details-menu__header__icon"
                onClick={() => handleDeleteEvent(selectedEvent)}
              />
            </>
          )}
          <CloseOutlined onClick={onClose} className="event-details-menu__header__icon" />
        </div>
      </DialogTitle>
      <DialogContent className="event-details-menu__content">
        <div className={clsx('event-details-menu__content__item', 'event-details-menu__title')}>
          <div className="event-details-menu__title__color__container">
            <div
              style={{ backgroundColor: selectedEvent.color || getCssVariable('--color-primary') }}
              className="event-details-menu__title__color"
            />
          </div>
          <div className="event-details-menu__title__text">{selectedEvent.title}</div>
        </div>
        <div className={clsx('event-details-menu__content__item')}>
          <AccessTime className="event-details-menu__content__item__icon" fontSize="small" />
          <div className="event-details-menu__content__item__text">
            {dayjs(selectedEvent.start).format('dddd, MMMM D')}
            {'  '}
            {dayjs(selectedEvent.start).format('HH:mm')} -{' '}
            {dayjs(selectedEvent.end).format('HH:mm')}
          </div>
        </div>
        {!!selectedEvent.description && (
          <div className={clsx('event-details-menu__content__item')}>
            <Notes className="event-details-menu__content__item__icon" fontSize="small" />
            <div
              className="event-details-menu__content__item__text"
              dangerouslySetInnerHTML={{ __html: selectedEvent.description! }}
            />
          </div>
        )}
        {!!selectedEvent.location && (
          <div className={clsx('event-details-menu__content__item')}>
            <LocationOn className="event-details-menu__content__item__icon" fontSize="small" />
            <div className={clsx('event-details-menu__content__item__text')}>
              {typeof selectedEvent.location === 'object'
                ? //@ts-ignore
                  selectedEvent.location.displayName // Some of outlook calendar events store locations in invalid formats
                : selectedEvent.location}
            </div>
          </div>
        )}
        {!!selectedEvent.mtgLink && (
          <div className={clsx('event-details-menu__content__item')}>
            <Videocam className="event-details-menu__content__item__icon" fontSize="small" />
            <div className={clsx('event-details-menu__content__item__text')}>
              {selectedEvent.mtgLink}
            </div>
          </div>
        )}
        {!!selectedEvent.attendees?.length && (
          <div className={clsx('event-details-menu__content__item')}>
            <People className="event-details-menu__content__item__icon" fontSize="small" />
            <EventAttendees attendees={selectedEvent.attendees} />
          </div>
        )}

        {!!targetCalendar && (
          <div className={clsx('event-details-menu__content__item')}>
            <CalendarToday className="event-details-menu__content__item__icon" fontSize="small" />
            <div className="event-details-menu__content__item__text">{targetCalendar.name}</div>
          </div>
        )}
        {!!selectedEvent.taskFrom && (
          <div className={clsx('event-details-menu__content__item')}>
            <TaskAlt className="event-details-menu__content__item__icon" fontSize="small" />
            <div className="event-details-menu__content__item__text">
              {selectedEvent.taskFrom.from}
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EventDetailsMenu;

import { Tooltip } from '@mui/material';
import { ReactNode, FC, ReactElement } from 'react';
import { clsx } from 'clsx';

import './CustomTooltip.scss';

interface CustomTooltipProps {
  title: ReactNode;
  children: ReactElement;
  className?: string;
}

const CustomTooltip: FC<CustomTooltipProps> = ({ title, children, className = '' }) => {
  return (
    <Tooltip
      title={title}
      arrow
      classes={{
        tooltip: clsx('custom-tooltip__tooltip', className),
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;

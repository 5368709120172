import React from 'react';
import clsx from 'clsx';

import './SubscriptionPlanItem.scss';

interface SubscriptionPlanItemProps {
  title: string;
  description: string;
  isSelected?: boolean;
  isBestDeal?: boolean;
  onClick: () => void;
}

const SubscriptionPlanItem: React.FC<SubscriptionPlanItemProps> = ({
  title,
  description,
  isSelected = false,
  isBestDeal = false,
  onClick,
}) => {
  return (
    <div
      className={clsx('subscription-plan-item', isSelected && 'subscription-plan-item--selected')}
      onClick={onClick}
    >
      {isBestDeal && <div className="subscription-plan-item__best-deal">Best Deal</div>}
      <div className="subscription-plan-item__title-group">
        <div className="subscription-plan-item__title-group__title">{title}</div>
        <div className="subscription-plan-item__title-group__description">{description}</div>
      </div>
    </div>
  );
};

export default SubscriptionPlanItem;

import axios from 'axios';

const accessTokenUrl = 'https://todoist.com/oauth/access_token';
const redirectUri = window.location.origin + '/callback/todoist';

export const exchangeCodeForToken = async (code: string) => {
  try {
    const response = await axios.post(accessTokenUrl, {
      client_id: process.env.REACT_APP_TODOIST_CLIENT_ID,
      client_secret: process.env.REACT_APP_TODOIST_CLIENT_SECRET,
      code,
      redirect_uri: redirectUri,
    });

    return response.data.access_token;
  } catch (error) {
    console.error('Error exchanging code for token:', error);
    throw error;
  }
};

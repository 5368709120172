import clsx from 'clsx';
import React, { useState } from 'react';
import { ICONS } from '../constants';
import './TodoList.scss';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Popover } from '@mui/material';
import { ConnectTodosDropdown } from '../../TodoIntegration';
import { useStartTodoAuth } from '../../../hooks';

interface TodoListProps {
  className?: string;
}

const TodoList: React.FC<TodoListProps> = ({ className }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { connectedTodos, handleOpenTodoAuth, generatingTodoAuth } = useStartTodoAuth({});
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={clsx('todos', className)}>
      {connectedTodos.map(({ source }, index) => (
        <div className="todos__button" key={index}>
          <img src={ICONS[source]} alt={source} className="todos__button__icon" />
          <div className="todos__button__text">
            {source?.charAt(0)?.toUpperCase() + source?.slice(1)}
          </div>
        </div>
      ))}
      <button aria-label="Add todos" onClick={handleClick} className="todos__button">
        <AddIcon className="todos__button__icon" />
        <div className="todos__button__text">TODOs</div>
      </button>
      <ConnectTodosDropdown
        anchorEl={anchorEl}
        onClose={handleClose}
        onClickItem={handleOpenTodoAuth}
        generatingTodoAuth={generatingTodoAuth}
        connectedTodos={connectedTodos}
      />
    </div>
  );
};

export default TodoList;

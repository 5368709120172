import { useState } from 'react';
import { googleCalAuth, msAuth } from '../data-access';
import { CalendarType } from '@demind-inc/core';

export const useConnectCalendar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const connectCal = async (type: CalendarType) => {
    try {
      setIsLoading(true);
      if (type === 'google') {
        await googleCalAuth.signinRedirect();
      } else if (type === 'outlook') {
        await msAuth.signinRedirect();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError('Something wrong while connecting google calendars');
    }
  };

  return { connectCal, isLoading, error };
};

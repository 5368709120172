import { useMutation, useQueryClient } from '@tanstack/react-query';

import { calendarApi } from '../api';

interface UseSyncGCalendarsListParams {
  userId: string;
  rootEmail: string;
  accessToken?: string;
  refreshToken?: string;
  expiryDate?: string;
}

export function useSyncGCalendarsList() {
  const queryClient = useQueryClient();

  const syncGCalendarsListMutation = useMutation({
    mutationFn: ({
      userId,
      rootEmail,
      accessToken,
      refreshToken,
      expiryDate,
    }: UseSyncGCalendarsListParams) => {
      return calendarApi
        .syncGCalendarsList(userId, rootEmail, {
          accessToken,
          refreshToken,
          expiryDate,
        })
        .then(({ data }) => data as string[]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.meta'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.events'] });
    },
  });

  return {
    syncGCalendarsList: syncGCalendarsListMutation.mutateAsync,
    ...syncGCalendarsListMutation,
  };
}

import { FC } from 'react';

import './CircadianTooltipContent.scss';

interface CircadianTooltipContentProps {}

const CircadianTooltipContent: FC<CircadianTooltipContentProps> = () => {
  return (
    <div className="circadian-tooltip-content">
      <div className="circadian-tooltip-content__title">⚡️ Energy</div>
      <p className="circadian-tooltip-content__description">
        Energy scores represent your predicted energy levels from 0 to 100. The score is calculated
        using circadian rhythms and your sleep data. The higher the wave, the higher your score —
        indicating the best times for you to perform challenging tasks. Your self-reported data,
        along with the category of each calendar event, further fine-tune your energy wave.
        <a
          href="https://demind-inc.notion.site/How-We-Calculate-Energy-5764b5cef9824a8e8523868216d49228?pvs=4"
          target="_blank"
          className="circadian-tooltip-content__description--see-more"
        >
          (learn more)
        </a>
      </p>
    </div>
  );
};

export default CircadianTooltipContent;

import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { FC, lazy } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { Path } from './path';
import {
  ConnectDevicesPage,
  ConnectTodoPage,
  EnergyPreferencePage,
  GoogleCallback,
  InitialSyncPage,
  OutlookCallback,
  PaywallPage,
  TerraCallback,
  TodoistAuthCallback,
  TrelloAuthCallback,
} from '../pages';
import App from '../App';
import { RequireAuth, LoginContainer } from '.';
import {
  CalendarProvider,
  CircadianProvider,
  CreateEventMenuProvider,
  EventDetailsMenuProvider,
  MetricsProvider,
  TodoTasksProvider,
} from '../data-access';
import { useBreakpoints } from '../utils';
import { NotSupported } from '../components';

const LazyDashboard = lazy(() => import('../pages/DashboardPage/DashboardPage'));

export const AppRouter: FC = () => {
  const { isDesktop } = useBreakpoints();

  return (
    <BrowserRouter>
      <Routes>
        <Route element={isDesktop ? <Outlet /> : <NotSupported />}>
          <Route path="/" element={<App />}>
            <Route index element={<LoginContainer />}></Route>
            <Route path={Path.PAYWALL} element={<PaywallPage />} />
            <Route
              path="/*"
              element={
                <RequireAuth>
                  <CalendarProvider>
                    <TodoTasksProvider>
                      <CircadianProvider>
                        <MetricsProvider>
                          <CreateEventMenuProvider>
                            <EventDetailsMenuProvider>
                              <DndProvider backend={HTML5Backend}>
                                <Routes>
                                  <Route path={Path.DASHBOARD} element={<LazyDashboard />} />
                                  <Route
                                    path={Path.CALLBACK.TODOIST_CALLBACK}
                                    element={<TodoistAuthCallback />}
                                  />
                                  <Route
                                    path={Path.CALLBACK.TRELLO_CALLBACK}
                                    element={<TrelloAuthCallback />}
                                  />
                                  <Route
                                    path={Path.CALLBACK.GOOGLE_CALLBACK}
                                    element={<GoogleCallback />}
                                  />
                                  <Route
                                    path={Path.CALLBACK.OUTLOOK_CALLBACK}
                                    element={<OutlookCallback />}
                                  />
                                  <Route
                                    path={Path.ONBOARDING.CONNECT_DEVICES}
                                    element={<ConnectDevicesPage />}
                                  />
                                  <Route
                                    path={Path.ONBOARDING.INITIAL_SYNC}
                                    element={<InitialSyncPage />}
                                  />
                                  <Route
                                    path={Path.ONBOARDING.ENERGY_PREFERENCE}
                                    element={<EnergyPreferencePage />}
                                  />
                                  <Route
                                    path={Path.ONBOARDING.CONNECT_TODO}
                                    element={<ConnectTodoPage />}
                                  />
                                  <Route
                                    path={Path.CALLBACK.TERRA_CALLBACK}
                                    element={<TerraCallback />}
                                  />
                                </Routes>
                              </DndProvider>
                            </EventDetailsMenuProvider>
                          </CreateEventMenuProvider>
                        </MetricsProvider>
                      </CircadianProvider>
                    </TodoTasksProvider>
                  </CalendarProvider>
                </RequireAuth>
              }
            ></Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { accountApi } from '../api';

interface UseCancelStripeSubscriptionParams {
  userId: string;
}

export function useCancelStripeSubscription() {
  const queryClient = useQueryClient();

  const cancelSubscription = useMutation({
    mutationFn: ({ userId }: UseCancelStripeSubscriptionParams) => {
      return accountApi.cancelStripeSubscription(userId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.subscriptions'] });
    },
  });

  return {
    cancelStripeSubscription: cancelSubscription.mutateAsync,
    ...cancelSubscription,
  };
}

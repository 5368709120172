import React from 'react';
import { Button } from '@mui/material';

import './SyncErrorTooltipContent.scss';

interface SyncErrorTooltipContentProps {
  disabled?: boolean;
  onReconnect: () => void;
}

const SyncErrorTooltipContent: React.FC<SyncErrorTooltipContentProps> = ({
  disabled = false,
  onReconnect,
}) => {
  return (
    <div className="sync-error-tooltip-content">
      <div className="sync-error-tooltip-content__title">Sync Error</div>
      <div className="sync-error-tooltip-content__description">Please authenticate again.</div>
      <Button variant="outlined" onClick={onReconnect} disabled={disabled}>
        Reconnect
      </Button>
    </div>
  );
};

export default SyncErrorTooltipContent;

import { FC } from 'react';

import './DashboardPage.scss';

import { SideBar } from './SideBar';
import KanbanView from './KanbanView/KanbanView';
import { CalendarView } from './CalendarView';

const DashboardPage: FC = () => {
  return (
    <div className="dashboard-page">
      <SideBar className="dashboard-page__side-bar" />
      <KanbanView className="dashboard-page__kanban-view" />
      <CalendarView className="dashboard-page__calendar-view" />
    </div>
  );
};

export default DashboardPage;

import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@mui/material';
import {
  EventCategoryEnergyPreference,
  EventCategoryEnergyPreferenceLevel,
} from '@demind-inc/core';
import { unionBy } from 'lodash';
import { useNavigate } from 'react-router-dom';

import './EnergyPreferencePage.scss';
import {
  EnergyPrefSelector,
  eventCategoriesForEnergyPref,
  OnboardingContainer,
} from '../../../components';
import {
  useAuthContext,
  useUpdateEventCategoryEnergyPref,
  useUpdateOnboardingStatus,
} from '../../../data-access';
import { Path } from '../../../routing/path';
import EnergyPrefAsset from '../../../assets/onboarding/energyPreference.gif';

const EnergyPreferencePage: React.FC = () => {
  const [energyPrefs, setEnergyPrefs] = useState<EventCategoryEnergyPreference[]>([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepPrefLevel, setCurrentStepPrefLevel] =
    useState<EventCategoryEnergyPreferenceLevel>('more_energized');

  const { user } = useAuthContext();
  const { updateEventCategoryEnergyPref } = useUpdateEventCategoryEnergyPref();
  const navigate = useNavigate();
  const { updateOnboardingStatus } = useUpdateOnboardingStatus();

  const maxStep = eventCategoriesForEnergyPref.length;

  const currentStepPref = useMemo(
    () => eventCategoriesForEnergyPref[currentStep - 1],
    [currentStep]
  );

  // Set the default level of the current step preference
  useEffect(() => {
    const currentStepPrefDefaultLevel =
      energyPrefs.find(({ categoryId }) => categoryId === currentStepPref.id)?.level ||
      'more_energized';

    setCurrentStepPrefLevel(currentStepPrefDefaultLevel);
  }, [currentStepPref]);

  const handleNextBtn = async () => {
    const newData: EventCategoryEnergyPreference = {
      categoryId: currentStepPref.id,
      categoryName: currentStepPref.name,
      level: currentStepPrefLevel,
    };
    const newEnergyPrefs = unionBy([newData], energyPrefs, 'categoryId');
    setEnergyPrefs(newEnergyPrefs);

    if (currentStep === maxStep) {
      await updateEventCategoryEnergyPref({
        preferenceId: user.preferenceId!,
        preferences: newEnergyPrefs,
      });
      updateOnboardingStatus({
        userId: user.userId,
        newStatus: { energySetting: 'answered' },
      });
      navigate(Path.ONBOARDING.CONNECT_TODO);
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  return (
    <div className="energy-preference-page">
      <OnboardingContainer
        leftChildren={
          <img
            src={EnergyPrefAsset}
            alt="lifestack energy preference"
            className="energy-preference-page__asset"
          />
        }
        rightChildren={
          <div className="energy-preference-page__right-section">
            <div className="energy-preference-page__content">
              <div className="energy-preference-page__content__title">Personalize your energy</div>
              <div className="energy-preference-page__content__question">
                How do you feel after
                <br />
                <p className="energy-preference-page__content__question--strong">
                  {currentStepPref.name}
                </p>
                ?
              </div>
              <EnergyPrefSelector
                level={currentStepPrefLevel}
                onSelectLevel={(level) => setCurrentStepPrefLevel(level)}
              />
            </div>
            <div className="energy-preference-page__btn-group">
              <Button
                variant="contained"
                className={'energy-preference-page__btn'}
                onClick={handleNextBtn}
              >
                Next ({currentStep}/{maxStep})
              </Button>
              {currentStep > 1 && (
                <Button
                  variant="text"
                  className="energy-preference-page__back-btn"
                  onClick={handleBack}
                >
                  Back
                </Button>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default EnergyPreferencePage;

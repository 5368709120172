import { FC } from 'react';

import './PrivacyPolicy.scss';

const PrivacyPolicy: FC = () => {
  return (
    <span className="privacy-policy">
      By signing in, you acknowledge and agree to Lifestack’s{' '}
      <a
        className="privacy-policy--highlight"
        href="https://demind-inc.notion.site/Privacy-Policy-867d4c361ca744e0a01be8645e951998?pvs=4"
        target="_blank"
      >
        Legal Terms
      </a>{' '}
      and{' '}
      <a
        className="privacy-policy--highlight"
        href="https://demind-inc.notion.site/Privacy-Policy-867d4c361ca744e0a01be8645e951998?pvs=4"
        target="_blank"
      >
        Privacy Policy
      </a>
    </span>
  );
};

export default PrivacyPolicy;

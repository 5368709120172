import React, { useState } from 'react';
import { AddOutlined } from '@mui/icons-material';
import { IconButton, MenuItem, Popover } from '@mui/material';
import { CalendarType } from '@demind-inc/core';

import { CalendarOptionsType, calendars, ICONS } from '../../constants';
import './ManageCalendarsBtn.scss';

interface ManageCalendarsBtnProps {
  onConnect: (calendarType: CalendarType) => void;
  disabledCalendarType?: CalendarType[];
}

interface ManageCalendarModalHeaderProps {
  onClose: () => void;
}

interface ManageCalendarModalContentProps {
  calendars: CalendarOptionsType[];
  disabledCalendarType: CalendarType[];
  onConnect: (calendarType: CalendarType) => void;
}

const ManageCalendarsBtn: React.FC<ManageCalendarsBtnProps> = ({
  disabledCalendarType = [],
  onConnect,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'manage-calendars' : undefined;

  return (
    <>
      <button
        aria-describedby={id}
        id="manage-calendars"
        className="manage-calendars-btn"
        onClick={handleClick}
      >
        <div className="manage-calendars-btn__icon-container">
          <AddOutlined />
        </div>
        Calendar Accounts
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        className="manage-calendars-btn__popover"
      >
        <div className="manage-calendars-btn__modal">
          <ModalHeader onClose={handleClose} />
          <ModalContent
            disabledCalendarType={disabledCalendarType}
            calendars={calendars}
            onConnect={onConnect}
          />
        </div>
      </Popover>
    </>
  );
};

const ModalHeader: React.FC<ManageCalendarModalHeaderProps> = ({ onClose }) => (
  <div className="manage-calendars-btn__modal__header">
    <p>Add Calendars</p>
    <IconButton onClick={onClose} className="manage-calendars-btn__modal__close-button">
      <img
        src={ICONS['close']}
        alt="Close"
        className="manage-calendars-btn__modal__close-button__icon"
      />
    </IconButton>
  </div>
);

const ModalContent: React.FC<ManageCalendarModalContentProps> = ({
  calendars,
  disabledCalendarType,
  onConnect,
}) => (
  <div className="manage-calendars-btn__modal__content">
    {calendars.map((calendarType) => (
      <MenuItem
        key={calendarType.id}
        className="manage-calendars-btn__modal__item"
        onClick={() => onConnect(calendarType.id)}
        disabled={disabledCalendarType.includes(calendarType.id)}
      >
        <div className="manage-calendars-btn__modal__item__text">
          <AddOutlined fontSize="small" className="manage-calendars-btn__modal__item__icon" />
          {calendarType.calendar}
        </div>
        <calendarType.icon />
      </MenuItem>
    ))}
  </div>
);

export default ManageCalendarsBtn;

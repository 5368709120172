import React from 'react';
import { ALL_TODO_INTEGRATIONS_TYPE, TodoIntegration, TodoIntegrationType } from '@demind-inc/core';

import './ConnectTodosList.scss';
import { ConnectTodosListItem } from '../ConnectTodosListItem';

interface ConnectTodosListProps {
  connectedTodos: TodoIntegration[];
  onConnectTodo: (todoType: TodoIntegrationType) => void;
}

const ConnectTodosList: React.FC<ConnectTodosListProps> = ({ connectedTodos, onConnectTodo }) => {
  return (
    <div className="connect-todos-list">
      {ALL_TODO_INTEGRATIONS_TYPE.map((todoType) => (
        <ConnectTodosListItem
          todoType={todoType}
          key={todoType}
          connected={connectedTodos.some(({ source }) => source === todoType)}
          onConnect={() => onConnectTodo(todoType)}
        />
      ))}
    </div>
  );
};

export default ConnectTodosList;

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { calendarApi } from '../api';
import { CalendarEvent } from '@demind-inc/core';
import { useCallback } from 'react';

interface UseCalendarEventsParams {
  userId?: string;
  startDate?: string;
  endDate?: string;
  enabled?: boolean;
}

interface UseCalendarEventsQueryParams {
  queryKey: [
    'lifestack.calendar.events',
    { userId?: string; startDate?: string; endDate?: string },
  ];
}

export const useCalendarEvents = ({
  userId = '',
  startDate,
  endDate,
  enabled = true,
}: UseCalendarEventsParams) => {
  const calendarEventsQuery = useQuery({
    queryKey: ['lifestack.calendar.events', { userId, startDate, endDate }],
    queryFn: ({ queryKey }: UseCalendarEventsQueryParams) => {
      const [, { userId = '', startDate, endDate }] = queryKey;

      return calendarApi
        .getCalendarEvents(userId, startDate, endDate)
        .then(({ data }) => data as CalendarEvent[]);
    },
    refetchOnWindowFocus: true,
    enabled: enabled && !!userId,
  });

  return {
    ...calendarEventsQuery,
    events: calendarEventsQuery.data ?? [],
    isLoading: enabled ? calendarEventsQuery.isLoading : false,
  };
};

export const useCalendarEventsCache = () => {
  const queryClient = useQueryClient();

  const findInCache = useCallback(
    ({ userId, startDate, endDate }: { userId?: string; startDate?: string; endDate?: string }) => {
      const calendar = queryClient.getQueryData<CalendarEvent[]>([
        'lifestack.calendar.events',
        { userId, startDate, endDate },
      ]);

      return calendar ? calendar.filter((item) => !!item) : [];
    },
    [queryClient]
  );

  return { findInCache };
};

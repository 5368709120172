import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OnboardingStatus } from '@demind-inc/core';

import { accountApi } from '../api';

interface UseUpdateOnboardingStatusParams {
  userId: string;
  newStatus: Partial<OnboardingStatus>;
}

export const useUpdateOnboardingStatus = () => {
  const queryClient = useQueryClient();

  const updateOnboardingStatusMutation = useMutation({
    mutationFn: ({ userId, newStatus }: UseUpdateOnboardingStatusParams) => {
      return accountApi.updateOnboardingStatus(userId, newStatus).then((data) => data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.user'] });
    },
  });

  return {
    updateOnboardingStatus: updateOnboardingStatusMutation.mutateAsync,
    ...updateOnboardingStatusMutation,
  };
};

import React, { FC, useState } from 'react';
import { upperFirst } from 'lodash';
import { Button } from '@mui/material';
import SubscriptionTimeline from '../../../../assets/settings/subscription-timeline.png';
import './SubscriptionSettings.scss';
import {
  useAuthContext,
  useCancelStripeSubscription,
  useSubscriptionContext,
} from '../../../../data-access';
import { UnsubscribeDialog } from './UnsubscribeDialog';
import { Subscription } from '@demind-inc/core';
import {
  checkFreeTrial,
  getFormattedDates,
  getFormattedPrice,
  getPriceAfterTrial,
  getPriceWithRecurring,
} from './helpers';

const SubscriptionSettings: FC = () => {
  const [unsubscribeDialogVisible, setUnsubscribeDialogVisible] = useState(false);
  const { currentSubscription } = useSubscriptionContext();
  const { cancelStripeSubscription, error: cancelError } = useCancelStripeSubscription();
  const { user, signOut } = useAuthContext();

  const subscription = currentSubscription as Subscription;

  const isInFreeTrial = subscription ? checkFreeTrial(subscription) : false;
  const formattedDates = subscription ? getFormattedDates(subscription) : undefined;
  const price = subscription ? getFormattedPrice(subscription) : '';
  const priceAfterTrial = subscription ? getPriceAfterTrial(subscription, price) : '';
  const priceWithRecurring = subscription ? getPriceWithRecurring(subscription, price) : '';

  const handleSignOut = () => {
    signOut();
    setTimeout(() => window.location.reload(), 1000);
  };

  const handleDelete = () => {
    window.open('https://forms.gle/Mtyn6QBLvNrxFKHk9', '_blank');
  };

  const handleUnsubscribe = () => {
    cancelStripeSubscription({ userId: user.userId });
  };

  return (
    <div className="subscription-settings">
      <div className="subscription-settings__plan">
        <div className="subscription-settings__plan__title">
          {upperFirst(subscription?.recurring.toLowerCase())} plan
          <div>
            {isInFreeTrial ? (
              <div className="subscription-settings__free-trial--wrapper">
                <img
                  src={SubscriptionTimeline}
                  alt="Subscription Timeline"
                  className="subscription-settings__timeline"
                />
                <div className="subscription-settings__free-trial-container ">
                  <div className="subscription-settings__free-trial-container__inner-container">
                    <p>Due {formattedDates?.startFreeTrialDate} (Cancel Anytime)</p>
                    <p>
                      14 days free <span>$0</span>
                    </p>
                  </div>
                  <div className="subscription-settings__free-trial-container__inner-container">
                    <p>Due {formattedDates?.startChargeDate}</p>
                    <p>{priceAfterTrial}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="subscription-settings__free-trial-container">
                {priceWithRecurring}
              </div>
            )}
          </div>
        </div>
      </div>

      {!!cancelError && <div className="subscription-settings__error">*{cancelError?.message}</div>}

      {subscription?.deviceFrom === 'web' && (
        <Button
          variant="outlined"
          className="subscription-settings__unsubscribe"
          onClick={() => setUnsubscribeDialogVisible(true)}
        >
          Cancel subscription
        </Button>
      )}

      <UnsubscribeDialog
        open={unsubscribeDialogVisible}
        onClose={() => setUnsubscribeDialogVisible(false)}
        onUnsubscribe={handleUnsubscribe}
      />

      <div className="subscription-settings__account">
        <div onClick={handleSignOut}>Sign Out</div>
        <div onClick={handleDelete} className="subscription-settings__account__delete">
          Delete Account
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSettings;

import { FC } from 'react';
import { Check } from '@mui/icons-material';
import clsx from 'clsx';

import './SubscriptionPlanContent.scss';

interface SubscriptionPlanContentProps {
  className?: string;
}

const SubscriptionPlanContent: FC<SubscriptionPlanContentProps> = ({ className }) => {
  return (
    <div className={clsx('subscription-plan-content', className)}>
      <div className="subscription-plan-content__item">
        <Check className="subscription-plan-content__item__check" />
        <div className="subscription-plan-content__item__title">14-day free trial</div>
      </div>
      <div className="subscription-plan-content__item">
        <Check className="subscription-plan-content__item__check" />
        <div className="subscription-plan-content__item__title">
          Unlimited calendar events and tasks
        </div>
      </div>
      <div className="subscription-plan-content__item">
        <Check className="subscription-plan-content__item__check" />
        <div className="subscription-plan-content__item__title">Chrome extension</div>
      </div>
      <div className="subscription-plan-content__item">
        <Check className="subscription-plan-content__item__check" />
        <div className="subscription-plan-content__item__title">Mobile app</div>
      </div>
    </div>
  );
};

export default SubscriptionPlanContent;

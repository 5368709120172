import { CircularProgress } from '@mui/material';
import { FC } from 'react';

import './SyncingChip.scss';

const SyncingChip: FC = () => {
  return (
    <div className="syncing-chip">
      <div className="syncing-chip__text">Syncing</div>
      <CircularProgress size={12} />
    </div>
  );
};

export default SyncingChip;

import { FC } from 'react';
import './AccountSettings.scss';
import { useAuthContext } from '../../../../data-access';
import { first } from 'lodash';

const AccountSettings: FC = () => {
  const { user } = useAuthContext();

  return (
    <div className="account-settings">
      <div className="account-settings__title">Profile </div>
      {user.photoUrl ? (
        <img alt="Profile image" src={user.photoUrl} className="account-settings__profile__img" />
      ) : (
        <div className="account-settings__profile-img-placeholder">
          {first(user.email?.toUpperCase())}
        </div>
      )}
    </div>
  );
};

export default AccountSettings;

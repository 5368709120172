import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';

import './GoogleCallback.scss';
import {
  googleCalAuth,
  useAuthContext,
  useCalendarContext,
  useSyncGCalendarsList,
} from '../../../data-access';
import { Path } from '../../../routing';

const GoogleCallback: FC = () => {
  const location = useLocation();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { syncGCalendarsList } = useSyncGCalendarsList();
  const { reSyncCalEvents } = useCalendarContext();

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code') ?? '';

  useEffect(() => {
    if (!user.userId || !code) {
      return;
    }

    const update = async () => {
      const cred = await googleCalAuth.signinCallback(window.location.href);

      if (cred) {
        const newCalendarIds = await syncGCalendarsList({
          userId: user.userId,
          rootEmail: cred.profile.email,
          accessToken: cred.access_token,
          refreshToken: cred.refresh_token,
          expiryDate: dayjs.unix(cred.expires_at).toISOString(),
        });

        reSyncCalEvents(newCalendarIds);
      }

      navigate(Path.DASHBOARD);
    };

    update();
  }, [user?.userId, code]);

  return (
    <div className="google-callback-page">
      <CircularProgress />
    </div>
  );
};

export default GoogleCallback;

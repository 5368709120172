import './InstallExtension.scss';
import ExtensionImg from '../../../../assets/settings/extension.png';
import ChromeButtonImg from '../../../../assets/settings/extension-button.png';

const InstallExtension = () => {
  return (
    <div className="extension-container">
      <p className="extension-title">Chrome Extension</p>
      <p className="extension-subtitle">See your energy anytime with Lifestack Extension</p>
      <div className="extension">
        <div className="extension-preview">
          <img src={ExtensionImg} alt="Extension Preview" draggable="false" />
        </div>
        <a
          href="https://chromewebstore.google.com/detail/lifestack-extension/mbpcaihbaecjnndomdpkigmmhdnfccjb"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={ChromeButtonImg} alt="Chrome Web Store" draggable="false" />
        </a>
      </div>
    </div>
  );
};

export default InstallExtension;

import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      xs: 480,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette: {
    primary: {
      main: '#680ddb',
    },
  },
});

export default theme;

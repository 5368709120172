import { Construction as ConstructionIcon } from '@mui/icons-material';
import type { FC } from 'react';

import './NotSupported.scss';

const NotSupported: FC = () => {
  return (
    <div className="not-supported">
      <ConstructionIcon className="not-supported__icon" />
      <div className="not-supported__text">This size of screen is not supported yet.</div>
    </div>
  );
};

export default NotSupported;

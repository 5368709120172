import dayjs from 'dayjs';
import { formatCurrencyPrice } from '../../../../pages';
import { Subscription } from '@demind-inc/core';

function checkFreeTrial(subscription: Subscription): boolean {
  return dayjs().isBefore(dayjs(subscription.freeTrial.endDate));
}

function getFormattedDates(subscription: Subscription) {
  return {
    startFreeTrialDate: dayjs(subscription.freeTrial.startDate).format('YYYY-MM-DD'),
    startChargeDate: dayjs(subscription.freeTrial.endDate).format('YYYY-MM-DD'),
  };
}

function getFormattedPrice(subscription: Subscription): string {
  return formatCurrencyPrice(subscription.price.currency, subscription.price.amount);
}

function getPriceAfterTrial(subscription: Subscription, price: string): string {
  const priceMapping: Record<Subscription['status'], string> = {
    freeTrial: price || 'Start Charging',
    unsubscribed: 'Unsubscribed',
    active: '',
    expired: '',
  };
  return priceMapping[subscription.status];
}

function getPriceWithRecurring(subscription: Subscription, price: string): string {
  const priceMapping: Record<Subscription['recurring'], string> = {
    ANNUAL: `${price} / Year`,
    MONTHLY: `${price} / Month`,
  };
  return priceMapping[subscription.recurring];
}

export {
  checkFreeTrial,
  getFormattedDates,
  getFormattedPrice,
  getPriceAfterTrial,
  getPriceWithRecurring,
};

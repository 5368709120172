import { CSSProperties, FC } from 'react';
import clsx from 'clsx';
import { CircadianPhase } from '@demind-inc/core';

import './ZoneLabelTimeline.scss';
import { circadianPhaseInfo, PeakDipStartEndSet } from '../../../../data-access';
import { HOUR_BLOCK_HEIGHT } from '../../CalendarTimeline/constants';
import dayjs from 'dayjs';
import { ZoneLabelExplaTooltipContent } from './ZoneLabelExplaTooltipContent';
import { CustomTooltip } from '../../../common';

interface ZoneLabelTimelineProps {
  peaksDipsBoundaries: PeakDipStartEndSet;
  className?: string;
}

const buildZoneStyle = (startTime: string, endTime: string): CSSProperties => {
  const startTimeHour = dayjs(startTime).hour() + dayjs(startTime).minute() / 60;
  const duration = dayjs(endTime).diff(dayjs(startTime), 'minutes') / 60;

  const top = Math.round(startTimeHour * HOUR_BLOCK_HEIGHT);
  const height = Math.round(duration * HOUR_BLOCK_HEIGHT);

  return {
    top: `${top}px`,
    height: `${height}px`,
    width: '100%',
  };
};

const ZoneLabelTimeline: FC<ZoneLabelTimelineProps> = ({ peaksDipsBoundaries, className }) => {
  return (
    <div className={clsx('zone-label-timeline', className)}>
      {Object.entries(peaksDipsBoundaries).map(([phase, { start, end }]) => {
        const phaseInfo = circadianPhaseInfo[phase as CircadianPhase];

        const style = {
          ...buildZoneStyle(start, end),
          backgroundColor: `${phaseInfo?.color}1A`,
        };

        const zoneTime = `${dayjs(start).format('HH:mm')} - ${dayjs(end).format('HH:mm')}`;

        return (
          <div className="zone-label-timeline__item" style={style} key={phase}>
            <CustomTooltip
              title={
                <ZoneLabelExplaTooltipContent
                  title={phaseInfo.title}
                  time={zoneTime}
                  color={phaseInfo.color}
                  explanation={phaseInfo.explanation}
                />
              }
            >
              <div className="zone-label-timeline__item__label" style={{ color: phaseInfo.color }}>
                {phaseInfo?.label}
                {phaseInfo.labelIcon}{' '}
              </div>
            </CustomTooltip>
          </div>
        );
      })}
    </div>
  );
};

export default ZoneLabelTimeline;

import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Cookies from 'js-cookie';

import { initGA4, initSentry, setUserProperties, trackPageViewGA4 } from './utils';
import { useAuthContext } from './data-access';

initSentry();
initGA4();

const App = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectTo = urlParams.get('redirect_to');
  const location = useLocation();
  const { user } = useAuthContext();

  useEffect(() => {
    trackPageViewGA4(location.pathname);
  }, [location]);

  useEffect(() => {
    if (!user.userId) {
      return;
    }

    setUserProperties(user.userId);
  }, [user.userId]);

  useEffect(() => {
    if (redirectTo) {
      Cookies.set('redirect_to', redirectTo);
    }
  }, []);

  return (
    <div className="wrapper">
      <Outlet />
    </div>
  );
};

export default App;

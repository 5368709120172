import { useMutation, useQueryClient } from '@tanstack/react-query';
import { calendarApi } from '../api';
import { CalendarEvent } from '@demind-inc/core';

interface UseDeleteCalendarEventParams {
  calendarId: string;
  userId: string;
  eventId: string;
}

export function useDeleteCalendarEvent() {
  const queryClient = useQueryClient();

  const deleteCalendarEventMutation = useMutation({
    mutationFn: ({ calendarId, eventId, userId }: UseDeleteCalendarEventParams) => {
      return calendarApi.deleteCalendarEvent(userId, calendarId, eventId);
    },
    onMutate: async ({ eventId: targetEventId }: UseDeleteCalendarEventParams) => {
      await queryClient.cancelQueries({ queryKey: ['lifestack.calendar.events'] });
      queryClient.setQueriesData<CalendarEvent[]>(
        { queryKey: ['lifestack.calendar.events'] },
        (prevEvents) => {
          return prevEvents?.filter(({ eventId }) => eventId !== targetEventId);
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.events'] });
    },
  });

  return {
    deleteCalendarEvent: deleteCalendarEventMutation.mutate,
    ...deleteCalendarEventMutation,
  };
}

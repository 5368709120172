import { FC } from 'react';

import './NoWearableChip.scss';

const NoWearableChip: FC = () => {
  return (
    <div className="no-wearable-chip">
      <div className="no-wearable-chip__text">
        Sleep Data Not Recorded
        <div className="no-wearable-chip__text__small">Sync by opening your wearable's app</div>
      </div>
    </div>
  );
};

export default NoWearableChip;

import { useMutation } from '@tanstack/react-query';
import { TerraWidgetResponse } from 'terra-api/lib/cjs/API/GenerateWidgetSessions';
import { integrationsApi } from '../api';

interface UseTerraWidgetParams {
  metricId: string;
  redirectTo?: string;
}

export const useTerraWidget = () => {
  const terraWidget = useMutation({
    mutationFn: async ({ metricId, redirectTo }: UseTerraWidgetParams) => {
      integrationsApi.generateTerraWidget(metricId, redirectTo).then(({ data }) => {
        window.location.href = (data as TerraWidgetResponse).url;
      });
    },
  });

  return {
    terraWidget: terraWidget.mutate,
    ...terraWidget,
  };
};

import { UserManager, UserManagerSettings } from 'oidc-client-ts';

const settings: UserManagerSettings = {
  authority: 'https://accounts.google.com',
  client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
  redirect_uri: `${window.location.origin}/callback/google`,
  response_type: 'code',
  scope:
    'openid email https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly',
  automaticSilentRenew: true,
  extraQueryParams: { access_type: 'offline', prompt: 'consent' },
};

export const googleCalAuth = new UserManager(settings);

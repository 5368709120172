import dayjs from 'dayjs';

/**
 * Transforms a Unix timestamp in milliseconds to an ISO string representation of the date.
 * @param expiryDate - The Unix timestamp in milliseconds.
 * @returns The ISO string representation of the date.
 */
export const transformExpiryDate = (expireIn: number) => {
  return dayjs().add(expireIn, 'second').toISOString();
};

import React from 'react';
import { useAuthContext, useTerraWidget } from '../../../../data-access';
import { MobileSdkOnlyWearbales, WEARABLES } from '../../constants';
import './WearablesSettings.scss';
import { Path } from '../../../../routing';
import clsx from 'clsx';

const WearablesSettings = () => {
  const { terraWidget, isPending: isGeneratingWidget } = useTerraWidget();
  const { user } = useAuthContext();
  const { connectedDevices } = user;

  const handleClick = () => {
    terraWidget({ metricId: user.metricId, redirectTo: Path.DASHBOARD });
  };

  return (
    <div className="wearables-settings">
      <p className="wearables-settings__title">Wearables</p>
      <div className="wearables-settings__grid">
        {WEARABLES?.map(({ id, name, Icon }) => {
          const isConnected = connectedDevices?.find(
            (device) => device.deviceName?.toLowerCase() === id
          );
          const isOnlyMobileSdkSupport = MobileSdkOnlyWearbales.includes(id);

          return (
            <button
              onClick={() => handleClick()}
              className={clsx(
                'wearables-settings__button',
                isConnected && 'wearables-settings__button--connected',
                !isConnected && 'wearables-settings__button--disconnected'
              )}
              disabled={!!isConnected || isOnlyMobileSdkSupport || isGeneratingWidget}
              key={id}
            >
              <div className="wearables-settings__button-content">
                <Icon className="wearables-settings__icon" />
                {name}
              </div>
              <span className="wearables-settings__connection-status">
                {isConnected ? 'Connected' : 'Connect'}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default WearablesSettings;

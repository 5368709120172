import React from 'react';
import { Navigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { Status, useAuthContext } from '../data-access';
import { LoadingOverlay } from '../components';
import { AuthPage } from '../pages';
import { Path } from './path';

const LoginContainer: React.FC = () => {
  const { status, user, isUserFetching } = useAuthContext();

  if (status === Status.UN_AUTHORIZED) {
    return <AuthPage />;
  }

  if (status === Status.LOADING || isUserFetching || isEmpty(user)) {
    return <LoadingOverlay />;
  }

  const {
    connectWearables = 'notAnswered',
    energySetting = 'notAnswered',
    connectTodo = 'notAnswered',
    initialSync = 'notAnswered',
  } = user?.onboarding || {};
  if (connectWearables === 'notAnswered') {
    return <Navigate to={Path.ONBOARDING.CONNECT_DEVICES} replace />;
  }
  if (energySetting === 'notAnswered') {
    return <Navigate to={Path.ONBOARDING.ENERGY_PREFERENCE} replace />;
  }
  if (connectTodo === 'notAnswered') {
    return <Navigate to={Path.ONBOARDING.CONNECT_TODO} replace />;
  }
  if (initialSync === 'notAnswered') {
    return <Navigate to={Path.ONBOARDING.INITIAL_SYNC} replace />;
  }

  return <Navigate to={Path.DASHBOARD} replace />;
};

export default LoginContainer;

import React, { FC } from 'react';

import './NotificationSettings.scss';

const NotificationSettings: FC = () => {
  return (
    <div className="notification-settings">
      <div className='notification-settings__title'>
        Notifications
      </div>
      <div className="notification-settings__title__light">
        Currently, notifications are not supported on the web
      </div>
    </div>
  );
};

export default NotificationSettings;

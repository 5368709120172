import { useMutation, useQueryClient } from '@tanstack/react-query';

import { accountApi } from '../api';
import { UserConnectedDevice } from '@demind-inc/core';

interface UseUpdateUserDevicesParams {
  userId: string;
  devices: UserConnectedDevice[];
}

export const useUpdateUserDevices = () => {
  const queryClient = useQueryClient();

  const updateUserDevicesMutation = useMutation({
    mutationFn: ({ userId, devices }: UseUpdateUserDevicesParams) => {
      return accountApi.updateUserDevices(userId, devices).then(({ data }) => data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.user'] });
    },
  });

  return {
    updateUserDevices: updateUserDevicesMutation.mutateAsync,
    ...updateUserDevicesMutation,
  };
};

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { calendarApi } from '../api';
import { FirestoreCalendar } from '../types';

interface UseUpdateCalendarInfoarams {
  calendarId: string;
  newCalendarInfo: Partial<FirestoreCalendar>;
}

export const useUpdateCalendarInfo = () => {
  const queryClient = useQueryClient();

  const updateCalendarInfoMutation = useMutation({
    mutationFn: ({ calendarId, newCalendarInfo }: UseUpdateCalendarInfoarams) => {
      return calendarApi.updateCalendarInfo(calendarId, newCalendarInfo).then((data) => data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.meta'] });
    },
  });

  return {
    updateCalendarInfo: updateCalendarInfoMutation.mutateAsync,
    ...updateCalendarInfoMutation,
  };
};

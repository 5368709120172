import React from 'react';
import { Menu, MenuItem, CircularProgress, IconButton } from '@mui/material';
import { ALL_TODO_INTEGRATIONS_TYPE, TodoIntegrationType } from '@demind-inc/core';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import './ConnectTodosDropdown.scss';
import { AVAILABLE_TODO_INTEGRATIONS_TYPE } from '../../../data-access';
import { TODO_INTEGRATION_ICONS } from '../constants';
import { Close } from '@mui/icons-material';
import clsx from 'clsx';

interface ConnectTodosDropdownProps {
  anchorEl: HTMLElement | null;
  generatingTodoAuth?: TodoIntegrationType;
  onClickItem: (todoType: TodoIntegrationType) => void;
  onClose: () => void;
  connectedTodos?: Array<{ source: TodoIntegrationType }>;
}

const ConnectTodosDropdown: React.FC<ConnectTodosDropdownProps> = ({
  anchorEl,
  generatingTodoAuth,
  onClickItem,
  onClose,
  connectedTodos,
}) => {
  const isConnected = (todoType: TodoIntegrationType) =>
    connectedTodos?.some(({ source }) => source === todoType);

  const renderMenuItemContent = (todoType: TodoIntegrationType) => (
    <>
      <div className="connect-todos-dropdown__item__text">
        {isConnected(todoType) ? <CheckIcon fontSize="inherit" /> : <AddIcon fontSize="inherit" />}
        {isConnected(todoType) ? 'Connected' : 'Connect'}
      </div>
      {generatingTodoAuth === todoType ? (
        <CircularProgress size={15} />
      ) : (
        <img
          src={TODO_INTEGRATION_ICONS[todoType]}
          alt={todoType}
          className="connect-todos-dropdown__icon"
        />
      )}
    </>
  );

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      className="connect-todos-dropdown"
    >
      <div className="connect-todos-dropdown__header">
        <p>Connect Todos</p>
        <IconButton onClick={onClose} className="connect-todos-dropdown__close-button">
          <Close fontSize="small" className="connect-todos-dropdown__close-button__icon" />
        </IconButton>
      </div>
      <div className="connect-todos-dropdown__content">
        {ALL_TODO_INTEGRATIONS_TYPE.map((todoType) => {
          const isDisabled =
            !AVAILABLE_TODO_INTEGRATIONS_TYPE.includes(todoType) ||
            !!generatingTodoAuth ||
            isConnected(todoType);

          return (
            <MenuItem
              key={todoType}
              onClick={() => onClickItem(todoType)}
              disabled={isDisabled}
              className={clsx('connect-todos-dropdown__item', {
                'connect-todos-dropdown__item--connected': isConnected(todoType),
              })}
            >
              {renderMenuItemContent(todoType)}
            </MenuItem>
          );
        })}
      </div>
    </Menu>
  );
};

export default ConnectTodosDropdown;

import { ReactNode, createContext, useCallback, useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { CircadianPhase, HeatmapDataType, PhaseStartEndSet } from '@demind-inc/core';

import {
  selectedDateAtom,
  useAuthContext,
  useCircadianHeatmap,
  useTodoTasksContext,
} from '../../data-access';
import { PeakDipStartEndSet } from '../types';
import {
  calculatePeakEnergy,
  getCircdaianPhaseBoundaries,
  getPeaksDipsBoundaries,
} from '../helpers';
import { useRecoilValue } from 'recoil';

dayjs.extend(timezone);
dayjs.extend(utc);

interface ICircadianContext {
  circadianRhythms: HeatmapDataType[];
  circadianPhaseBoundaries?: PhaseStartEndSet;
  peaksDipsBoundaries?: PeakDipStartEndSet;
  peakEnergy?: number;
  isSyncingCircadian: boolean;
  findPhaseForTaskTime: (startTime: string, endTime: string) => CircadianPhase | undefined;
}

export const CircadianContext = createContext({} as ICircadianContext);
export const useCircadianContext = () => useContext(CircadianContext);

export const CircadianProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuthContext();
  const selectedDate = useRecoilValue(selectedDateAtom);
  const { visibleTodoListDates } = useTodoTasksContext();

  const { circadianRhythmsByDate, isLoading: isSyncingCircadian } = useCircadianHeatmap({
    userId: user.userId,
    calendarIds: user.calendarIds ?? [],
    metricId: user.metricId!,
    dates: visibleTodoListDates.map((d) => d.format('YYYY-MM-DD')),
    timezone: dayjs.tz.guess(),
  });

  const selectedDateCircadianRhythms = useMemo(() => {
    if (!circadianRhythmsByDate.length) {
      return [];
    }
    const selectedDateCircadianRhythms = circadianRhythmsByDate.find(
      ({ date }) => date === selectedDate.format('YYYY-MM-DD')
    );

    return selectedDateCircadianRhythms?.values ?? [];
  }, [circadianRhythmsByDate, selectedDate]);

  const { circadianPhaseBoundaries, peaksDipsBoundaries } = useMemo(() => {
    if (!selectedDateCircadianRhythms.length) {
      return {};
    }

    const circadianPhaseBoundaries = getCircdaianPhaseBoundaries(
      selectedDateCircadianRhythms
    ) as PhaseStartEndSet;

    const peaksDipsBoundaries = getPeaksDipsBoundaries(
      selectedDateCircadianRhythms
    ) as PeakDipStartEndSet;

    return {
      circadianPhaseBoundaries,
      peaksDipsBoundaries,
    };
  }, [selectedDateCircadianRhythms, selectedDate]);

  const peakEnergy = useMemo(() => {
    if (!selectedDateCircadianRhythms.length) {
      return;
    }

    return calculatePeakEnergy(selectedDateCircadianRhythms);
  }, [selectedDateCircadianRhythms]);

  const findPhaseForTaskTime = useCallback(
    (startTime: string, endTime: string) => {
      if (!circadianPhaseBoundaries) {
        return;
      }
      const targetDateCircadian = circadianRhythmsByDate.find(
        ({ date }) => date === dayjs(startTime).format('YYYY-MM-DD')
      );
      if (!targetDateCircadian) {
        return;
      }
      const targetDateCircadianPhaseBounrdaries = getCircdaianPhaseBoundaries(
        targetDateCircadian.values
      );
      const midpointDuration = dayjs(endTime).diff(dayjs(startTime)) / 2;
      const midpoint = dayjs(startTime).add(midpointDuration, 'millisecond');

      return Object.entries(targetDateCircadianPhaseBounrdaries).find(([_, { start, end }]) => {
        return midpoint.isBefore(dayjs(end)) && midpoint.isAfter(dayjs(start));
      })?.[0] as CircadianPhase;
    },
    [circadianRhythmsByDate]
  );

  return (
    <CircadianContext.Provider
      value={{
        circadianRhythms: selectedDateCircadianRhythms,
        circadianPhaseBoundaries,
        peaksDipsBoundaries,
        peakEnergy,
        isSyncingCircadian,
        findPhaseForTaskTime,
      }}
    >
      {children}
    </CircadianContext.Provider>
  );
};

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { integrationsApi } from '../api';
import { TodoIntegrationType } from '@demind-inc/core';

interface UseInitTodoIntegrationParams {
  userId: string;
  todoType: TodoIntegrationType;
  token: string;
}

export function useInitTodoIntegration() {
  const queryClient = useQueryClient();

  const initTodoIntegrationMutation = useMutation({
    mutationFn: ({ userId, todoType, token }: UseInitTodoIntegrationParams) => {
      return integrationsApi
        .initTodoIntegrations(todoType, token, userId)
        .then(({ data }) => data.newTodoIntegrationId as string);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.projects'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.todoIntegrations'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.user'] });
    },
  });

  return {
    initTodoIntegration: initTodoIntegrationMutation.mutateAsync,
    ...initTodoIntegrationMutation,
  };
}

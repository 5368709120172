import React, { useState } from 'react';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';

import './EnergyPrefSelector.scss';
import { EventCategoryEnergyPreferenceLevel } from '@demind-inc/core';
import { energyPrefLevelText } from './constants';

interface EnergyPrefSelectorProps {
  level: EventCategoryEnergyPreferenceLevel;
  onSelectLevel: (level: EventCategoryEnergyPreferenceLevel) => void;
}

const EnergyPrefSelector: React.FC<EnergyPrefSelectorProps> = ({ level, onSelectLevel }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSelectLevel(event.target.value as EventCategoryEnergyPreferenceLevel);
  };

  return (
    <RadioGroup
      aria-label="energy-preference"
      name="energy-preference"
      value={level}
      onChange={handleChange}
      className="energy-pref-selector"
    >
      {Object.entries(energyPrefLevelText).map(([key, text]) => {
        return <FormControlLabel value={key} control={<Radio size={'medium'} />} label={text} />;
      })}
    </RadioGroup>
  );
};

export default EnergyPrefSelector;

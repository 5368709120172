import { CalendarEvent, TaskItem } from '@demind-inc/core';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(tz);

export const convertTaskToEvent = (task: TaskItem): CalendarEvent => {
  return {
    eventId: 'creating',
    summary: task.name,
    description: task.desc,
    color: task.labels?.[0]?.color,
    start: {
      date: '',
      timeZone: dayjs.tz.guess(),
    },
    end: {
      date: '',
      timeZone: dayjs.tz.guess(),
    },
    taskFrom: {
      taskId: task.taskId,
      boardId: task.boardId!,
      from: task.appFrom,
    },
    updatedDate: dayjs().toISOString(),
    createdDate: dayjs().toISOString(),
  };
};

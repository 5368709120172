import { FC, ReactNode } from 'react';
import { HeatmapDataType } from '@demind-inc/core';

import './CustomTimeGutter.scss';
import { Heatmap, ZoneLabelTimeline } from '../../Circadian';
import { HOUR_BLOCK_HEIGHT } from '../constants';
import { PeakDipStartEndSet } from '../../../../data-access';
import { Skeleton } from '@mui/material';

interface CustomTimeGutterProps {
  heatmapData: HeatmapDataType[];
  peaksDipsBoundaries?: PeakDipStartEndSet;
  isDefaultHeatmap?: boolean;
  children: ReactNode;
}

const calendarHeight = 24 * HOUR_BLOCK_HEIGHT;
const heatmapWidth = '14';

const CustomTimeGutter: FC<CustomTimeGutterProps> = ({
  heatmapData,
  peaksDipsBoundaries,
  isDefaultHeatmap = false,
  children,
}) => {
  return (
    <div className="custom-time-gutter">
      {peaksDipsBoundaries && (
        <ZoneLabelTimeline
          peaksDipsBoundaries={peaksDipsBoundaries}
          className="custom-time-gutter__zone-labels"
        />
      )}
      {children}
      {!heatmapData.length ? (
        <Skeleton
          variant="rectangular"
          width={`${heatmapWidth}px`}
          height={`${calendarHeight}px`}
        />
      ) : (
        <Heatmap
          data={heatmapData}
          width={heatmapWidth}
          height={`${calendarHeight}`}
          opacity={isDefaultHeatmap ? 0.1 : 1}
        />
      )}
    </div>
  );
};

export default CustomTimeGutter;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventCategoryEnergyPreference } from '@demind-inc/core';

import { energyApi } from '../api';

interface UseUpdateEventCategoryEnergyPrefParams {
  preferenceId: string;
  preferences: EventCategoryEnergyPreference[];
}

export const useUpdateEventCategoryEnergyPref = () => {
  const queryClient = useQueryClient();

  const updateEnergyPrefMutation = useMutation({
    mutationFn: ({ preferenceId, preferences }: UseUpdateEventCategoryEnergyPrefParams) => {
      return energyApi
        .updateEventCategoryPreference(preferenceId, preferences)
        .then((data) => data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.preference.energy'] });
    },
  });

  return {
    updateEventCategoryEnergyPref: updateEnergyPrefMutation.mutateAsync,
    ...updateEnergyPrefMutation,
  };
};

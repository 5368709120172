import { useMutation, useQueryClient } from '@tanstack/react-query';

import { tasksApi } from '../api';
import { FirestoreTaskItem } from '../types';

interface UseUpdateTodoTaskParams {
  boardId: string;
  taskId: string;
  userId: string;
  newTaskInfo: Partial<FirestoreTaskItem>;
}

export const useUpdateTodoTask = () => {
  const queryClient = useQueryClient();

  const updateTaskMutation = useMutation({
    mutationFn: ({ boardId, taskId, userId, newTaskInfo }: UseUpdateTodoTaskParams) => {
      return tasksApi.updateTodoTask(taskId, boardId, userId, newTaskInfo).then(({ data }) => data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.tasks'] });
    },
  });

  return {
    updateTodoTask: updateTaskMutation.mutateAsync,
    isUpdating: updateTaskMutation.isPending,
    ...updateTaskMutation,
  };
};

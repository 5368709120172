import { FC, ReactNode } from 'react';

import './ZoneLabelExplaTooltipContent.scss';

interface ZoneLabelExplaTooltipContentProps {
  title: string;
  time: string;
  color: string;
  explanation: string;
}

const ZoneLabelExplaTooltipContent: FC<ZoneLabelExplaTooltipContentProps> = ({
  title,
  time,
  color,
  explanation,
}) => {
  return (
    <div className="zone-label-tooltip-content">
      <div className="zone-label-tooltip-content__title-group">
        <div className="zone-label-tooltip-content__title">{title}</div>
        <span className="zone-label-tooltip-content__time" style={{ color, borderColor: color }}>
          {time}
        </span>
      </div>
      <div className="zone-label-tooltip-content__expla">{explanation}</div>
    </div>
  );
};

export default ZoneLabelExplaTooltipContent;

import { useCalendarContext } from '../../../../data-access';
import { first } from 'lodash';

import './CalendarsSettings.scss';
import { getCssVariable } from '../../../../utils';

const primaryColor = getCssVariable('--color-primary');

const CalendarsSettings = () => {
  const { calendarsMeta } = useCalendarContext();

  return (
    <div className="calendars-settings">
      <p className="calendars-settings__title">Calendar</p>
      <div className="calendars-settings__grid">
        {calendarsMeta?.map((calendar) => (
          <div key={calendar.calendarId} className="calendars-settings__calendar-item">
            <div
              className="calendars-settings__calendar-icon"
              style={{ background: calendar.color || primaryColor }}
            >
              {first(calendar.name)}
            </div>
            {calendar.rootEmail}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarsSettings;

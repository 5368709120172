import { useMutation } from '@tanstack/react-query';
import { TodoIntegrationType } from '@demind-inc/core';

import { integrationsApi } from '../api';

interface UseGenerateTodoAuthURLParams {
  todoType: TodoIntegrationType;
  callback: string;
}

export const useGenerateTodoAuthURL = () => {
  const generateAuthUrlMutation = useMutation({
    mutationFn: ({ todoType, callback }: UseGenerateTodoAuthURLParams) => {
      return integrationsApi
        .generateTodoAuthUrl(todoType, callback)
        .then(({ data }) => data as string);
    },
  });

  return {
    generateTodoAuthUrl: generateAuthUrlMutation.mutateAsync,
    ...generateAuthUrlMutation,
  };
};

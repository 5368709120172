import dayjs from 'dayjs';

import { getStartEndTimeOfMultDays, useAuthContext, useSyncSleepDetails } from '../data-access';

interface SyncSleepDataParams {
  terraUserIds: string[];
  startDate: string;
  endDate: string;
}

export const useSyncSleepData = () => {
  const { syncSleepDetail } = useSyncSleepDetails();
  const { user } = useAuthContext();

  const syncSleepData = async ({ terraUserIds, startDate, endDate }: SyncSleepDataParams) => {
    if (!user.metricId || !user.userId || !terraUserIds.length) {
      return;
    }

    const { startTime, endTime } = getStartEndTimeOfMultDays(dayjs(startDate), dayjs(endDate));

    if (terraUserIds.length) {
      await syncSleepDetail({
        userId: user.userId,
        metricId: user.metricId,
        terraUserIds,
        startDate: startTime.toISOString(),
        endDate: endTime.toISOString(),
      });
    }
  };

  return { syncSleepData };
};

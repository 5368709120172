import { FC } from 'react';
import clsx from 'clsx';
import { AccessTime } from '@mui/icons-material';
import { TimePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

import './EventTimeSelector.scss';

interface EventTimeSelectorProps {
  startDateTime: Dayjs;
  endDateTime: Dayjs;
  onStartTimeChange: (date: Dayjs) => void;
  onEndTimeChange: (date: Dayjs) => void;
  className?: string;
}

const EventTimeSelector: FC<EventTimeSelectorProps> = ({
  startDateTime,
  endDateTime,
  onStartTimeChange,
  onEndTimeChange,
  className,
}) => {
  return (
    <div className={clsx('event-time-selector', className)}>
      <AccessTime className="event-time-selector__icon" fontSize="small" />
      <div className="event-time-selector__container">
        <div className="event-time-selector__date">{startDateTime.format('dddd, MMMM D')}</div>
        <div className="event-time-selector__time">
          <TimePicker
            value={startDateTime}
            ampm={false}
            onChange={(val) => val && onStartTimeChange(val)}
          />
          -
          <TimePicker
            value={endDateTime}
            ampm={false}
            onChange={(val) => val && onEndTimeChange(val)}
          />
        </div>
      </div>
    </div>
  );
};

export default EventTimeSelector;

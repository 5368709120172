import { CalendarEvent, DateTimeSet, TaskItem } from '@demind-inc/core';
import dayjs from 'dayjs';

import { FirestoreTaskItem } from '../data-access';
import { start } from 'repl';

export const getParamToUpdateTaskTimeFromCalendar = (
  event: Partial<CalendarEvent>
): Partial<FirestoreTaskItem> => {
  return {
    ...(event.start
      ? {
          startDateTime: {
            datetime: dayjs(event.start.date).toISOString(),
            date: '',
            timezone: dayjs.tz.guess(),
          },
        }
      : {}),
    ...(event.end
      ? {
          dueDateTime: {
            datetime: dayjs(event.end.date).toISOString(),
            date: '',
            timezone: dayjs.tz.guess(),
          },
        }
      : {}),
    ...(event.start && event.end
      ? {
          duration: {
            amount: dayjs(event.end.date).diff(dayjs(event.start.date), 'minute'),
            unit: 'minute',
          },
        }
      : {}),
    addedToCalendar: true,
  };
};

/**
 * Formats the due date for updating a task.
 *
 * @param label - Specifies whether the date is a 'start' or 'due' date.
 * @param dateSet - An optional object containing date and time information.
 * @returns A partial `TaskItem` object with the formatted date and time.
 */
export const getFormattedDueDateForUpdateTask = (
  label: 'start' | 'due',
  dateSet?: DateTimeSet
): Partial<TaskItem> => {
  const hasValidDate = !!dateSet && (!!dateSet.datetime || !!dateSet.date);
  if (!hasValidDate) {
    return {};
  }

  const labelKey = label === 'start' ? 'startDateTime' : 'dueDateTime';
  const hasDataTime = !!dateSet.datetime;

  return {
    [labelKey]: {
      ...(dateSet.datetime ? { datetime: dateSet.datetime } : {}),
      ...(dateSet.date && !hasDataTime ? { date: dateSet.date } : {}), // Send date only when datetime is not set
      timezone: dateSet.timezone || dayjs.tz.guess(),
    },
  };
};

/**
 * Calculates the duration between two DateTimeSet objects and returns it in minutes.
 *
 * @param startDateTime - The starting DateTimeSet object.
 * @param dueDateTime - The ending DateTimeSet object.
 * @returns A Partial<TaskItem> object containing the duration in minutes, or an empty object if either DateTimeSet is invalid.
 */
export const getFormattedDuration = (
  startDateTime?: DateTimeSet,
  dueDateTime?: DateTimeSet
): Partial<TaskItem> => {
  if (!startDateTime || !dueDateTime || !dueDateTime.datetime || !startDateTime.datetime) {
    return {};
  }

  const durationMin = Math.abs(
    dayjs(dueDateTime.datetime).diff(dayjs(startDateTime.datetime), 'minute')
  );

  return {
    duration: {
      amount: durationMin,
      unit: 'minute',
    },
  };
};

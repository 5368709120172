import * as Sentry from '@sentry/react';

import { isDevelopment } from '../environment';

export const initSentry = () => {
  if (isDevelopment) {
    return;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SETNRY_DNS,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV,
    ignoreErrors: [/AxiosError/],
  });
};

import { FC, useEffect, useMemo, useState } from 'react';
import { Button, Snackbar } from '@mui/material';
import { upperFirst } from 'lodash';

import { AVAILABLE_TODO_INTEGRATIONS_TYPE } from '../../../data-access';
import { TodoIntegrationType } from '@demind-inc/core';

interface TaskErrorSnackBarProps {
  error?: string;
  onAction: (type: TodoIntegrationType) => void;
}

const TaskErrorSnackBar: FC<TaskErrorSnackBarProps> = ({ error = '', onAction }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(!!error);
    }
  }, [error]);

  // If unauthenticated error, show the actions
  // TODO: This should be handed in react query
  const showActions = useMemo(() => error.includes('401'), [error]);

  return (
    <Snackbar
      open={visible}
      onClose={(_, reason) => reason === 'timeout' && setVisible(false)}
      message={`Error updating task.  ${showActions ? 'Re-connect your todo-list : ' : ''}`}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={
        showActions && (
          <>
            {AVAILABLE_TODO_INTEGRATIONS_TYPE.map((type) => (
              <Button key={type} color="secondary" size="small" onClick={() => onAction(type)}>
                {upperFirst(type)}
              </Button>
            ))}
          </>
        )
      }
    />
  );
};

export default TaskErrorSnackBar;

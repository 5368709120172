import { useMutation, useQueryClient } from '@tanstack/react-query';

import { metricsApi } from '../api';

interface UseSyncSleepDetailsParams {
  userId: string;
  metricId: string;
  terraUserIds: string[];
  startDate: string;
  endDate: string;
}

export function useSyncSleepDetails() {
  const queryClient = useQueryClient();

  const syncSleepDetailMutation = useMutation({
    mutationFn: ({
      userId,
      metricId,
      terraUserIds,
      startDate,
      endDate,
    }: UseSyncSleepDetailsParams) => {
      return metricsApi.syncSleepDetails(metricId, userId, terraUserIds, startDate, endDate);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.sleep.details'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.circadian'] });
    },
  });

  return {
    syncSleepDetail: syncSleepDetailMutation.mutateAsync,
    ...syncSleepDetailMutation,
  };
}

import { ReactNode, createContext, useContext } from 'react';
import { SleepDetailsByDate } from '@demind-inc/core';
import { useRecoilValue } from 'recoil';

import { useAuthContext } from './AuthProvider';
import { useSleepDetails } from '../queries';
import { selectedDateAtom } from '../recoil';

interface IMetricsContext {
  sleepDetails: SleepDetailsByDate[];

  isLoading: boolean;
}

export const MetricsContext = createContext({} as IMetricsContext);
export const useMetricsContext = () => useContext(MetricsContext);

export const MetricsProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuthContext();
  const selectedDate = useRecoilValue(selectedDateAtom);

  const { sleepDetails, isLoading: isFetchingSleep } = useSleepDetails({
    userId: user.userId,
    metricId: user.metricId!,
    dates: [selectedDate.format('YYYY-MM-DD')],
  });

  return (
    <MetricsContext.Provider
      value={{
        sleepDetails,
        isLoading: isFetchingSleep,
      }}
    >
      {children}
    </MetricsContext.Provider>
  );
};

import { useQuery } from '@tanstack/react-query';
import { TodoIntegration } from '@demind-inc/core';

import { integrationsApi } from '../api';

interface UseTodoIntegrationsParams {
  userId: string;
}

interface UseTodoIntegrationsQueryParams {
  queryKey: ['lifestack.todoIntegrations', { userId: string }];
}

export const useTodoIntegrations = ({ userId }: UseTodoIntegrationsParams) => {
  const todoIntegrationsQuery = useQuery({
    queryKey: ['lifestack.todoIntegrations', { userId }],
    queryFn: ({ queryKey }: UseTodoIntegrationsQueryParams) => {
      const [, { userId }] = queryKey;

      return integrationsApi
        .getTodoIntegrations(userId)
        .then(({ data }) => data as TodoIntegration[]);
    },
    refetchOnWindowFocus: false,
    enabled: !!userId,
  });

  return {
    todoIntegrations: todoIntegrationsQuery.data ?? [],
    ...todoIntegrationsQuery,
  };
};

import { FC } from 'react';
import { Button } from '@mui/material';
import clsx from 'clsx';

import './ConnectDevicesPage.scss';
import { useAuthContext, useTerraWidget } from '../../../data-access';
import { trackEventGA4 } from '../../../utils';
import { OnboardingContainer } from '../../../components';
import { Path } from '../../../routing/path';
import ConnectDeviceAsset from '../../../assets/onboarding/connectDevice.png';

const ConnectDevicesPage: FC = () => {
  const { terraWidget, isPending } = useTerraWidget();
  const { user } = useAuthContext();

  const handleClick = () => {
    terraWidget({ metricId: user.metricId, redirectTo: Path.ONBOARDING.ENERGY_PREFERENCE });
    trackEventGA4('Button_click', 'click_connect_wearables');
  };

  return (
    <div className="connect-devices-page">
      <OnboardingContainer
        leftChildren={
          <img
            src={ConnectDeviceAsset}
            alt="lifestack connect device"
            className="connect-devices-page__asset"
          />
        }
        rightChildren={
          <div className="connect-devices-page__right-section">
            <div className="connect-devices-page__content">
              <div className="connect-devices-page__content__title">Connect your wearables</div>
              <div className="connect-devices-page__content__description">
                You can also connect Apple Health from the mobile settings page.
              </div>
            </div>
            <div className="connect-devices-page__btn-group">
              <Button
                variant="contained"
                className="connect-devices-page__btn"
                onClick={handleClick}
                disabled={isPending}
              >
                Connect Wearables
              </Button>
              <Button
                variant="contained"
                className={clsx('connect-devices-page__btn', 'connect-devices-page__btn--disabled')}
                disabled
              >
                Connect Apple Health
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ConnectDevicesPage;

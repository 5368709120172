import { FC, MouseEvent } from 'react';

import './EmptyKanbanCard.scss';

interface EmptyKanbanCardProps {
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

const EmptyKanbanCard: FC<EmptyKanbanCardProps> = ({ onClick = () => void 0 }) => {
  return (
    <div className="empty-kanban-card" onClick={onClick}>
      <div className="empty-kanban-card__title">Connect your todo</div>
    </div>
  );
};

export default EmptyKanbanCard;

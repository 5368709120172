/* tslint:disable */
/* eslint-disable */
/**
 * Lifestack API (Staging)
 * This is the API documentation for Lifestack.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccountAuthGenerateJWTPostRes
 */
export interface AccountAuthGenerateJWTPostRes {
    /**
     * refresh token
     * @type {string}
     * @memberof AccountAuthGenerateJWTPostRes
     */
    'refreshToken': string;
    /**
     * access token
     * @type {string}
     * @memberof AccountAuthGenerateJWTPostRes
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface AccountAuthRefreshPostRes
 */
export interface AccountAuthRefreshPostRes {
    /**
     * access token
     * @type {string}
     * @memberof AccountAuthRefreshPostRes
     */
    'access': string;
}
/**
 * 
 * @export
 * @interface AccountAuthSignInPostReq
 */
export interface AccountAuthSignInPostReq {
    /**
     * 
     * @type {string}
     * @memberof AccountAuthSignInPostReq
     */
    'serverAuthCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAuthSignInPostReq
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAuthSignInPostReq
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAuthSignInPostReq
     */
    'tokenExpiryDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountAuthSignInPostReq
     */
    'isNewTrialFlow'?: boolean;
    /**
     * FirestoreUser
     * @type {object}
     * @memberof AccountAuthSignInPostReq
     */
    'newUserInfo'?: object;
}
/**
 * 
 * @export
 * @interface AccountAuthSignInPostRes
 */
export interface AccountAuthSignInPostRes {
    /**
     * User
     * @type {object}
     * @memberof AccountAuthSignInPostRes
     */
    'user': object;
    /**
     * refresh token
     * @type {string}
     * @memberof AccountAuthSignInPostRes
     */
    'refreshToken'?: string;
    /**
     * access token
     * @type {string}
     * @memberof AccountAuthSignInPostRes
     */
    'accessToken'?: string;
}
/**
 * 
 * @export
 * @interface AccountSubscriptionGetRes
 */
export interface AccountSubscriptionGetRes {
    /**
     * Subscriptions
     * @type {Array<object>}
     * @memberof AccountSubscriptionGetRes
     */
    'subscriptions': Array<object>;
    /**
     * Active subscription
     * @type {object}
     * @memberof AccountSubscriptionGetRes
     */
    'activeSubscription'?: object;
    /**
     * Has access
     * @type {boolean}
     * @memberof AccountSubscriptionGetRes
     */
    'hasAccess': boolean;
}
/**
 * 
 * @export
 * @interface CalendarEventPostReq
 */
export interface CalendarEventPostReq {
    /**
     * 
     * @type {string}
     * @memberof CalendarEventPostReq
     */
    'taskId'?: string;
    /**
     * CalendarEventMetrics
     * @type {object}
     * @memberof CalendarEventPostReq
     */
    'metrics'?: object;
    /**
     * Partial<CalendarEvent>
     * @type {object}
     * @memberof CalendarEventPostReq
     */
    'newEventOption': object;
}
/**
 * 
 * @export
 * @interface CalendarEventsSyncRes
 */
export interface CalendarEventsSyncRes {
    /**
     * 
     * @type {string}
     * @memberof CalendarEventsSyncRes
     */
    'calendarId'?: string;
    /**
     * CalendarEvent[]
     * @type {Array<object>}
     * @memberof CalendarEventsSyncRes
     */
    'events'?: Array<object>;
}
/**
 * 
 * @export
 * @interface CalendarListSyncGCalReq
 */
export interface CalendarListSyncGCalReq {
    /**
     * Google refresh token
     * @type {string}
     * @memberof CalendarListSyncGCalReq
     */
    'refreshToken'?: string;
    /**
     * Google access token
     * @type {string}
     * @memberof CalendarListSyncGCalReq
     */
    'accessToken'?: string;
    /**
     * Google access token expiry date
     * @type {string}
     * @memberof CalendarListSyncGCalReq
     */
    'expiryDate'?: string;
}
/**
 * 
 * @export
 * @interface EnergyCircadianGetRes
 */
export interface EnergyCircadianGetRes {
    /**
     * Circadian rhythm
     * @type {Array<object>}
     * @memberof EnergyCircadianGetRes
     */
    'circadian': Array<object>;
    /**
     * Fluctuations
     * @type {object}
     * @memberof EnergyCircadianGetRes
     */
    'fluctuations': object;
}
/**
 * 
 * @export
 * @interface ErrorRes
 */
export interface ErrorRes {
    /**
     * error: {message}
     * @type {string}
     * @memberof ErrorRes
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface GetTerraUserStatusRes
 */
export interface GetTerraUserStatusRes {
    /**
     * 
     * @type {string}
     * @memberof GetTerraUserStatusRes
     */
    'terraUserId': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetTerraUserStatusRes
     */
    'valid': boolean;
    /**
     * -- TerraUserResponse
     * @type {object}
     * @memberof GetTerraUserStatusRes
     */
    'user': object;
}
/**
 * 
 * @export
 * @interface InitTodoPutRes
 */
export interface InitTodoPutRes {
    /**
     * 
     * @type {Array<string>}
     * @memberof InitTodoPutRes
     */
    'newTodoTasksBoardIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InitTodoPutRes
     */
    'newTodoIntegrationId': string;
}
/**
 * 
 * @export
 * @interface MetricsWebhookTerraPutReq
 */
export interface MetricsWebhookTerraPutReq {
    /**
     * TerraUser
     * @type {object}
     * @memberof MetricsWebhookTerraPutReq
     */
    'user': object;
    /**
     * 
     * @type {string}
     * @memberof MetricsWebhookTerraPutReq
     */
    'type': string;
    /**
     * 
     * @type {Array<object>}
     * @memberof MetricsWebhookTerraPutReq
     */
    'data': Array<object>;
}
/**
 * 
 * @export
 * @interface SchedulerEnergyBoostPutReq
 */
export interface SchedulerEnergyBoostPutReq {
    /**
     * CalendarEvent[]
     * @type {Array<object>}
     * @memberof SchedulerEnergyBoostPutReq
     */
    'eventsOnDay': Array<object>;
    /**
     * PhaseStartEndSet
     * @type {object}
     * @memberof SchedulerEnergyBoostPutReq
     */
    'circadianPhaseBoundaries': object;
    /**
     * 
     * @type {string}
     * @memberof SchedulerEnergyBoostPutReq
     */
    'currentTime': string;
    /**
     * RecommendedTaskOrigin
     * @type {string}
     * @memberof SchedulerEnergyBoostPutReq
     */
    'origin'?: string;
}
/**
 * 
 * @export
 * @interface SuccessRes
 */
export interface SuccessRes {
    /**
     * success: true
     * @type {boolean}
     * @memberof SuccessRes
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface TaskIdRes
 */
export interface TaskIdRes {
    /**
     * taskId: abcdef
     * @type {string}
     * @memberof TaskIdRes
     */
    'taskId': string;
}
/**
 * 
 * @export
 * @interface TaskProjectIdRes
 */
export interface TaskProjectIdRes {
    /**
     * taskId: abcdef
     * @type {string}
     * @memberof TaskProjectIdRes
     */
    'taskProjectId': string;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel a subscription via Stripe
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelStripeSubscription: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('cancelStripeSubscription', 'userId', userId)
            const localVarPath = `/api/v1/account/subscriptions/stripe/unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate a new JWT
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateJwt: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('generateJwt', 'userId', userId)
            const localVarPath = `/api/v1/account/auth/generateJwt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available subscriptions
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSubscriptions: async (provider?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/account/subscriptions/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get subscriptions
         * @param {string} userId 
         * @param {boolean} [isNewTrialFlow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptions: async (userId: string, isNewTrialFlow?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSubscriptions', 'userId', userId)
            const localVarPath = `/api/v1/account/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (isNewTrialFlow !== undefined) {
                localVarQueryParameter['isNewTrialFlow'] = isNewTrialFlow;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user info
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/api/v1/account/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh an access token
         * @param {string} refresh 
         * @param {boolean} [withJwt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (refresh: string, withJwt?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refresh' is not null or undefined
            assertParamExists('refreshToken', 'refresh', refresh)
            const localVarPath = `/api/v1/account/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (refresh !== undefined) {
                localVarQueryParameter['refresh'] = refresh;
            }

            if (withJwt !== undefined) {
                localVarQueryParameter['withJwt'] = withJwt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a user device
         * @param {string} userId 
         * @param {string} deviceTerraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserDevice: async (userId: string, deviceTerraId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeUserDevice', 'userId', userId)
            // verify required parameter 'deviceTerraId' is not null or undefined
            assertParamExists('removeUserDevice', 'deviceTerraId', deviceTerraId)
            const localVarPath = `/api/v1/account/user/{userId}/device`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceTerraId !== undefined) {
                localVarQueryParameter['deviceTerraId'] = deviceTerraId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request a subscription via Stripe
         * @param {string} userId 
         * @param {string} priceId 
         * @param {string} email 
         * @param {string} customerToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestStripeSubscription: async (userId: string, priceId: string, email: string, customerToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('requestStripeSubscription', 'userId', userId)
            // verify required parameter 'priceId' is not null or undefined
            assertParamExists('requestStripeSubscription', 'priceId', priceId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('requestStripeSubscription', 'email', email)
            // verify required parameter 'customerToken' is not null or undefined
            assertParamExists('requestStripeSubscription', 'customerToken', customerToken)
            const localVarPath = `/api/v1/account/subscriptions/stripe/subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (priceId !== undefined) {
                localVarQueryParameter['priceId'] = priceId;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (customerToken !== undefined) {
                localVarQueryParameter['customerToken'] = customerToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign in or sign up a user
         * @param {string} userId 
         * @param {boolean} [withJwt] 
         * @param {AccountAuthSignInPostReq} [accountAuthSignInPostReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signIn: async (userId: string, withJwt?: boolean, accountAuthSignInPostReq?: AccountAuthSignInPostReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('signIn', 'userId', userId)
            const localVarPath = `/api/v1/account/auth/signIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (withJwt !== undefined) {
                localVarQueryParameter['withJwt'] = withJwt;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountAuthSignInPostReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an onboarding status
         * @param {string} userId 
         * @param {object} body Partial&lt;OnboardingStatus&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOnboardingStatus: async (userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateOnboardingStatus', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateOnboardingStatus', 'body', body)
            const localVarPath = `/api/v1/account/user/{userId}/onboarding`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a subscription
         * @param {string} userId 
         * @param {object} body Partial&lt;Subscription&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscription: async (userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateSubscription', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateSubscription', 'body', body)
            const localVarPath = `/api/v1/account/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user info
         * @param {string} userId 
         * @param {object} body Parital&lt;FirestoreUser&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateUser', 'body', body)
            const localVarPath = `/api/v1/account/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user devices
         * @param {string} userId 
         * @param {Array<object>} requestBody Array&lt;UserConnectedDevice&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserDevices: async (userId: string, requestBody: Array<object>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserDevices', 'userId', userId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateUserDevices', 'requestBody', requestBody)
            const localVarPath = `/api/v1/account/user/{userId}/devices`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user token
         * @param {string} userId 
         * @param {object} body Partial&lt;FirestoreToken&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserToken: async (userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserToken', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateUserToken', 'body', body)
            const localVarPath = `/api/v1/account/user/{userId}/token`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Watch a subscription status -- Connect to RevenueCat
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchSubscriptionStatus: async (body?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/account/subscriptions/watchStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel a subscription via Stripe
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelStripeSubscription(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelStripeSubscription(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.cancelStripeSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Generate a new JWT
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateJwt(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountAuthGenerateJWTPostRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateJwt(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.generateJwt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get available subscriptions
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableSubscriptions(provider?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableSubscriptions(provider, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.getAvailableSubscriptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get subscriptions
         * @param {string} userId 
         * @param {boolean} [isNewTrialFlow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptions(userId: string, isNewTrialFlow?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountSubscriptionGetRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptions(userId, isNewTrialFlow, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.getSubscriptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a user info
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.getUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh an access token
         * @param {string} refresh 
         * @param {boolean} [withJwt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(refresh: string, withJwt?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountAuthRefreshPostRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(refresh, withJwt, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.refreshToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove a user device
         * @param {string} userId 
         * @param {string} deviceTerraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserDevice(userId: string, deviceTerraId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserDevice(userId, deviceTerraId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.removeUserDevice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Request a subscription via Stripe
         * @param {string} userId 
         * @param {string} priceId 
         * @param {string} email 
         * @param {string} customerToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestStripeSubscription(userId: string, priceId: string, email: string, customerToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestStripeSubscription(userId, priceId, email, customerToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.requestStripeSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sign in or sign up a user
         * @param {string} userId 
         * @param {boolean} [withJwt] 
         * @param {AccountAuthSignInPostReq} [accountAuthSignInPostReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signIn(userId: string, withJwt?: boolean, accountAuthSignInPostReq?: AccountAuthSignInPostReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountAuthSignInPostRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signIn(userId, withJwt, accountAuthSignInPostReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.signIn']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update an onboarding status
         * @param {string} userId 
         * @param {object} body Partial&lt;OnboardingStatus&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOnboardingStatus(userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOnboardingStatus(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.updateOnboardingStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a subscription
         * @param {string} userId 
         * @param {object} body Partial&lt;Subscription&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscription(userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscription(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.updateSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a user info
         * @param {string} userId 
         * @param {object} body Parital&lt;FirestoreUser&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a user devices
         * @param {string} userId 
         * @param {Array<object>} requestBody Array&lt;UserConnectedDevice&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserDevices(userId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserDevices(userId, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.updateUserDevices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a user token
         * @param {string} userId 
         * @param {object} body Partial&lt;FirestoreToken&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserToken(userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserToken(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.updateUserToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Watch a subscription status -- Connect to RevenueCat
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async watchSubscriptionStatus(body?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.watchSubscriptionStatus(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.watchSubscriptionStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel a subscription via Stripe
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelStripeSubscription(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.cancelStripeSubscription(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate a new JWT
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateJwt(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<AccountAuthGenerateJWTPostRes> {
            return localVarFp.generateJwt(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available subscriptions
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSubscriptions(provider?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getAvailableSubscriptions(provider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get subscriptions
         * @param {string} userId 
         * @param {boolean} [isNewTrialFlow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptions(userId: string, isNewTrialFlow?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<AccountSubscriptionGetRes> {
            return localVarFp.getSubscriptions(userId, isNewTrialFlow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a user info
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh an access token
         * @param {string} refresh 
         * @param {boolean} [withJwt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(refresh: string, withJwt?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<AccountAuthRefreshPostRes> {
            return localVarFp.refreshToken(refresh, withJwt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a user device
         * @param {string} userId 
         * @param {string} deviceTerraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserDevice(userId: string, deviceTerraId: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.removeUserDevice(userId, deviceTerraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request a subscription via Stripe
         * @param {string} userId 
         * @param {string} priceId 
         * @param {string} email 
         * @param {string} customerToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestStripeSubscription(userId: string, priceId: string, email: string, customerToken: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.requestStripeSubscription(userId, priceId, email, customerToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign in or sign up a user
         * @param {string} userId 
         * @param {boolean} [withJwt] 
         * @param {AccountAuthSignInPostReq} [accountAuthSignInPostReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signIn(userId: string, withJwt?: boolean, accountAuthSignInPostReq?: AccountAuthSignInPostReq, options?: RawAxiosRequestConfig): AxiosPromise<AccountAuthSignInPostRes> {
            return localVarFp.signIn(userId, withJwt, accountAuthSignInPostReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an onboarding status
         * @param {string} userId 
         * @param {object} body Partial&lt;OnboardingStatus&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOnboardingStatus(userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateOnboardingStatus(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a subscription
         * @param {string} userId 
         * @param {object} body Partial&lt;Subscription&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscription(userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateSubscription(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user info
         * @param {string} userId 
         * @param {object} body Parital&lt;FirestoreUser&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateUser(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user devices
         * @param {string} userId 
         * @param {Array<object>} requestBody Array&lt;UserConnectedDevice&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserDevices(userId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateUserDevices(userId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user token
         * @param {string} userId 
         * @param {object} body Partial&lt;FirestoreToken&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserToken(userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateUserToken(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Watch a subscription status -- Connect to RevenueCat
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchSubscriptionStatus(body?: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.watchSubscriptionStatus(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Cancel a subscription via Stripe
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public cancelStripeSubscription(userId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).cancelStripeSubscription(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate a new JWT
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public generateJwt(userId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).generateJwt(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available subscriptions
     * @param {string} [provider] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAvailableSubscriptions(provider?: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAvailableSubscriptions(provider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get subscriptions
     * @param {string} userId 
     * @param {boolean} [isNewTrialFlow] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getSubscriptions(userId: string, isNewTrialFlow?: boolean, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).getSubscriptions(userId, isNewTrialFlow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a user info
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getUser(userId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh an access token
     * @param {string} refresh 
     * @param {boolean} [withJwt] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public refreshToken(refresh: string, withJwt?: boolean, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).refreshToken(refresh, withJwt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a user device
     * @param {string} userId 
     * @param {string} deviceTerraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public removeUserDevice(userId: string, deviceTerraId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).removeUserDevice(userId, deviceTerraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request a subscription via Stripe
     * @param {string} userId 
     * @param {string} priceId 
     * @param {string} email 
     * @param {string} customerToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public requestStripeSubscription(userId: string, priceId: string, email: string, customerToken: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).requestStripeSubscription(userId, priceId, email, customerToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign in or sign up a user
     * @param {string} userId 
     * @param {boolean} [withJwt] 
     * @param {AccountAuthSignInPostReq} [accountAuthSignInPostReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public signIn(userId: string, withJwt?: boolean, accountAuthSignInPostReq?: AccountAuthSignInPostReq, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).signIn(userId, withJwt, accountAuthSignInPostReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an onboarding status
     * @param {string} userId 
     * @param {object} body Partial&lt;OnboardingStatus&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateOnboardingStatus(userId: string, body: object, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateOnboardingStatus(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a subscription
     * @param {string} userId 
     * @param {object} body Partial&lt;Subscription&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateSubscription(userId: string, body: object, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateSubscription(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user info
     * @param {string} userId 
     * @param {object} body Parital&lt;FirestoreUser&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateUser(userId: string, body: object, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateUser(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user devices
     * @param {string} userId 
     * @param {Array<object>} requestBody Array&lt;UserConnectedDevice&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateUserDevices(userId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateUserDevices(userId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user token
     * @param {string} userId 
     * @param {object} body Partial&lt;FirestoreToken&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateUserToken(userId: string, body: object, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateUserToken(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Watch a subscription status -- Connect to RevenueCat
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public watchSubscriptionStatus(body?: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).watchSubscriptionStatus(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CalendarApi - axios parameter creator
 * @export
 */
export const CalendarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a calendar event
         * @param {string} calendarId 
         * @param {string} userId 
         * @param {CalendarEventPostReq} [calendarEventPostReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendarEvent: async (calendarId: string, userId: string, calendarEventPostReq?: CalendarEventPostReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarId' is not null or undefined
            assertParamExists('createCalendarEvent', 'calendarId', calendarId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createCalendarEvent', 'userId', userId)
            const localVarPath = `/api/v1/calendar/events/{calendarId}`
                .replace(`{${"calendarId"}}`, encodeURIComponent(String(calendarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarEventPostReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a calendar event
         * @param {string} userId 
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarEvent: async (userId: string, calendarId: string, eventId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteCalendarEvent', 'userId', userId)
            // verify required parameter 'calendarId' is not null or undefined
            assertParamExists('deleteCalendarEvent', 'calendarId', calendarId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('deleteCalendarEvent', 'eventId', eventId)
            const localVarPath = `/api/v1/calendar/events/{calendarId}/{eventId}`
                .replace(`{${"calendarId"}}`, encodeURIComponent(String(calendarId)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all-day calendar events
         * @param {string} userId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDayCalendarEvents: async (userId: string, date: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllDayCalendarEvents', 'userId', userId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getAllDayCalendarEvents', 'date', date)
            const localVarPath = `/api/v1/calendar/events/allDay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get calendar events
         * @param {string} userId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEvents: async (userId: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCalendarEvents', 'userId', userId)
            const localVarPath = `/api/v1/calendar/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get events in each ciradian phase
         * @param {Array<string>} calendarIds 
         * @param {string} date 
         * @param {string} metricId 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEventsForPhases: async (calendarIds: Array<string>, date: string, metricId: string, timezone: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarIds' is not null or undefined
            assertParamExists('getCalendarEventsForPhases', 'calendarIds', calendarIds)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getCalendarEventsForPhases', 'date', date)
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('getCalendarEventsForPhases', 'metricId', metricId)
            // verify required parameter 'timezone' is not null or undefined
            assertParamExists('getCalendarEventsForPhases', 'timezone', timezone)
            const localVarPath = `/api/v1/calendar/events/phase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (calendarIds) {
                localVarQueryParameter['calendarIds[]'] = calendarIds;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (metricId !== undefined) {
                localVarQueryParameter['metricId'] = metricId;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all calendars
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarsInfo: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCalendarsInfo', 'userId', userId)
            const localVarPath = `/api/v1/calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync calendar events
         * @param {string} userId 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {Array<string>} calendarIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCalendarEvents: async (userId: string, startTime: string, endTime: string, calendarIds: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncCalendarEvents', 'userId', userId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('syncCalendarEvents', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('syncCalendarEvents', 'endTime', endTime)
            // verify required parameter 'calendarIds' is not null or undefined
            assertParamExists('syncCalendarEvents', 'calendarIds', calendarIds)
            const localVarPath = `/api/v1/calendar/events/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (calendarIds) {
                localVarQueryParameter['calendarIds[]'] = calendarIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync Google Calendar list
         * @param {string} uid 
         * @param {string} rootEmail 
         * @param {CalendarListSyncGCalReq} [calendarListSyncGCalReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncGCalendarsList: async (uid: string, rootEmail: string, calendarListSyncGCalReq?: CalendarListSyncGCalReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('syncGCalendarsList', 'uid', uid)
            // verify required parameter 'rootEmail' is not null or undefined
            assertParamExists('syncGCalendarsList', 'rootEmail', rootEmail)
            const localVarPath = `/api/v1/calendar/list/sync/gcal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (rootEmail !== undefined) {
                localVarQueryParameter['rootEmail'] = rootEmail;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarListSyncGCalReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync Outlook Calendar list
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        syncOutlookCalendarList: async (uid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('syncOutlookCalendarList', 'uid', uid)
            const localVarPath = `/api/v1/calendar/list/sync/outlook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync Outlook Calendar list V2
         * @param {string} uid 
         * @param {CalendarListSyncGCalReq} [calendarListSyncGCalReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOutlookCalendarListV2: async (uid: string, calendarListSyncGCalReq?: CalendarListSyncGCalReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('syncOutlookCalendarListV2', 'uid', uid)
            const localVarPath = `/api/v1/calendar/list/sync/outlook/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarListSyncGCalReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a calendar event
         * @param {string} userId 
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {object} body Partial&lt;CalendarEvent&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarEvent: async (userId: string, calendarId: string, eventId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateCalendarEvent', 'userId', userId)
            // verify required parameter 'calendarId' is not null or undefined
            assertParamExists('updateCalendarEvent', 'calendarId', calendarId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('updateCalendarEvent', 'eventId', eventId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCalendarEvent', 'body', body)
            const localVarPath = `/api/v1/calendar/events/{calendarId}/{eventId}`
                .replace(`{${"calendarId"}}`, encodeURIComponent(String(calendarId)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update EMA energy for a calendar event
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {object} body CalendarEventMetrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarEventEnergy: async (calendarId: string, eventId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarId' is not null or undefined
            assertParamExists('updateCalendarEventEnergy', 'calendarId', calendarId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('updateCalendarEventEnergy', 'eventId', eventId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCalendarEventEnergy', 'body', body)
            const localVarPath = `/api/v1/calendar/events/{calendarId}/{eventId}/energy`
                .replace(`{${"calendarId"}}`, encodeURIComponent(String(calendarId)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a calendar info
         * @param {string} calendarId 
         * @param {object} body Partial&lt;FirestoreCalendar&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarInfo: async (calendarId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarId' is not null or undefined
            assertParamExists('updateCalendarInfo', 'calendarId', calendarId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCalendarInfo', 'body', body)
            const localVarPath = `/api/v1/calendar/{calendarId}`
                .replace(`{${"calendarId"}}`, encodeURIComponent(String(calendarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update external calendar
         * @param {string} date 
         * @param {string} metricId 
         * @param {string} energyZoneCalendarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalCalendar: async (date: string, metricId: string, energyZoneCalendarId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('updateExternalCalendar', 'date', date)
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('updateExternalCalendar', 'metricId', metricId)
            // verify required parameter 'energyZoneCalendarId' is not null or undefined
            assertParamExists('updateExternalCalendar', 'energyZoneCalendarId', energyZoneCalendarId)
            const localVarPath = `/api/v1/calendar/external`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (metricId !== undefined) {
                localVarQueryParameter['metricId'] = metricId;
            }

            if (energyZoneCalendarId !== undefined) {
                localVarQueryParameter['energyZoneCalendarId'] = energyZoneCalendarId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarApi - functional programming interface
 * @export
 */
export const CalendarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a calendar event
         * @param {string} calendarId 
         * @param {string} userId 
         * @param {CalendarEventPostReq} [calendarEventPostReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCalendarEvent(calendarId: string, userId: string, calendarEventPostReq?: CalendarEventPostReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCalendarEvent(calendarId, userId, calendarEventPostReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.createCalendarEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary delete a calendar event
         * @param {string} userId 
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCalendarEvent(userId: string, calendarId: string, eventId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCalendarEvent(userId, calendarId, eventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.deleteCalendarEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all-day calendar events
         * @param {string} userId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDayCalendarEvents(userId: string, date: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDayCalendarEvents(userId, date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.getAllDayCalendarEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get calendar events
         * @param {string} userId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarEvents(userId: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarEvents(userId, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.getCalendarEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get events in each ciradian phase
         * @param {Array<string>} calendarIds 
         * @param {string} date 
         * @param {string} metricId 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarEventsForPhases(calendarIds: Array<string>, date: string, metricId: string, timezone: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarEventsForPhases(calendarIds, date, metricId, timezone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.getCalendarEventsForPhases']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all calendars
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarsInfo(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarsInfo(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.getCalendarsInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync calendar events
         * @param {string} userId 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {Array<string>} calendarIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncCalendarEvents(userId: string, startTime: string, endTime: string, calendarIds: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventsSyncRes>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncCalendarEvents(userId, startTime, endTime, calendarIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.syncCalendarEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync Google Calendar list
         * @param {string} uid 
         * @param {string} rootEmail 
         * @param {CalendarListSyncGCalReq} [calendarListSyncGCalReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncGCalendarsList(uid: string, rootEmail: string, calendarListSyncGCalReq?: CalendarListSyncGCalReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncGCalendarsList(uid, rootEmail, calendarListSyncGCalReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.syncGCalendarsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync Outlook Calendar list
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async syncOutlookCalendarList(uid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOutlookCalendarList(uid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.syncOutlookCalendarList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync Outlook Calendar list V2
         * @param {string} uid 
         * @param {CalendarListSyncGCalReq} [calendarListSyncGCalReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOutlookCalendarListV2(uid: string, calendarListSyncGCalReq?: CalendarListSyncGCalReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOutlookCalendarListV2(uid, calendarListSyncGCalReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.syncOutlookCalendarListV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a calendar event
         * @param {string} userId 
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {object} body Partial&lt;CalendarEvent&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCalendarEvent(userId: string, calendarId: string, eventId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCalendarEvent(userId, calendarId, eventId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.updateCalendarEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update EMA energy for a calendar event
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {object} body CalendarEventMetrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCalendarEventEnergy(calendarId: string, eventId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCalendarEventEnergy(calendarId, eventId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.updateCalendarEventEnergy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a calendar info
         * @param {string} calendarId 
         * @param {object} body Partial&lt;FirestoreCalendar&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCalendarInfo(calendarId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCalendarInfo(calendarId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.updateCalendarInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update external calendar
         * @param {string} date 
         * @param {string} metricId 
         * @param {string} energyZoneCalendarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExternalCalendar(date: string, metricId: string, energyZoneCalendarId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExternalCalendar(date, metricId, energyZoneCalendarId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.updateExternalCalendar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CalendarApi - factory interface
 * @export
 */
export const CalendarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a calendar event
         * @param {string} calendarId 
         * @param {string} userId 
         * @param {CalendarEventPostReq} [calendarEventPostReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendarEvent(calendarId: string, userId: string, calendarEventPostReq?: CalendarEventPostReq, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.createCalendarEvent(calendarId, userId, calendarEventPostReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a calendar event
         * @param {string} userId 
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarEvent(userId: string, calendarId: string, eventId: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.deleteCalendarEvent(userId, calendarId, eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all-day calendar events
         * @param {string} userId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDayCalendarEvents(userId: string, date: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getAllDayCalendarEvents(userId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get calendar events
         * @param {string} userId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEvents(userId: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getCalendarEvents(userId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get events in each ciradian phase
         * @param {Array<string>} calendarIds 
         * @param {string} date 
         * @param {string} metricId 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEventsForPhases(calendarIds: Array<string>, date: string, metricId: string, timezone: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getCalendarEventsForPhases(calendarIds, date, metricId, timezone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all calendars
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarsInfo(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getCalendarsInfo(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync calendar events
         * @param {string} userId 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {Array<string>} calendarIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCalendarEvents(userId: string, startTime: string, endTime: string, calendarIds: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Array<CalendarEventsSyncRes>> {
            return localVarFp.syncCalendarEvents(userId, startTime, endTime, calendarIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync Google Calendar list
         * @param {string} uid 
         * @param {string} rootEmail 
         * @param {CalendarListSyncGCalReq} [calendarListSyncGCalReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncGCalendarsList(uid: string, rootEmail: string, calendarListSyncGCalReq?: CalendarListSyncGCalReq, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.syncGCalendarsList(uid, rootEmail, calendarListSyncGCalReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync Outlook Calendar list
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        syncOutlookCalendarList(uid: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.syncOutlookCalendarList(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync Outlook Calendar list V2
         * @param {string} uid 
         * @param {CalendarListSyncGCalReq} [calendarListSyncGCalReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOutlookCalendarListV2(uid: string, calendarListSyncGCalReq?: CalendarListSyncGCalReq, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.syncOutlookCalendarListV2(uid, calendarListSyncGCalReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a calendar event
         * @param {string} userId 
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {object} body Partial&lt;CalendarEvent&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarEvent(userId: string, calendarId: string, eventId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.updateCalendarEvent(userId, calendarId, eventId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update EMA energy for a calendar event
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {object} body CalendarEventMetrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarEventEnergy(calendarId: string, eventId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateCalendarEventEnergy(calendarId, eventId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a calendar info
         * @param {string} calendarId 
         * @param {object} body Partial&lt;FirestoreCalendar&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarInfo(calendarId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateCalendarInfo(calendarId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update external calendar
         * @param {string} date 
         * @param {string} metricId 
         * @param {string} energyZoneCalendarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExternalCalendar(date: string, metricId: string, energyZoneCalendarId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.updateExternalCalendar(date, metricId, energyZoneCalendarId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendarApi - object-oriented interface
 * @export
 * @class CalendarApi
 * @extends {BaseAPI}
 */
export class CalendarApi extends BaseAPI {
    /**
     * 
     * @summary Create a calendar event
     * @param {string} calendarId 
     * @param {string} userId 
     * @param {CalendarEventPostReq} [calendarEventPostReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public createCalendarEvent(calendarId: string, userId: string, calendarEventPostReq?: CalendarEventPostReq, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).createCalendarEvent(calendarId, userId, calendarEventPostReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a calendar event
     * @param {string} userId 
     * @param {string} calendarId 
     * @param {string} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteCalendarEvent(userId: string, calendarId: string, eventId: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).deleteCalendarEvent(userId, calendarId, eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all-day calendar events
     * @param {string} userId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getAllDayCalendarEvents(userId: string, date: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getAllDayCalendarEvents(userId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get calendar events
     * @param {string} userId 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getCalendarEvents(userId: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getCalendarEvents(userId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get events in each ciradian phase
     * @param {Array<string>} calendarIds 
     * @param {string} date 
     * @param {string} metricId 
     * @param {string} timezone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getCalendarEventsForPhases(calendarIds: Array<string>, date: string, metricId: string, timezone: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getCalendarEventsForPhases(calendarIds, date, metricId, timezone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all calendars
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getCalendarsInfo(userId: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getCalendarsInfo(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync calendar events
     * @param {string} userId 
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {Array<string>} calendarIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public syncCalendarEvents(userId: string, startTime: string, endTime: string, calendarIds: Array<string>, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).syncCalendarEvents(userId, startTime, endTime, calendarIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync Google Calendar list
     * @param {string} uid 
     * @param {string} rootEmail 
     * @param {CalendarListSyncGCalReq} [calendarListSyncGCalReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public syncGCalendarsList(uid: string, rootEmail: string, calendarListSyncGCalReq?: CalendarListSyncGCalReq, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).syncGCalendarsList(uid, rootEmail, calendarListSyncGCalReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync Outlook Calendar list
     * @param {string} uid 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public syncOutlookCalendarList(uid: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).syncOutlookCalendarList(uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync Outlook Calendar list V2
     * @param {string} uid 
     * @param {CalendarListSyncGCalReq} [calendarListSyncGCalReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public syncOutlookCalendarListV2(uid: string, calendarListSyncGCalReq?: CalendarListSyncGCalReq, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).syncOutlookCalendarListV2(uid, calendarListSyncGCalReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a calendar event
     * @param {string} userId 
     * @param {string} calendarId 
     * @param {string} eventId 
     * @param {object} body Partial&lt;CalendarEvent&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateCalendarEvent(userId: string, calendarId: string, eventId: string, body: object, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).updateCalendarEvent(userId, calendarId, eventId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update EMA energy for a calendar event
     * @param {string} calendarId 
     * @param {string} eventId 
     * @param {object} body CalendarEventMetrics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateCalendarEventEnergy(calendarId: string, eventId: string, body: object, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).updateCalendarEventEnergy(calendarId, eventId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a calendar info
     * @param {string} calendarId 
     * @param {object} body Partial&lt;FirestoreCalendar&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateCalendarInfo(calendarId: string, body: object, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).updateCalendarInfo(calendarId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update external calendar
     * @param {string} date 
     * @param {string} metricId 
     * @param {string} energyZoneCalendarId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateExternalCalendar(date: string, metricId: string, energyZoneCalendarId: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).updateExternalCalendar(date, metricId, energyZoneCalendarId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DevApi - axios parameter creator
 * @export
 */
export const DevApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Run analytics
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runAnalytics: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('runAnalytics', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('runAnalytics', 'endDate', endDate)
            const localVarPath = `/api/v1/dev/analytics/run`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevApi - functional programming interface
 * @export
 */
export const DevApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Run analytics
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runAnalytics(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runAnalytics(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DevApi.runAnalytics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DevApi - factory interface
 * @export
 */
export const DevApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevApiFp(configuration)
    return {
        /**
         * 
         * @summary Run analytics
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runAnalytics(startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.runAnalytics(startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DevApi - object-oriented interface
 * @export
 * @class DevApi
 * @extends {BaseAPI}
 */
export class DevApi extends BaseAPI {
    /**
     * 
     * @summary Run analytics
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevApi
     */
    public runAnalytics(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return DevApiFp(this.configuration).runAnalytics(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EnergyApi - axios parameter creator
 * @export
 */
export const EnergyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get average circadian rhythm
         * @param {string} userId 
         * @param {string} metricId 
         * @param {string} referenceDate 
         * @param {string} [chronotype] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvgCircadian: async (userId: string, metricId: string, referenceDate: string, chronotype?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAvgCircadian', 'userId', userId)
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('getAvgCircadian', 'metricId', metricId)
            // verify required parameter 'referenceDate' is not null or undefined
            assertParamExists('getAvgCircadian', 'referenceDate', referenceDate)
            const localVarPath = `/api/v1/energy/circadian/avg`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (metricId !== undefined) {
                localVarQueryParameter['metricId'] = metricId;
            }

            if (referenceDate !== undefined) {
                localVarQueryParameter['referenceDate'] = referenceDate;
            }

            if (chronotype !== undefined) {
                localVarQueryParameter['chronotype'] = chronotype;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get circadian rhythm
         * @param {string} userId 
         * @param {Array<string>} calendarIds 
         * @param {string} metricId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {boolean} [sleepV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCircadian: async (userId: string, calendarIds: Array<string>, metricId: string, date: string, timezone: string, sleepV2?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCircadian', 'userId', userId)
            // verify required parameter 'calendarIds' is not null or undefined
            assertParamExists('getCircadian', 'calendarIds', calendarIds)
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('getCircadian', 'metricId', metricId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getCircadian', 'date', date)
            // verify required parameter 'timezone' is not null or undefined
            assertParamExists('getCircadian', 'timezone', timezone)
            const localVarPath = `/api/v1/energy/circadian`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (calendarIds) {
                localVarQueryParameter['calendarIds[]'] = calendarIds;
            }

            if (metricId !== undefined) {
                localVarQueryParameter['metricId'] = metricId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (sleepV2 !== undefined) {
                localVarQueryParameter['sleepV2'] = sleepV2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get energy preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergyPreference: async (preferenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('getEnergyPreference', 'preferenceId', preferenceId)
            const localVarPath = `/api/v1/energy/preference/{preferenceId}`
                .replace(`{${"preferenceId"}}`, encodeURIComponent(String(preferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a phase ema
         * @param {string} userId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhaseEmas: async (userId: string, date: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getPhaseEmas', 'userId', userId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getPhaseEmas', 'date', date)
            const localVarPath = `/api/v1/energy/ema`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update preferences for event categoiries\' energy
         * @param {string} preferenceId 
         * @param {Array<object>} requestBody EventCategoryEnergyPreference[]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventCategoryPreference: async (preferenceId: string, requestBody: Array<object>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('updateEventCategoryPreference', 'preferenceId', preferenceId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateEventCategoryPreference', 'requestBody', requestBody)
            const localVarPath = `/api/v1/energy/preference/{preferenceId}/eventCategory`
                .replace(`{${"preferenceId"}}`, encodeURIComponent(String(preferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a phase ema
         * @param {string} userId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {object} body EMADatePhaseScoreSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhaseEma: async (userId: string, date: string, timezone: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updatePhaseEma', 'userId', userId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('updatePhaseEma', 'date', date)
            // verify required parameter 'timezone' is not null or undefined
            assertParamExists('updatePhaseEma', 'timezone', timezone)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updatePhaseEma', 'body', body)
            const localVarPath = `/api/v1/energy/ema`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnergyApi - functional programming interface
 * @export
 */
export const EnergyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnergyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get average circadian rhythm
         * @param {string} userId 
         * @param {string} metricId 
         * @param {string} referenceDate 
         * @param {string} [chronotype] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvgCircadian(userId: string, metricId: string, referenceDate: string, chronotype?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvgCircadian(userId, metricId, referenceDate, chronotype, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnergyApi.getAvgCircadian']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get circadian rhythm
         * @param {string} userId 
         * @param {Array<string>} calendarIds 
         * @param {string} metricId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {boolean} [sleepV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCircadian(userId: string, calendarIds: Array<string>, metricId: string, date: string, timezone: string, sleepV2?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnergyCircadianGetRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCircadian(userId, calendarIds, metricId, date, timezone, sleepV2, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnergyApi.getCircadian']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get energy preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnergyPreference(preferenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnergyPreference(preferenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnergyApi.getEnergyPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a phase ema
         * @param {string} userId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhaseEmas(userId: string, date: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhaseEmas(userId, date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnergyApi.getPhaseEmas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update preferences for event categoiries\' energy
         * @param {string} preferenceId 
         * @param {Array<object>} requestBody EventCategoryEnergyPreference[]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEventCategoryPreference(preferenceId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEventCategoryPreference(preferenceId, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnergyApi.updateEventCategoryPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a phase ema
         * @param {string} userId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {object} body EMADatePhaseScoreSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePhaseEma(userId: string, date: string, timezone: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePhaseEma(userId, date, timezone, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnergyApi.updatePhaseEma']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EnergyApi - factory interface
 * @export
 */
export const EnergyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnergyApiFp(configuration)
    return {
        /**
         * 
         * @summary Get average circadian rhythm
         * @param {string} userId 
         * @param {string} metricId 
         * @param {string} referenceDate 
         * @param {string} [chronotype] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvgCircadian(userId: string, metricId: string, referenceDate: string, chronotype?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getAvgCircadian(userId, metricId, referenceDate, chronotype, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get circadian rhythm
         * @param {string} userId 
         * @param {Array<string>} calendarIds 
         * @param {string} metricId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {boolean} [sleepV2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCircadian(userId: string, calendarIds: Array<string>, metricId: string, date: string, timezone: string, sleepV2?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<EnergyCircadianGetRes> {
            return localVarFp.getCircadian(userId, calendarIds, metricId, date, timezone, sleepV2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get energy preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergyPreference(preferenceId: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getEnergyPreference(preferenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a phase ema
         * @param {string} userId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhaseEmas(userId: string, date: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getPhaseEmas(userId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update preferences for event categoiries\' energy
         * @param {string} preferenceId 
         * @param {Array<object>} requestBody EventCategoryEnergyPreference[]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventCategoryPreference(preferenceId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateEventCategoryPreference(preferenceId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a phase ema
         * @param {string} userId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {object} body EMADatePhaseScoreSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhaseEma(userId: string, date: string, timezone: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updatePhaseEma(userId, date, timezone, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnergyApi - object-oriented interface
 * @export
 * @class EnergyApi
 * @extends {BaseAPI}
 */
export class EnergyApi extends BaseAPI {
    /**
     * 
     * @summary Get average circadian rhythm
     * @param {string} userId 
     * @param {string} metricId 
     * @param {string} referenceDate 
     * @param {string} [chronotype] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyApi
     */
    public getAvgCircadian(userId: string, metricId: string, referenceDate: string, chronotype?: string, options?: RawAxiosRequestConfig) {
        return EnergyApiFp(this.configuration).getAvgCircadian(userId, metricId, referenceDate, chronotype, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get circadian rhythm
     * @param {string} userId 
     * @param {Array<string>} calendarIds 
     * @param {string} metricId 
     * @param {string} date 
     * @param {string} timezone 
     * @param {boolean} [sleepV2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyApi
     */
    public getCircadian(userId: string, calendarIds: Array<string>, metricId: string, date: string, timezone: string, sleepV2?: boolean, options?: RawAxiosRequestConfig) {
        return EnergyApiFp(this.configuration).getCircadian(userId, calendarIds, metricId, date, timezone, sleepV2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get energy preference
     * @param {string} preferenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyApi
     */
    public getEnergyPreference(preferenceId: string, options?: RawAxiosRequestConfig) {
        return EnergyApiFp(this.configuration).getEnergyPreference(preferenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a phase ema
     * @param {string} userId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyApi
     */
    public getPhaseEmas(userId: string, date: string, options?: RawAxiosRequestConfig) {
        return EnergyApiFp(this.configuration).getPhaseEmas(userId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update preferences for event categoiries\' energy
     * @param {string} preferenceId 
     * @param {Array<object>} requestBody EventCategoryEnergyPreference[]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyApi
     */
    public updateEventCategoryPreference(preferenceId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig) {
        return EnergyApiFp(this.configuration).updateEventCategoryPreference(preferenceId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a phase ema
     * @param {string} userId 
     * @param {string} date 
     * @param {string} timezone 
     * @param {object} body EMADatePhaseScoreSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyApi
     */
    public updatePhaseEma(userId: string, date: string, timezone: string, body: object, options?: RawAxiosRequestConfig) {
        return EnergyApiFp(this.configuration).updatePhaseEma(userId, date, timezone, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExternalApi - axios parameter creator
 * @export
 */
export const ExternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update a slack profile status based on energy levels
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSlackProfileStatus: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('updateSlackProfileStatus', 'code', code)
            const localVarPath = `/api/v1/external/slack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalApi - functional programming interface
 * @export
 */
export const ExternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExternalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update a slack profile status based on energy levels
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSlackProfileStatus(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSlackProfileStatus(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalApi.updateSlackProfileStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExternalApi - factory interface
 * @export
 */
export const ExternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExternalApiFp(configuration)
    return {
        /**
         * 
         * @summary Update a slack profile status based on energy levels
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSlackProfileStatus(code: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateSlackProfileStatus(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExternalApi - object-oriented interface
 * @export
 * @class ExternalApi
 * @extends {BaseAPI}
 */
export class ExternalApi extends BaseAPI {
    /**
     * 
     * @summary Update a slack profile status based on energy levels
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public updateSlackProfileStatus(code: string, options?: RawAxiosRequestConfig) {
        return ExternalApiFp(this.configuration).updateSlackProfileStatus(code, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IntegrationsApi - axios parameter creator
 * @export
 */
export const IntegrationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate a Terra widget
         * @param {string} metricId Metric ID
         * @param {string} [redirectTo] Redirect to URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTerraWidget: async (metricId: string, redirectTo?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('generateTerraWidget', 'metricId', metricId)
            const localVarPath = `/api/v1/integrations/terra/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metricId !== undefined) {
                localVarQueryParameter['metricId'] = metricId;
            }

            if (redirectTo !== undefined) {
                localVarQueryParameter['redirectTo'] = redirectTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generate auth url for todo integrations
         * @param {string} todoType TodoIntegrationType
         * @param {string} [callback] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTodoAuthUrl: async (todoType: string, callback?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'todoType' is not null or undefined
            assertParamExists('generateTodoAuthUrl', 'todoType', todoType)
            const localVarPath = `/api/v1/integrations/todo/generateAuthUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (todoType !== undefined) {
                localVarQueryParameter['todoType'] = todoType;
            }

            if (callback !== undefined) {
                localVarQueryParameter['callback'] = callback;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Terra user status
         * @param {Array<string>} terraUserIds User ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerraUserStatus: async (terraUserIds: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'terraUserIds' is not null or undefined
            assertParamExists('getTerraUserStatus', 'terraUserIds', terraUserIds)
            const localVarPath = `/api/v1/integrations/terra/auth/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (terraUserIds) {
                localVarQueryParameter['terraUserIds[]'] = terraUserIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get todo integrations
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodoIntegrations: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTodoIntegrations', 'userId', userId)
            const localVarPath = `/api/v1/integrations/todo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initialize todo integrations
         * @param {string} todoType TodoIntegrationType
         * @param {string} token 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initTodoIntegrations: async (todoType: string, token: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'todoType' is not null or undefined
            assertParamExists('initTodoIntegrations', 'todoType', todoType)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('initTodoIntegrations', 'token', token)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('initTodoIntegrations', 'userId', userId)
            const localVarPath = `/api/v1/integrations/todo/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (todoType !== undefined) {
                localVarQueryParameter['todoType'] = todoType;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationsApi - functional programming interface
 * @export
 */
export const IntegrationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate a Terra widget
         * @param {string} metricId Metric ID
         * @param {string} [redirectTo] Redirect to URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTerraWidget(metricId: string, redirectTo?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTerraWidget(metricId, redirectTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationsApi.generateTerraWidget']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary generate auth url for todo integrations
         * @param {string} todoType TodoIntegrationType
         * @param {string} [callback] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTodoAuthUrl(todoType: string, callback?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTodoAuthUrl(todoType, callback, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationsApi.generateTodoAuthUrl']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the Terra user status
         * @param {Array<string>} terraUserIds User ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerraUserStatus(terraUserIds: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetTerraUserStatusRes>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerraUserStatus(terraUserIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationsApi.getTerraUserStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get todo integrations
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTodoIntegrations(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTodoIntegrations(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationsApi.getTodoIntegrations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Initialize todo integrations
         * @param {string} todoType TodoIntegrationType
         * @param {string} token 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initTodoIntegrations(todoType: string, token: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitTodoPutRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initTodoIntegrations(todoType, token, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationsApi.initTodoIntegrations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IntegrationsApi - factory interface
 * @export
 */
export const IntegrationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate a Terra widget
         * @param {string} metricId Metric ID
         * @param {string} [redirectTo] Redirect to URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTerraWidget(metricId: string, redirectTo?: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.generateTerraWidget(metricId, redirectTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary generate auth url for todo integrations
         * @param {string} todoType TodoIntegrationType
         * @param {string} [callback] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTodoAuthUrl(todoType: string, callback?: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.generateTodoAuthUrl(todoType, callback, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Terra user status
         * @param {Array<string>} terraUserIds User ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerraUserStatus(terraUserIds: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetTerraUserStatusRes>> {
            return localVarFp.getTerraUserStatus(terraUserIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get todo integrations
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodoIntegrations(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getTodoIntegrations(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initialize todo integrations
         * @param {string} todoType TodoIntegrationType
         * @param {string} token 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initTodoIntegrations(todoType: string, token: string, userId: string, options?: RawAxiosRequestConfig): AxiosPromise<InitTodoPutRes> {
            return localVarFp.initTodoIntegrations(todoType, token, userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationsApi - object-oriented interface
 * @export
 * @class IntegrationsApi
 * @extends {BaseAPI}
 */
export class IntegrationsApi extends BaseAPI {
    /**
     * 
     * @summary Generate a Terra widget
     * @param {string} metricId Metric ID
     * @param {string} [redirectTo] Redirect to URL
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public generateTerraWidget(metricId: string, redirectTo?: string, options?: RawAxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).generateTerraWidget(metricId, redirectTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary generate auth url for todo integrations
     * @param {string} todoType TodoIntegrationType
     * @param {string} [callback] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public generateTodoAuthUrl(todoType: string, callback?: string, options?: RawAxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).generateTodoAuthUrl(todoType, callback, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the Terra user status
     * @param {Array<string>} terraUserIds User ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public getTerraUserStatus(terraUserIds: Array<string>, options?: RawAxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).getTerraUserStatus(terraUserIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get todo integrations
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public getTodoIntegrations(userId: string, options?: RawAxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).getTodoIntegrations(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initialize todo integrations
     * @param {string} todoType TodoIntegrationType
     * @param {string} token 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public initTodoIntegrations(todoType: string, token: string, userId: string, options?: RawAxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).initTodoIntegrations(todoType, token, userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consume Terra webhook
         * @param {MetricsWebhookTerraPutReq} [metricsWebhookTerraPutReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeTerraWebhook: async (metricsWebhookTerraPutReq?: MetricsWebhookTerraPutReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/metrics/webhook/terra`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricsWebhookTerraPutReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Diagnose chronotype
         * @param {string} userId 
         * @param {string} metricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseChronotype: async (userId: string, metricId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('diagnoseChronotype', 'userId', userId)
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('diagnoseChronotype', 'metricId', metricId)
            const localVarPath = `/api/v1/metrics/sleep/diagnoseChronotype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (metricId !== undefined) {
                localVarQueryParameter['metricId'] = metricId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<string>} dates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSleepDetails: async (metricId: string, userId: string, dates: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('getSleepDetails', 'metricId', metricId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSleepDetails', 'userId', userId)
            // verify required parameter 'dates' is not null or undefined
            assertParamExists('getSleepDetails', 'dates', dates)
            const localVarPath = `/api/v1/metrics/sleep/{metricId}`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (dates) {
                localVarQueryParameter['dates[]'] = dates;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save heart rate data
         * @param {string} userId 
         * @param {string} date 
         * @param {string} device 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveHeartRate: async (userId: string, date: string, device: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('saveHeartRate', 'userId', userId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('saveHeartRate', 'date', date)
            // verify required parameter 'device' is not null or undefined
            assertParamExists('saveHeartRate', 'device', device)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('saveHeartRate', 'body', body)
            const localVarPath = `/api/v1/metrics/heartRate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (device !== undefined) {
                localVarQueryParameter['device'] = device;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<string>} terraUserIds 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSleepDetails: async (metricId: string, userId: string, terraUserIds: Array<string>, startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('syncSleepDetails', 'metricId', metricId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncSleepDetails', 'userId', userId)
            // verify required parameter 'terraUserIds' is not null or undefined
            assertParamExists('syncSleepDetails', 'terraUserIds', terraUserIds)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('syncSleepDetails', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('syncSleepDetails', 'endDate', endDate)
            const localVarPath = `/api/v1/metrics/sleep/{metricId}/sync`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (terraUserIds) {
                localVarQueryParameter['terraUserIds[]'] = terraUserIds;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<object>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSleepDetails: async (metricId: string, userId: string, requestBody: Array<object>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('updateSleepDetails', 'metricId', metricId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateSleepDetails', 'userId', userId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateSleepDetails', 'requestBody', requestBody)
            const localVarPath = `/api/v1/metrics/sleep/{metricId}`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Consume Terra webhook
         * @param {MetricsWebhookTerraPutReq} [metricsWebhookTerraPutReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consumeTerraWebhook(metricsWebhookTerraPutReq?: MetricsWebhookTerraPutReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consumeTerraWebhook(metricsWebhookTerraPutReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.consumeTerraWebhook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Diagnose chronotype
         * @param {string} userId 
         * @param {string} metricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async diagnoseChronotype(userId: string, metricId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.diagnoseChronotype(userId, metricId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.diagnoseChronotype']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<string>} dates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSleepDetails(metricId: string, userId: string, dates: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSleepDetails(metricId, userId, dates, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.getSleepDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Save heart rate data
         * @param {string} userId 
         * @param {string} date 
         * @param {string} device 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveHeartRate(userId: string, date: string, device: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveHeartRate(userId, date, device, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.saveHeartRate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<string>} terraUserIds 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncSleepDetails(metricId: string, userId: string, terraUserIds: Array<string>, startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncSleepDetails(metricId, userId, terraUserIds, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.syncSleepDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<object>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSleepDetails(metricId: string, userId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSleepDetails(metricId, userId, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.updateSleepDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsApiFp(configuration)
    return {
        /**
         * 
         * @summary Consume Terra webhook
         * @param {MetricsWebhookTerraPutReq} [metricsWebhookTerraPutReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeTerraWebhook(metricsWebhookTerraPutReq?: MetricsWebhookTerraPutReq, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.consumeTerraWebhook(metricsWebhookTerraPutReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Diagnose chronotype
         * @param {string} userId 
         * @param {string} metricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseChronotype(userId: string, metricId: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.diagnoseChronotype(userId, metricId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<string>} dates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSleepDetails(metricId: string, userId: string, dates: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getSleepDetails(metricId, userId, dates, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save heart rate data
         * @param {string} userId 
         * @param {string} date 
         * @param {string} device 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveHeartRate(userId: string, date: string, device: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.saveHeartRate(userId, date, device, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<string>} terraUserIds 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSleepDetails(metricId: string, userId: string, terraUserIds: Array<string>, startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.syncSleepDetails(metricId, userId, terraUserIds, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<object>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSleepDetails(metricId: string, userId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateSleepDetails(metricId, userId, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
    /**
     * 
     * @summary Consume Terra webhook
     * @param {MetricsWebhookTerraPutReq} [metricsWebhookTerraPutReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public consumeTerraWebhook(metricsWebhookTerraPutReq?: MetricsWebhookTerraPutReq, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).consumeTerraWebhook(metricsWebhookTerraPutReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Diagnose chronotype
     * @param {string} userId 
     * @param {string} metricId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public diagnoseChronotype(userId: string, metricId: string, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).diagnoseChronotype(userId, metricId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sleep details
     * @param {string} metricId 
     * @param {string} userId 
     * @param {Array<string>} dates 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getSleepDetails(metricId: string, userId: string, dates: Array<string>, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getSleepDetails(metricId, userId, dates, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save heart rate data
     * @param {string} userId 
     * @param {string} date 
     * @param {string} device 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public saveHeartRate(userId: string, date: string, device: string, body: object, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).saveHeartRate(userId, date, device, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync sleep details
     * @param {string} metricId 
     * @param {string} userId 
     * @param {Array<string>} terraUserIds 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public syncSleepDetails(metricId: string, userId: string, terraUserIds: Array<string>, startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).syncSleepDetails(metricId, userId, terraUserIds, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update sleep details
     * @param {string} metricId 
     * @param {string} userId 
     * @param {Array<object>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public updateSleepDetails(metricId: string, userId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).updateSleepDetails(metricId, userId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RecommendationsApi - axios parameter creator
 * @export
 */
export const RecommendationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete recommended tasks
         * @param {string} userId 
         * @param {object} body RecommendedTask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecommendedTask: async (userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteRecommendedTask', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('deleteRecommendedTask', 'body', body)
            const localVarPath = `/api/v1/recommendations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recommended tasks
         * @param {string} userId 
         * @param {string} [origin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendedTasks: async (userId: string, origin?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getRecommendedTasks', 'userId', userId)
            const localVarPath = `/api/v1/recommendations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (origin !== undefined) {
                localVarQueryParameter['origin'] = origin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset recommended tasks
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetRecommendedTasks: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resetRecommendedTasks', 'userId', userId)
            const localVarPath = `/api/v1/recommendations/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update recommended tasks
         * @param {string} userId 
         * @param {object} body RecommendedTask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecommendedTasks: async (userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateRecommendedTasks', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateRecommendedTasks', 'body', body)
            const localVarPath = `/api/v1/recommendations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecommendationsApi - functional programming interface
 * @export
 */
export const RecommendationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecommendationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete recommended tasks
         * @param {string} userId 
         * @param {object} body RecommendedTask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRecommendedTask(userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRecommendedTask(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecommendationsApi.deleteRecommendedTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get recommended tasks
         * @param {string} userId 
         * @param {string} [origin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecommendedTasks(userId: string, origin?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecommendedTasks(userId, origin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecommendationsApi.getRecommendedTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset recommended tasks
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetRecommendedTasks(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetRecommendedTasks(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecommendationsApi.resetRecommendedTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update recommended tasks
         * @param {string} userId 
         * @param {object} body RecommendedTask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRecommendedTasks(userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRecommendedTasks(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecommendationsApi.updateRecommendedTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RecommendationsApi - factory interface
 * @export
 */
export const RecommendationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecommendationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete recommended tasks
         * @param {string} userId 
         * @param {object} body RecommendedTask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecommendedTask(userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.deleteRecommendedTask(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recommended tasks
         * @param {string} userId 
         * @param {string} [origin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendedTasks(userId: string, origin?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getRecommendedTasks(userId, origin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset recommended tasks
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetRecommendedTasks(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.resetRecommendedTasks(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update recommended tasks
         * @param {string} userId 
         * @param {object} body RecommendedTask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecommendedTasks(userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateRecommendedTasks(userId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecommendationsApi - object-oriented interface
 * @export
 * @class RecommendationsApi
 * @extends {BaseAPI}
 */
export class RecommendationsApi extends BaseAPI {
    /**
     * 
     * @summary Delete recommended tasks
     * @param {string} userId 
     * @param {object} body RecommendedTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApi
     */
    public deleteRecommendedTask(userId: string, body: object, options?: RawAxiosRequestConfig) {
        return RecommendationsApiFp(this.configuration).deleteRecommendedTask(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recommended tasks
     * @param {string} userId 
     * @param {string} [origin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApi
     */
    public getRecommendedTasks(userId: string, origin?: string, options?: RawAxiosRequestConfig) {
        return RecommendationsApiFp(this.configuration).getRecommendedTasks(userId, origin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset recommended tasks
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApi
     */
    public resetRecommendedTasks(userId: string, options?: RawAxiosRequestConfig) {
        return RecommendationsApiFp(this.configuration).resetRecommendedTasks(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update recommended tasks
     * @param {string} userId 
     * @param {object} body RecommendedTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApi
     */
    public updateRecommendedTasks(userId: string, body: object, options?: RawAxiosRequestConfig) {
        return RecommendationsApiFp(this.configuration).updateRecommendedTasks(userId, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SchedulerApi - axios parameter creator
 * @export
 */
export const SchedulerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Run Energy boost
         * @param {string} userId 
         * @param {SchedulerEnergyBoostPutReq} schedulerEnergyBoostPutReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleEnergyBoost: async (userId: string, schedulerEnergyBoostPutReq: SchedulerEnergyBoostPutReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('scheduleEnergyBoost', 'userId', userId)
            // verify required parameter 'schedulerEnergyBoostPutReq' is not null or undefined
            assertParamExists('scheduleEnergyBoost', 'schedulerEnergyBoostPutReq', schedulerEnergyBoostPutReq)
            const localVarPath = `/api/v1/scheduler/energyBoost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(schedulerEnergyBoostPutReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchedulerApi - functional programming interface
 * @export
 */
export const SchedulerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchedulerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Run Energy boost
         * @param {string} userId 
         * @param {SchedulerEnergyBoostPutReq} schedulerEnergyBoostPutReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduleEnergyBoost(userId: string, schedulerEnergyBoostPutReq: SchedulerEnergyBoostPutReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduleEnergyBoost(userId, schedulerEnergyBoostPutReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulerApi.scheduleEnergyBoost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SchedulerApi - factory interface
 * @export
 */
export const SchedulerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchedulerApiFp(configuration)
    return {
        /**
         * 
         * @summary Run Energy boost
         * @param {string} userId 
         * @param {SchedulerEnergyBoostPutReq} schedulerEnergyBoostPutReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleEnergyBoost(userId: string, schedulerEnergyBoostPutReq: SchedulerEnergyBoostPutReq, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.scheduleEnergyBoost(userId, schedulerEnergyBoostPutReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchedulerApi - object-oriented interface
 * @export
 * @class SchedulerApi
 * @extends {BaseAPI}
 */
export class SchedulerApi extends BaseAPI {
    /**
     * 
     * @summary Run Energy boost
     * @param {string} userId 
     * @param {SchedulerEnergyBoostPutReq} schedulerEnergyBoostPutReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public scheduleEnergyBoost(userId: string, schedulerEnergyBoostPutReq: SchedulerEnergyBoostPutReq, options?: RawAxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).scheduleEnergyBoost(userId, schedulerEnergyBoostPutReq, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TasksApi - axios parameter creator
 * @export
 */
export const TasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new project
         * @param {string} userId 
         * @param {object} body AddTodotaskBoardProps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTodoProject: async (userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addTodoProject', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addTodoProject', 'body', body)
            const localVarPath = `/api/v1/tasks/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a todo task
         * @param {string} boardId 
         * @param {string} userId 
         * @param {object} body AddTaskItemProps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTodoTask: async (boardId: string, userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardId' is not null or undefined
            assertParamExists('addTodoTask', 'boardId', boardId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addTodoTask', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addTodoTask', 'body', body)
            const localVarPath = `/api/v1/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (boardId !== undefined) {
                localVarQueryParameter['boardId'] = boardId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch overdue tasks by the selected date. So, if you pass 2024-09-30, it will return overdue(not completed) tasks until 2024-09-30.
         * @summary Get all overdue todo tasks
         * @param {Array<string>} todoTasksBoardIds 
         * @param {string} dueDateTill YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverdueTodoTasks: async (todoTasksBoardIds: Array<string>, dueDateTill: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'todoTasksBoardIds' is not null or undefined
            assertParamExists('getOverdueTodoTasks', 'todoTasksBoardIds', todoTasksBoardIds)
            // verify required parameter 'dueDateTill' is not null or undefined
            assertParamExists('getOverdueTodoTasks', 'dueDateTill', dueDateTill)
            const localVarPath = `/api/v1/tasks/overdue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (todoTasksBoardIds) {
                localVarQueryParameter['todoTasksBoardIds[]'] = todoTasksBoardIds;
            }

            if (dueDateTill !== undefined) {
                localVarQueryParameter['dueDateTill'] = dueDateTill;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all projects
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodoProjects: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTodoProjects', 'userId', userId)
            const localVarPath = `/api/v1/tasks/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all todo tasks
         * @param {Array<string>} todoTasksBoardIds 
         * @param {string} [dueDate] 
         * @param {boolean} [onlyMyTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodoTasks: async (todoTasksBoardIds: Array<string>, dueDate?: string, onlyMyTask?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'todoTasksBoardIds' is not null or undefined
            assertParamExists('getTodoTasks', 'todoTasksBoardIds', todoTasksBoardIds)
            const localVarPath = `/api/v1/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (todoTasksBoardIds) {
                localVarQueryParameter['todoTasksBoardIds[]'] = todoTasksBoardIds;
            }

            if (dueDate !== undefined) {
                localVarQueryParameter['dueDate'] = dueDate;
            }

            if (onlyMyTask !== undefined) {
                localVarQueryParameter['onlyMyTask'] = onlyMyTask;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync all projects
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTodoProjects: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncTodoProjects', 'userId', userId)
            const localVarPath = `/api/v1/tasks/projects/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync todo tasks
         * @param {string} userId 
         * @param {string} timezone 
         * @param {Array<string>} targetTodoIntegrationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTodoTasks: async (userId: string, timezone: string, targetTodoIntegrationIds: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncTodoTasks', 'userId', userId)
            // verify required parameter 'timezone' is not null or undefined
            assertParamExists('syncTodoTasks', 'timezone', timezone)
            // verify required parameter 'targetTodoIntegrationIds' is not null or undefined
            assertParamExists('syncTodoTasks', 'targetTodoIntegrationIds', targetTodoIntegrationIds)
            const localVarPath = `/api/v1/tasks/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (targetTodoIntegrationIds) {
                localVarQueryParameter['targetTodoIntegrationIds[]'] = targetTodoIntegrationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a todo task
         * @param {string} taskId 
         * @param {string} boardId 
         * @param {string} userId 
         * @param {object} body Partial&lt;FirestoreTaskItem&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTodoTask: async (taskId: string, boardId: string, userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('updateTodoTask', 'taskId', taskId)
            // verify required parameter 'boardId' is not null or undefined
            assertParamExists('updateTodoTask', 'boardId', boardId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateTodoTask', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateTodoTask', 'body', body)
            const localVarPath = `/api/v1/tasks/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (boardId !== undefined) {
                localVarQueryParameter['boardId'] = boardId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TasksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a new project
         * @param {string} userId 
         * @param {object} body AddTodotaskBoardProps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTodoProject(userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskProjectIdRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTodoProject(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.addTodoProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add a todo task
         * @param {string} boardId 
         * @param {string} userId 
         * @param {object} body AddTaskItemProps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTodoTask(boardId: string, userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskIdRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTodoTask(boardId, userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.addTodoTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch overdue tasks by the selected date. So, if you pass 2024-09-30, it will return overdue(not completed) tasks until 2024-09-30.
         * @summary Get all overdue todo tasks
         * @param {Array<string>} todoTasksBoardIds 
         * @param {string} dueDateTill YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOverdueTodoTasks(todoTasksBoardIds: Array<string>, dueDateTill: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOverdueTodoTasks(todoTasksBoardIds, dueDateTill, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.getOverdueTodoTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all projects
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTodoProjects(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTodoProjects(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.getTodoProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all todo tasks
         * @param {Array<string>} todoTasksBoardIds 
         * @param {string} [dueDate] 
         * @param {boolean} [onlyMyTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTodoTasks(todoTasksBoardIds: Array<string>, dueDate?: string, onlyMyTask?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTodoTasks(todoTasksBoardIds, dueDate, onlyMyTask, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.getTodoTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync all projects
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncTodoProjects(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncTodoProjects(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.syncTodoProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync todo tasks
         * @param {string} userId 
         * @param {string} timezone 
         * @param {Array<string>} targetTodoIntegrationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncTodoTasks(userId: string, timezone: string, targetTodoIntegrationIds: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncTodoTasks(userId, timezone, targetTodoIntegrationIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.syncTodoTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a todo task
         * @param {string} taskId 
         * @param {string} boardId 
         * @param {string} userId 
         * @param {object} body Partial&lt;FirestoreTaskItem&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTodoTask(taskId: string, boardId: string, userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskIdRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTodoTask(taskId, boardId, userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.updateTodoTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TasksApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a new project
         * @param {string} userId 
         * @param {object} body AddTodotaskBoardProps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTodoProject(userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<TaskProjectIdRes> {
            return localVarFp.addTodoProject(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a todo task
         * @param {string} boardId 
         * @param {string} userId 
         * @param {object} body AddTaskItemProps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTodoTask(boardId: string, userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<TaskIdRes> {
            return localVarFp.addTodoTask(boardId, userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch overdue tasks by the selected date. So, if you pass 2024-09-30, it will return overdue(not completed) tasks until 2024-09-30.
         * @summary Get all overdue todo tasks
         * @param {Array<string>} todoTasksBoardIds 
         * @param {string} dueDateTill YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverdueTodoTasks(todoTasksBoardIds: Array<string>, dueDateTill: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getOverdueTodoTasks(todoTasksBoardIds, dueDateTill, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all projects
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodoProjects(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getTodoProjects(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all todo tasks
         * @param {Array<string>} todoTasksBoardIds 
         * @param {string} [dueDate] 
         * @param {boolean} [onlyMyTask] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodoTasks(todoTasksBoardIds: Array<string>, dueDate?: string, onlyMyTask?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getTodoTasks(todoTasksBoardIds, dueDate, onlyMyTask, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync all projects
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTodoProjects(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.syncTodoProjects(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync todo tasks
         * @param {string} userId 
         * @param {string} timezone 
         * @param {Array<string>} targetTodoIntegrationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTodoTasks(userId: string, timezone: string, targetTodoIntegrationIds: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.syncTodoTasks(userId, timezone, targetTodoIntegrationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a todo task
         * @param {string} taskId 
         * @param {string} boardId 
         * @param {string} userId 
         * @param {object} body Partial&lt;FirestoreTaskItem&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTodoTask(taskId: string, boardId: string, userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<TaskIdRes> {
            return localVarFp.updateTodoTask(taskId, boardId, userId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
    /**
     * 
     * @summary Add a new project
     * @param {string} userId 
     * @param {object} body AddTodotaskBoardProps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public addTodoProject(userId: string, body: object, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).addTodoProject(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a todo task
     * @param {string} boardId 
     * @param {string} userId 
     * @param {object} body AddTaskItemProps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public addTodoTask(boardId: string, userId: string, body: object, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).addTodoTask(boardId, userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch overdue tasks by the selected date. So, if you pass 2024-09-30, it will return overdue(not completed) tasks until 2024-09-30.
     * @summary Get all overdue todo tasks
     * @param {Array<string>} todoTasksBoardIds 
     * @param {string} dueDateTill YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getOverdueTodoTasks(todoTasksBoardIds: Array<string>, dueDateTill: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).getOverdueTodoTasks(todoTasksBoardIds, dueDateTill, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all projects
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getTodoProjects(userId: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).getTodoProjects(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all todo tasks
     * @param {Array<string>} todoTasksBoardIds 
     * @param {string} [dueDate] 
     * @param {boolean} [onlyMyTask] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getTodoTasks(todoTasksBoardIds: Array<string>, dueDate?: string, onlyMyTask?: boolean, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).getTodoTasks(todoTasksBoardIds, dueDate, onlyMyTask, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync all projects
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public syncTodoProjects(userId: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).syncTodoProjects(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync todo tasks
     * @param {string} userId 
     * @param {string} timezone 
     * @param {Array<string>} targetTodoIntegrationIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public syncTodoTasks(userId: string, timezone: string, targetTodoIntegrationIds: Array<string>, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).syncTodoTasks(userId, timezone, targetTodoIntegrationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a todo task
     * @param {string} taskId 
     * @param {string} boardId 
     * @param {string} userId 
     * @param {object} body Partial&lt;FirestoreTaskItem&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public updateTodoTask(taskId: string, boardId: string, userId: string, body: object, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).updateTodoTask(taskId, boardId, userId, body, options).then((request) => request(this.axios, this.basePath));
    }
}




import React, { useState } from 'react';

import './AppDownloadSwitcher.scss';
import LifestackMobileIOSQR from '../../assets/onboarding/lifestack-mobile-ios-qr.png';
import LifestackMobileAndroidQR from '../../assets/onboarding/lifestack-mobile-android-qr.png';
import clsx from 'clsx';

type AppType = 'ios' | 'android';

const AppDownloadSwitcher: React.FC = () => {
  const [type, setType] = useState<AppType>('ios');

  const clickAppType = (appType: AppType) => {
    setType(appType);
  };

  return (
    <div className="app-download-switcher">
      <img
        src={type === 'ios' ? LifestackMobileIOSQR : LifestackMobileAndroidQR}
        alt="lifestack logo"
        className="app-download-switcher__image"
      />
      <div className="app-download-switcher__button-group">
        <div
          className={clsx(
            'app-download-switcher__button-group__button',
            type === 'ios' && 'app-download-switcher__button-group__button--active'
          )}
          onClick={() => clickAppType('ios')}
        >
          iOS
        </div>
        <div
          className={clsx(
            'app-download-switcher__button-group__button',
            type === 'android' && 'app-download-switcher__button-group__button--active'
          )}
          onClick={() => clickAppType('android')}
        >
          Android
        </div>
      </div>
    </div>
  );
};

export default AppDownloadSwitcher;

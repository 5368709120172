import { FC } from 'react';
import clsx from 'clsx';

import './EventBlock.scss';
import { RBCEvent } from '../types';

interface EventBlockProps {
  event: Partial<RBCEvent>;
  className?: string;
}

const EventBlock: FC<EventBlockProps> = ({ event, className }) => {
  return (
    <div className={clsx('event-block', className)}>
      {/* Exclude Zone  */}
      {event.eventId && (
        <div
          className="event-block__calendar"
          style={{ backgroundColor: event.calendarColor || event.color }}
        />
      )}
      <span className="event-block__title" style={{ color: event.color }}>
        {event.title}
      </span>
    </div>
  );
};

export default EventBlock;

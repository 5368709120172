import { EventCategorySet, EventCategoryEnergyPreferenceLevel } from '@demind-inc/core';

export const eventCategoriesForEnergyPref: EventCategorySet[] = [
  { id: 'default_deepWork', name: 'Focused work' },
  { id: 'default_lightTask', name: 'Light work' },
  { id: 'default_exercise', name: 'Exercise' },
  { id: 'default_mtg', name: 'Meetings' },
  { id: 'default_social', name: 'Social events' },
];

export const energyPrefLevelText: Record<EventCategoryEnergyPreferenceLevel, string> = {
  more_energized: 'More Energized',
  less_energized: 'Less Energized',
  about_same: 'About The Same',
  not_sure: "I Don't Know",
};

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { accountApi } from '../api';
import { FirestoreUser } from '../types';

interface UseUpdateUserParams {
  userId: string;
  newUserInfo: Partial<FirestoreUser>;
}

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  const updateUserMutation = useMutation({
    mutationFn: ({ userId, newUserInfo }: UseUpdateUserParams) => {
      return accountApi.updateUser(userId, newUserInfo).then(({ data }) => data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.user'] });
    },
  });

  return {
    updateUser: updateUserMutation.mutateAsync,
    ...updateUserMutation,
  };
};

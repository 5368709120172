import React from 'react';
import Box from '@mui/material/Box';
import './EventCategories.scss';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Input } from '@mui/material';
import clsx from 'clsx';
import SellIcon from '@mui/icons-material/Sell';
import { defaultEventCategorySet, EventCategorySet } from '@demind-inc/core';

interface EventCategoriesProps {
  className?: string;
  selectedCategories: EventCategorySet[];
  setSelectedCategories: (selectedCategories: EventCategorySet[]) => void;
}

export default function EventCategories({
  className,
  selectedCategories,
  setSelectedCategories,
}: EventCategoriesProps) {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value as string[];
    const selectedCategorySets = defaultEventCategorySet.filter((category) =>
      selectedIds.includes(category.id)
    );
    setSelectedCategories(selectedCategorySets);
  };

  return (
    <div className={clsx('event-categories', className)}>
      <FormControl className="event-categories__form-control">
        <InputLabel id="multiple-category-label" className="event-categories__label">
          <SellIcon fontSize="small" />
          <span className="event-categories__label-text">Select categories</span>
        </InputLabel>
        <Select
          labelId="multiple-category-label"
          id="multiple-category-select"
          multiple
          value={selectedCategories.map((category) => category.id)}
          disableUnderline
          onChange={handleChange}
          input={<Input id="select-multiple-category" />}
          renderValue={(selected) => (
            <Box className="event-categories__box">
              {selectedCategories.map((category) => (
                <Chip key={category.id} label={category.name} />
              ))}
            </Box>
          )}
          MenuProps={{
            PaperProps: {
              className: 'event-categories__menu',
            },
          }}
        >
          {defaultEventCategorySet.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

import { FC } from 'react';
import { CircularProgress } from '@mui/material';

import './LoadingOverlay.scss';

const LoadingOverlay: FC = () => (
  <div className="loading-overlay">
    <CircularProgress />
  </div>
);

export default LoadingOverlay;

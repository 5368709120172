import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import dayjs, { Dayjs } from 'dayjs';
import { useRecoilValue } from 'recoil';

import './EditEventMenu.scss';
import { selectedDateAtom, useEventDetailsMenuContext } from '../../../../data-access';
import { EventTimeSelector } from '../EventTimeSelector';
import { RBCEvent } from '../../CalendarTimeline';
import { EventDescription } from '../EventDescription';
import { hasSameDate } from '../helpers';
import { EventCategories } from '../EventCategories';
import { EventCategorySet } from '@demind-inc/core';
import { isEqual } from 'lodash';

interface EditEventMenuProps {
  visible: boolean;
  onClose: () => void;
}

let defaultEvent: RBCEvent | undefined; // Use this for checking if the event has changed

const EditEventMenu: React.FC<EditEventMenuProps> = ({ visible, onClose }) => {
  const selectedDate = useRecoilValue(selectedDateAtom);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState<Dayjs>(selectedDate);
  const [endDate, setEndDate] = useState<Dayjs>(selectedDate.add(30, 'minutes'));
  const [selectedCategories, setSelectedCategories] = useState<EventCategorySet[]>([]);
  const { selectedEvent, handleSaveEvent: handleSaveEventApi } = useEventDetailsMenuContext();

  useEffect(() => {
    if (!selectedEvent) {
      return;
    }

    if (!defaultEvent) {
      defaultEvent = selectedEvent;
    }

    setTitle(selectedEvent.title || '');
    setStartDate(dayjs(selectedEvent.start));
    setEndDate(dayjs(selectedEvent.end));
    setDescription(selectedEvent.description || '');
    setSelectedCategories(selectedEvent.categories || []);
  }, [selectedEvent]);

  const isStartTimeBeforeEndTime = useMemo(() => startDate.isBefore(endDate), [startDate, endDate]);
  const canSave = useMemo(
    () =>
      (!!title &&
        isStartTimeBeforeEndTime &&
        (defaultEvent?.title !== title ||
          defaultEvent?.description !== description ||
          !hasSameDate(defaultEvent?.start, startDate) ||
          !hasSameDate(defaultEvent?.end, endDate))) ||
      !isEqual(selectedCategories, defaultEvent?.categories),
    [isStartTimeBeforeEndTime, title, description, startDate, endDate, selectedCategories]
  );
  const resetEventForm = () => {
    setTitle('');
    setStartDate(selectedDate);
    setEndDate(selectedDate.add(30, 'minutes'));
    defaultEvent = undefined;
  };

  const handleSaveEvent = () => {
    handleSaveEventApi({
      summary: title,
      start: {
        date: startDate.toISOString(),
        timeZone: dayjs.tz.guess(),
      },
      end: {
        date: endDate.toISOString(),
        timeZone: dayjs.tz.guess(),
      },
      description,
      categories: selectedCategories,
    });
    resetEventForm();
  };

  const handleCancelEvent = () => {
    onClose();
    resetEventForm();
  };

  const handleKeyDown = (key: string) => {
    if (!canSave) {
      return;
    }

    if (key === 'Enter') {
      handleSaveEvent();
    }
  };

  if (!selectedEvent) {
    return null;
  }

  return (
    <Dialog
      open={visible}
      className="edit-event-menu"
      hideBackdrop
      onClose={onClose}
      onKeyDown={({ key }) => handleKeyDown(key)}
    >
      <DialogTitle className="edit-event-menu__header">
        <CloseOutlined onClick={onClose} className="edit-event-menu__header__close" />
      </DialogTitle>
      <DialogContent className="edit-event-menu__content">
        <TextField
          value={title}
          placeholder="Add title"
          onChange={(e) => setTitle(e.target.value)}
          variant="standard"
          className="create-event-menu__title"
        />
        <EventTimeSelector
          startDateTime={startDate}
          endDateTime={endDate}
          onStartTimeChange={(val) => setStartDate(val)}
          onEndTimeChange={(val) => setEndDate(val)}
          className="edit-event-menu__content__item"
        />
        <EventCategories
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          className="edit-event-menu__content__item"
        />
        <EventDescription
          description={description}
          onChangeDescription={setDescription}
          className="edit-event-menu__content__item"
        />
      </DialogContent>
      <DialogActions className="edit-event-menu__actions">
        <Button variant="contained" disabled={!canSave} onClick={handleSaveEvent}>
          Save
        </Button>
        <Button variant="outlined" onClick={handleCancelEvent}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditEventMenu;

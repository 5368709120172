import { CircadianPhase } from '@demind-inc/core';
import {
  WbTwilight,
  Adjust,
  TrendingDown,
  TrendingUp,
  NotificationsPaused,
} from '@mui/icons-material';
import { ReactElement } from 'react';

const COLOR_RED = '#f34720';
const COLOR_GREEN = '#09c25a';
const COLOR_YELLOW = '#fdc913';
const COLOR_BLUE = '#3a92fa';

export const circadianPhaseInfo: Record<
  CircadianPhase,
  { color: string; title: string; label?: string; labelIcon?: ReactElement; explanation?: string }
> = {
  default: {
    title: '',
    color: COLOR_BLUE,
  },
  wakeup_low: {
    title: '🌅 Morning Warm-up',
    label: 'Warm-up',
    color: COLOR_RED,
    labelIcon: <WbTwilight style={{ color: COLOR_RED, fontSize: '14px' }} />,
    explanation: 'Warm-up! Good morning, set your self up for success!',
  },
  wakeup_normal: {
    title: '😎 Morning Standard',
    color: COLOR_YELLOW,
  },
  morning_high: {
    title: '☀️ Morning Peak',
    label: 'Focus',
    color: COLOR_GREEN,
    labelIcon: <Adjust style={{ color: COLOR_GREEN, fontSize: '14px' }} />,
    explanation: 'Focus time! Work on deep tasks to maximize your productivity.',
  },
  morning_normal: {
    title: '😎 Afternoon Standard',
    color: COLOR_YELLOW,
  },
  morning_low: {
    title: '☕️ Afternoon Calm',
    label: 'Energy Calm',
    color: COLOR_RED,
    labelIcon: <TrendingDown style={{ color: COLOR_RED, fontSize: '14px' }} />,
    explanation: 'Breather! Work on light tasks or take a break.',
  },
  evening_normal: {
    title: '😎 Evening Standard',
    color: COLOR_YELLOW,
  },
  evening_high: {
    title: '🌙 Evening Peak',
    label: 'Energy Peak',
    color: COLOR_GREEN,
    labelIcon: <TrendingUp style={{ color: COLOR_GREEN, fontSize: '14px' }} />,
    explanation: 'Another peak! Finish the day on a high note.',
  },
  evening_low: {
    title: '💤 Wind Down',
    label: 'Wind Down',
    color: COLOR_RED,
    labelIcon: <NotificationsPaused style={{ color: COLOR_RED, fontSize: '14px' }} />,
    explanation: "Wind down! Start winding down your day's activities.",
  },
};
